export enum TeamsPolicyType {
    CallingLineIdentity = "CallingLineIdentity",
    ExternalAccessPolicy = "ExternalAccessPolicy",
    OnlineVoiceRoutingPolicy = "OnlineVoiceRoutingPolicy",
    TeamsAppSetupPolicy = "TeamsAppSetupPolicy",
    TeamsAppPermissionPolicy = "TeamsAppPermissionPolicy",
    TeamsCallingPolicy = "TeamsCallingPolicy",
    TeamsCallParkPolicy = "TeamsCallParkPolicy",
    TeamsChannelsPolicy = "TeamsChannelsPolicy",
    TeamsEmergencyCallingPolicy = "TeamsEmergencyCallingPolicy",
    TeamsEmergencyCallRoutingPolicy = "TeamsEmergencyCallRoutingPolicy",
    TeamsMeetingPolicy = "TeamsMeetingPolicy",
    TeamsMessagingPolicy = "TeamsMessagingPolicy",
    TeamsUpdateManagementPolicy = "TeamsUpdateManagementPolicy",
    TeamsVideoInteropServicePolicy = "TeamsVideoInteropServicePolicy",
    TenantDialPlan = "TenantDialPlan",
}