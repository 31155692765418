import { ToastIntent } from "@fluentui/react-components";
import { createContext } from "react";
import { ICustomAlertProps } from "../components/CustomAlert";
import { ISubscriptionEvent } from "@symity-hub/types";

interface AppContextProps {
    pageName?: { originalValue: string, newValue: string };
    setPageName: React.Dispatch<React.SetStateAction<{ originalValue: string, newValue: string } | undefined>>;
    notify: (message: string, intent: ToastIntent) => void;
    alerts: ICustomAlertProps[];
    setAlerts: React.Dispatch<React.SetStateAction<ICustomAlertProps[]>>;
    sendMessage?: (message: ISubscriptionEvent, callback: (response: ISubscriptionEvent) => void) => Promise<void>
}

export const AppContext = createContext({} as AppContextProps);
