import { z } from "zod";

export const baseCosmosItemSchema = z.object({
    id: z.string()
        .uuid(),
    _ts: z.number()
        .optional(),
    _rid: z.string()
        .optional(),
    _self: z.string()
        .optional(),
    _etag: z.string()
        .optional(),
    _attachments: z.string()
        .optional()
});

export type IBaseCosmosItem = z.infer<typeof baseCosmosItemSchema>;