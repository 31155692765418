import React, { useContext, useEffect } from "react";
import { AppContext } from "../contexts/AppContext";

interface IBreadcrumbOverrideProps {
    id?: string;
    name?: string | null;
}

export const BreadcrumbOverride: React.FunctionComponent<IBreadcrumbOverrideProps> = (props) => {

    const appContext = useContext(AppContext);

    // Update page name in the breadcrumb
    useEffect(() => {
        if (props.id) {
            // Need to add a temporary loading state to the breadcrumb (currently shows the id)
            if (props.name && appContext.pageName?.newValue !== props.name) {
                appContext.setPageName({ originalValue: props.id, newValue: props.name });
            } else if (!props.name && appContext.pageName?.newValue !== "...") {
                appContext.setPageName({ originalValue: props.id, newValue: "..." });
            }
        }
    }, [appContext, props.id, props.name]);

    return (
        <></>
    );
};
