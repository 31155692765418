import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbOverride } from "./BreadcrumbOverride";
import { EntityPage } from "./EntityPage";
import ErrorBoundary from "./ErrorBoundary";
import { sharedHorizontalMediumGapWrapFlexStyles } from "../styles/styles";
import { PropertyAndValue } from "./PropertyAndValue";
import { Section } from "./Section";
import { EditPhoneNumberPanel } from "./EditPhoneNumberPanel";
import { renderPhoneNumberWithExtension } from "../modules/string";
import { Edit24Regular } from "@fluentui/react-icons";
import { Button, Link } from "@fluentui/react-components";
import { IPhoneNumberExtended, writePhoneNumberPermission } from "@symity-hub/types";
import { RbacWrapper } from "./RbacWrapper";

interface IPhoneNumberPageProps {
}

export const PhoneNumberPage: React.FunctionComponent<IPhoneNumberPageProps> = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [panelOpen, setPanelOpen] = useState<boolean>(false);
    const [data, setData] = useState<IPhoneNumberExtended>();

    return (
        <ErrorBoundary>
            <EntityPage
                url={`/api/voice/phoneNumbers/${id}`}
                setData={setData}
            >
                <BreadcrumbOverride id={id} name={data?.phoneNumber ? renderPhoneNumberWithExtension(data.phoneNumber, data.extensionNumber) : undefined} />
                {data && (
                    <div className={sharedHorizontalMediumGapWrapFlexStyles().root}>
                        <Section
                            title="Number details"
                            isLoading={!data}
                            action={(
                                <RbacWrapper allowedPermissions={[writePhoneNumberPermission]}>
                                    <Button
                                        icon={<Edit24Regular />}
                                        onClick={() => setPanelOpen(true)}
                                        appearance="outline"
                                    >
                                        Edit
                                    </Button>
                                </RbacWrapper>
                            )}
                        >
                            <PropertyAndValue
                                property="Number"
                                value={renderPhoneNumberWithExtension(data.phoneNumber, data.extensionNumber)}
                            />
                            <PropertyAndValue
                                property="Range"
                                value={<Link onClick={() => { navigate(`/voice/phoneNumberRanges/${data.rangeId}`) }}>{data.rangeName}</Link>}
                            />
                            <PropertyAndValue
                                property="Type"
                                value={data.numberType}
                            />
                            <PropertyAndValue
                                property="Classification"
                                value={data.classification}
                            />
                            <PropertyAndValue
                                property="Notes"
                                value={data.notes}
                            />
                        </Section>
                        <Section
                            title="Assignment"
                            isLoading={!data}
                        >
                            <PropertyAndValue
                                property="Assigned to"
                                value={<Link onClick={() => { navigate(`/accounts/${data.userId}`) }}>{data.userDisplayName}</Link>}
                            />
                            <PropertyAndValue
                                property="Assignment type"
                                value={data.pstnAssignmentStatus}
                            />
                            <PropertyAndValue
                                property="Activation state"
                                value={data.activationState}
                            />
                        </Section>
                    </div>
                )}
                {panelOpen && data && (
                    <EditPhoneNumberPanel
                        open={panelOpen}
                        setOpen={setPanelOpen}
                        data={data}
                        setData={setData}
                    />
                )}
            </EntityPage>
        </ErrorBoundary>
    );
};
