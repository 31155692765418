import { Text, makeStyles, tokens } from "@fluentui/react-components";
import React from "react";

interface IAppTitleProps {
}

const appTitleStyles = makeStyles({
    root: {
        color: tokens.colorNeutralForegroundStaticInverted,
        display: "flex",
        flexDirection: "column",
    }
});

export const AppTitle: React.FunctionComponent<IAppTitleProps> = () => {

    return (
        <div
            className={appTitleStyles().root}
        >
            <Text size={800} as="h1">Symity Hub</Text>
            {process.env.NODE_ENV === "development" && <Text size={200} as="h3">DEVELOPMENT BUILD</Text>}
        </div>
    );
};
