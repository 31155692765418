import React from "react";
import { Spinner, makeStyles, Subtitle1, tokens, Card, CardHeader, Caption1 } from "@fluentui/react-components";

export interface ISectionProps {
    title?: string | JSX.Element;
    description?: string;
    action?: JSX.Element;
    isLoading?: boolean;
    className?: string;
}

 const sectionStyles = makeStyles({
    card: {
        minWidth: "300px",
        maxWidth: "700px",
        height: "fit-content",   
    },
    cardHeader: {
        alignItems: "start",
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    }
});

export const Section: React.FunctionComponent<ISectionProps> = (props) => {

    return (
        <div className={props.className}>
            <Card
                className={sectionStyles().card}
                size="large"
                appearance="filled-alternative"
            >
                {props.title &&
                    <CardHeader
                        className={sectionStyles().cardHeader}
                        header={<Subtitle1>{props.title}</Subtitle1>}
                        description={<Caption1 className={sectionStyles().caption}>{props.description}</Caption1>}
                        action={props.action}
                    />
                }
                {props.isLoading
                    ? (<Spinner />)
                    : (<div>
                        {props.children}
                    </div>)}
            </Card>
        </div>
    );
};
