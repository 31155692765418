import React from "react";
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom";
import App from "./App";
import { msalConfig } from "./modules/auth";

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Handle login event
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const rootElement = document.getElementById("root");
ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
  ,
  rootElement
);
