import React, { useEffect, useState } from "react";
import { Caption1, Spinner, Subtitle2, TableCellLayout, TableColumnDefinition, createTableColumn } from "@fluentui/react-components";
import ErrorBoundary from "./ErrorBoundary";
import { User } from "@microsoft/microsoft-graph-types";
import { IOperationRequestBase, ITeamsPolicy, ITeamsPowerShellTask, TaskStatus, TeamsPolicyType, teamsPowerShellTaskSchema, TeamsPowerShellTaskType } from "@symity-hub/types";
import { sharedHorizontalMediumGapWrapFlexStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { fetcher } from "../modules/api";
import { useMsal } from "@azure/msal-react";
import useSWR from "swr";
import { CustomAlert } from "./CustomAlert";
import { OperationDialog } from "./OperationDialog";
import { CustomEditDrawer } from "./CustomEditDrawer";
import { CustomDropdown } from "./CustomDropdown";
import { OptionOnSelectData } from "@fluentui/react-combobox";
import { BasicDataGrid } from "./BasicDataGrid";
import { ZodError } from "zod";

interface IAssignUserPoliciesPanelProps {
    users: User[];
    assignedUserPolicies?: ITeamsPolicy[];
    open: boolean;
    setOpen: (open: boolean) => void;
    onDismiss: () => void;
}

export const AssignUserPoliciesPanel: React.FunctionComponent<IAssignUserPoliciesPanelProps> = (props) => {

    const msalContext = useMsal();

    const [tasks, setTasks] = useState<ITeamsPowerShellTask[]>([]);
    const [teamsPolicies, setTeamsPolicies] = useState<ITeamsPolicy[]>([]);
    const [operationId, setOperationId] = useState<string>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<ZodError>();

    const columns: TableColumnDefinition<User>[] = [
        createTableColumn<User>({
            columnId: "displayName",
            renderHeaderCell: () => {
                return "Name";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.displayName}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<User>({
            columnId: "userPrincipalName",
            renderHeaderCell: () => {
                return "Username";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.userPrincipalName}
                    </TableCellLayout>
                );
            }
        })
    ];

    // Get teams policies
    const { data, error, mutate } = useSWR<ITeamsPolicy[]>(props.users.length > 0 && props.open ? [`/api/teams/policies`, msalContext, "GET"] : null, fetcher,
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            onSuccess: (data) => {
                const policies = data.map((policy) => {
                    // Check if policy is already assigned to user
                    const assignedPolicy = props.assignedUserPolicies?.find((userPolicy) => userPolicy.policyType === policy.policyType);
                    if (assignedPolicy) {
                        policy.currentPolicyName = assignedPolicy.currentPolicyName;
                    }
                    return policy;
                });
                setTeamsPolicies(policies);
            }
        }
    );

    const onSave = async () => {
        if (tasks.length > 0) {
            // Validate schema
            setValidationError(undefined);
            const validTasks = await Promise.all(tasks.map(async (task) => {
                return await teamsPowerShellTaskSchema.parseAsync(task)
                    .then(async () => {
                        return task;
                    })
            }))
                .catch((error: ZodError) => {
                    console.error(error.errors);
                    setValidationError(error);
                });
            if (validTasks && validTasks.length > 0 && !validationError) {
                // Create operation request
                // Filter out void tasks
                const operationRequest: IOperationRequestBase = {
                    tasks: validTasks
                }
                // Send request
                await fetcher(["/api/teams/policies/assign", msalContext, "POST", undefined, operationRequest])
                    .then((data) => {
                        // If successful, set the operation ID and open the dialog
                        if (data.operationId) {
                            setOperationId(data.operationId);
                            setDialogOpen(true);
                        }
                    })

            }
        }
    }

    const onPolicyChange = (policyType: TeamsPolicyType, option: OptionOnSelectData) => {
        if (option && option.optionValue && props.users.length > 0) {
            // Set current value of dropdown to new option
            const newTeamsPolicies = teamsPolicies.map((policy) => {
                if (policy.policyType === policyType && option.optionValue) {
                    const newPolicy: ITeamsPolicy = {
                        ...policy,
                        currentPolicyName: option.optionValue.toString()
                    }
                    return newPolicy;
                }
                return policy;
            });
            setTeamsPolicies(newTeamsPolicies);

            // Check if its the same as the data value (default), in that case remove it from the array?
            const originalPolicy = data?.find((policy) => policy.policyType === policyType);
            if (originalPolicy && originalPolicy.currentPolicyName === option.optionValue.toString()) {
                // Remove from tasks
                const newTasks = tasks.filter((task) => task.batchPolicyAssignment?.policyType !== policyType);
                setTasks(newTasks);
            } else {
                // Add to tasks
                const newTasks = tasks.filter((task: ITeamsPowerShellTask) => task.batchPolicyAssignment?.policyType !== policyType);
                newTasks.push({
                    name: `Assign ${option.optionValue.toString()} (${policyType}) to  ${props.users.length} user(s)`,
                    type: TeamsPowerShellTaskType.BatchPolicyAssignment,
                    batchPolicyAssignment: {
                        policyType: policyType,
                        policyName: option.optionValue.toString(),
                        userIds: props.users.map(user => user.id as string)
                    }
                });
                setTasks(newTasks);
            }
        }
    }

    // Refresh data when panel is opened
    useEffect(() => {
        if (props.open) {
            // TODO - Fix this being called twice
            mutate();
        }
    }, [mutate, props.open]);

    const onDismiss = () => {
        // Reset state
        props.onDismiss();
    }

    const onDismissOperationDialog = () => {
        setDialogOpen(false);
        setOperationId(undefined);
        onDismiss();
    }

    return (
        <CustomEditDrawer
            open={props.open}
            onDismiss={onDismiss}
            onSave={onSave}
            headerText="Assign policies"
            validationError={validationError}
            size="large"
        >
            <ErrorBoundary>
                <div className={sharedVerticalMediumGapFlexStyles().root}>
                    {error && (<CustomAlert text={error} type={TaskStatus.Error} id="assignUserPolicyPanel" />)}
                    {props.users.length > 0 && (
                        <BasicDataGrid
                            columns={columns}
                            items={props.users}
                        />
                    )}
                    <Subtitle2>Policies</Subtitle2>
                    <Caption1>Select the policies to assign to the selected user(s). A &rsquo;Global&rsquo; policy is an org-wide default policy.</Caption1>
                    {teamsPolicies.length === 0 && (<Spinner size="large" />)}
                    {teamsPolicies && (
                        <div className={sharedHorizontalMediumGapWrapFlexStyles().root}>
                            {teamsPolicies.map((policy: ITeamsPolicy) => {
                                return (
                                    <CustomDropdown
                                        key={policy.policyType}
                                        id={policy.policyType}
                                        selectedOptions={[policy.currentPolicyName]}
                                        value={policy.currentPolicyName}
                                        label={policy.displayName}
                                        options={policy.policies.map((policyName: string) => { return { key: policyName, text: policyName } })}
                                        onOptionSelect={(_, data) => {
                                            onPolicyChange(policy.policyType, data);
                                        }}
                                    />
                                )
                            })}
                        </div>
                    )}
                    {operationId && (
                        <OperationDialog
                            open={dialogOpen}
                            onDismiss={onDismissOperationDialog}
                            operationId={operationId}
                        />
                    )}
                </div>
            </ErrorBoundary>
        </CustomEditDrawer>
    );
};
