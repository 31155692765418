import React, { useEffect, useRef, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { IBaseQueryParams, IPhoneNumberRange, writePhoneNumberPermission } from "@symity-hub/types";
import { useNavigate } from "react-router-dom";
import { IQueryProps } from "../types/IQueryProps";
import { EditPhoneNumberRangePanel } from "./EditPhoneNumberRangePanel";
import { Link, TableColumnDefinition, ToolbarButton, createTableColumn } from "@fluentui/react-components";
import CustomDataGrid, { ICustomDataGridRef } from "./CustomDataGrid";
import { Add24Regular } from "@fluentui/react-icons";
import { sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { RbacWrapper } from "./RbacWrapper";
import { CustomTableCellLayout } from "./CustomTableCellLayout";

const defaultQueryParams: IBaseQueryParams = {
    sortBy: "name"
};

export const PhoneNumberRangeList: React.FunctionComponent = () => {

    const navigate = useNavigate();

    const dataGridRef = useRef<ICustomDataGridRef>(null);

    const [panelOpen, setPanelOpen] = useState<boolean>(false);
    const prevPanelOpen = useRef<boolean>(panelOpen);
    const [queryProps, setQueryProps] = useState<IQueryProps>({
        params: defaultQueryParams,
        searchField: "rangeStart",
        searchPlaceholderText: "Search by range"
    });

    const columns: TableColumnDefinition<IPhoneNumberRange>[] = [
        createTableColumn<IPhoneNumberRange>({
            columnId: "name",
            renderHeaderCell: () => {
                return "Name";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.name}>
                        <Link onClick={() => { navigate(`/voice/phoneNumberRanges/${item.id}`) }}>{item.name}</Link>
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.name.localeCompare(b.name);
            },
        }),
        createTableColumn<IPhoneNumberRange>({
            columnId: "rangeStart",
            renderHeaderCell: () => {
                return "Range start";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.rangeStart}>
                        {item.rangeStart}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.rangeStart.localeCompare(b.rangeStart);
            },
        }),
        createTableColumn<IPhoneNumberRange>({
            columnId: "rangeEnd",
            renderHeaderCell: () => {
                return "Range end";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.rangeEnd}>
                        {item.rangeEnd}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.rangeEnd.localeCompare(b.rangeEnd);
            },
        }),
        createTableColumn<IPhoneNumberRange>({
            columnId: "totalPhoneNumbersInRange",
            renderHeaderCell: () => {
                return "Numbers in range";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.totalPhoneNumbersInRange.toString()}>
                        {item.totalPhoneNumbersInRange}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.totalPhoneNumbersInRange - b.totalPhoneNumbersInRange;
            },
        }),
        createTableColumn<IPhoneNumberRange>({
            columnId: "carrier",
            renderHeaderCell: () => {
                return "Carrier";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.carrier}>
                        {item.carrier}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                const aCarrier = a.carrier || "";
                const bCarrier = b.carrier || "";
                return aCarrier.localeCompare(bCarrier);
            },
        })
    ];

    // When panel is closed, refresh the data grid
    useEffect(() => {
        if (prevPanelOpen.current && !panelOpen && dataGridRef.current) {
            dataGridRef.current.reloadData();
        }
        prevPanelOpen.current = panelOpen;
    }, [panelOpen]);

    return (
        <div className={sharedVerticalMediumGapFlexStyles().root}>
            <ErrorBoundary>
                <CustomDataGrid
                    url="/api/voice/phoneNumberRanges"
                    queryProps={queryProps}
                    setQueryProps={setQueryProps}
                    columns={columns}
                    toolbarButtons={[
                        <RbacWrapper allowedPermissions={[writePhoneNumberPermission]}
                            key={0}
                        >
                            <ToolbarButton
                                appearance="subtle"
                                icon={<Add24Regular />}
                                onClick={() => setPanelOpen(true)}
                            >
                                Add range
                            </ToolbarButton>
                        </RbacWrapper>
                    ]}
                />
                {panelOpen && (
                    <EditPhoneNumberRangePanel
                        open={panelOpen}
                        setOpen={setPanelOpen}
                    />
                )}
            </ErrorBoundary>
        </div>
    );
}
