import { z } from "zod";
import { PhoneNumberAssignmentAction } from "./enums/PhoneNumberAssignmentAction";
import { PhoneNumberType } from "./enums/PhoneNumberType";
import { operationTaskSchema } from "./IOperationTask";
import { TeamsPowerShellTaskType } from "./enums/TeamsPowerShellTaskType";

export const teamsPowerShellTaskSchema = operationTaskSchema.extend({
    type: z.nativeEnum(TeamsPowerShellTaskType),
    batchPolicyAssignment: z.object({
        userIds: z.array(
            z.string()
                .uuid()
        ),
        policyType: z.string(),
        policyName: z.string()
    })
        .optional(),
    phoneNumberAssignment: z.object({
        userId: z.string()
            .uuid(),
        phoneNumber: z.string(),
        extensionNumber: z.string()
            .optional(),
        phoneNumberType: z.nativeEnum(PhoneNumberType),
        action: z.nativeEnum(PhoneNumberAssignmentAction)
    })
        .optional(),
    params: z.object({
        entityId: z.string()
            .uuid()
            .optional(),
        top: z.number()
            .optional(),
        skip: z.number()
            .optional(),
    })
        .optional()
})
    .strict()
    // Validate that phoneNumberAssignment is required when type is PhoneNumberAssignment
    .refine(data => {
        if (data.type === TeamsPowerShellTaskType.PhoneNumberAssignment) {
            return data.phoneNumberAssignment !== undefined;
        }
        return true;
    }, {
        message: "phoneNumberAssignment is required when type is PhoneNumberAssignment",
        path: ["phoneNumberAssignment"]
    })
    // Validate that batchPolicyAssignment is required when type is BatchPolicyAssignment
    .refine(data => {
        if (data.type === TeamsPowerShellTaskType.BatchPolicyAssignment) {
            return data.batchPolicyAssignment !== undefined;
        }
        return true;
    }, {
        message: "batchPolicyAssignment is required when type is BatchPolicyAssignment",
        path: ["batchPolicyAssignment"]
    });

export type ITeamsPowerShellTask = z.infer<typeof teamsPowerShellTaskSchema>;