import { z } from "zod";
import { e164Regex } from "../validation/base";
import { baseCosmosItemSchema } from "./IBaseCosmosItem";

export const phoneNumberRangeSchema = baseCosmosItemSchema.extend({
    name: z.string({ required_error: "Name is required" })
        .min(3),
    description: z.string()
        .optional(),
    rangeStart: z.string({ required_error: "Range start is required" })
        .regex(e164Regex, "Range start must be in E.164 format"),
    rangeEnd: z.string({ required_error: "Range end is required" })
        .regex(e164Regex, "Range end must be in E.164 format"),
    totalPhoneNumbersInRange: z.number({ required_error: "Total phone numbers in range is required" })
        .max(10000, "Total phone numbers in range must not exceed 10,000"),
    carrier: z.string()
        .optional(),
    notes: z.string()
        .optional()
})
    .strict();

export type IPhoneNumberRange = z.infer<typeof phoneNumberRangeSchema>;