import { operationRequestBaseSchema } from "./IOperationRequestBase";
import { z } from "zod";

export const operationRequestSchema = operationRequestBaseSchema.extend({
    customerId: z.string()
        .uuid(),
    initiatedByUserId: z.string()
        .uuid(),
    token: z.string(),
    instanceId: z.string()
        .uuid(),
    operationName: z.string()
});

export type IOperationRequest = z.infer<typeof operationRequestSchema>;