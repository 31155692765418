import { Configuration, LogLevel } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AAD_CLIENT_ID as string,
        authority: `https://login.microsoftonline.com/common/`,
        redirectUri: `${process.env.REACT_APP_CLIENT_ROOT_URL}/loginHandler/`,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: unknown, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                }
            }
        }
    }
};

export const apiScopes = [
    `${process.env.REACT_APP_AAD_CLIENT_ID}/access_as_user`
];
