export enum AuditTypeAction {
    Create = "Create",
    Update = "Update",
    Delete = "Delete",
    Read = "Read",
    Batch = "Batch",
    Assign = "Assign",
    Sync = "Sync",
    Unknown = "Unknown"
}
