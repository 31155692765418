import React, { useContext, useState } from "react";
import { OverlayDrawer, DrawerBody, DrawerHeader, DrawerHeaderTitle, Tooltip, Caption2 } from "@fluentui/react-components";
import { Avatar, Button, Link, shorthands, makeStyles, tokens, Text, mergeClasses, Switch } from "@fluentui/react-components";
import { ChevronDown24Regular, ChevronUp24Regular, Dismiss24Regular, DocumentBulletListMultiple24Regular, PersonFeedback24Regular, Settings24Regular, SignOut24Regular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { sharedNavMenuStyles } from "../styles/styles";
import { ThemeContext } from "../contexts/ThemeContext";
import { symityDarkTheme, symityLightTheme } from "../styles/theme";
import { FeedbackDialog } from "./FeedbackDialog";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import { allUserRoles } from "@symity-hub/types";

interface ISignedInUserPanelProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  photo?: string;
}

const signedInUserPanelStyles = makeStyles({
  header: {
    ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM)
  },
  avatar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    gap: (tokens.spacingVerticalS, tokens.spacingHorizontalS)
  },
  user: {
    display: "flex",
    flexDirection: "column",
    maxWidth: '200px',
    whiteSpace: 'nowrap'
  },
  username: {
    overflowX: "hidden",
    overflowY: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  separator: {
    borderBottomColor: tokens.colorNeutralStroke3,
    borderBottomWidth: tokens.strokeWidthThin,
    borderBottomStyle: "solid",
  },
  item: {
    ":hover": {
      textDecorationLine: "none",
      backgroundColor: tokens.colorNeutralBackground1Hover
    },
    ":focus": {
      textDecorationLine: "none",
      backgroundColor: tokens.colorNeutralBackground1Hover
    },
    ":pressed": {
      textDecorationLine: "none",
      backgroundColor: tokens.colorNeutralBackground1Pressed
    },
    ":active": {
      textDecorationLine: "none",
      backgroundColor: tokens.colorNeutralBackground1Selected
    }
  },
  body: {
    ...shorthands.padding(tokens.spacingVerticalXS, "0"),
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  chevron: {
    marginLeft: "auto"
  },
  version: {
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS)
  }
})

export const SignedInUserPanel: React.FunctionComponent<ISignedInUserPanelProps> = (props) => {

  const msalContext = useMsal();
  const signedInUserContext = useContext(SignedInUserContext);
  const sharedStyles = mergeClasses(sharedNavMenuStyles().root, signedInUserPanelStyles().item);
  const [settingsExpanded, setSettingsExpanded] = useState<boolean>(false);
  const themeContext = useContext(ThemeContext);
  const changeTheme = () => {
    themeContext.setTheme(themeContext.theme === symityLightTheme ? symityDarkTheme : symityLightTheme)
    localStorage.setItem("theme", themeContext.theme === symityLightTheme ? "dark" : "light");
  }
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)
  const handleLogout = () => {
    msalContext.instance.logoutPopup({
      mainWindowRedirectUri: "/"
    });
  }

  const navigate = useNavigate();
  const onLinkClick = (e?: React.MouseEvent<HTMLElement>, url?: string) => {
    if (url && e) {
      e.preventDefault();
      navigate(url);
      props.setOpen(false);
    }
  }

  return (
    <div>
      <OverlayDrawer
        size="small"
        position="end"
        open={props.open}
        onOpenChange={(_, state) => props.setOpen(state.open)}
      >
        <DrawerHeader
          className={signedInUserPanelStyles().header}
        >
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => props.setOpen(false)}
              />
            }
          >
            <div className={signedInUserPanelStyles().avatar}>
              <Avatar
                name={msalContext.accounts[0]?.name}
                image={{
                  src: props.photo || undefined
                }}
                size={40}
              />
              <div className={signedInUserPanelStyles().user}>
                <Text size={300}>{msalContext.accounts[0]?.name}</Text>
                <Tooltip content={msalContext.accounts[0]?.username || ""} relationship="label">
                  <Text size={200} className={signedInUserPanelStyles().username}>{msalContext.accounts[0]?.username}</Text>
                </Tooltip>
                <Text size={100}>{signedInUserContext.signedInUser?.roles?.map((roleId) => {
                  return allUserRoles.find((userRole) => userRole.id === roleId)?.displayName || "Unknown role"
                })
                  .sort((a, b) => a.localeCompare(b))
                  .join(", ")}</Text>
              </div>
            </div>
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody className={signedInUserPanelStyles().body}>
          <div>
            <div className={signedInUserPanelStyles().separator} />
            {signedInUserContext.signedInUser && (
              <div>
                <div>
                  <Link
                    onClick={() => setSettingsExpanded(!settingsExpanded)}
                    className={sharedStyles}>
                    <Settings24Regular />
                    <div>Settings</div>
                    <div className={signedInUserPanelStyles().chevron}>
                      {settingsExpanded ?
                        <ChevronUp24Regular />
                        : <ChevronDown24Regular />
                      }
                    </div>
                  </Link>
                  {settingsExpanded &&
                    <div>
                      <Switch
                        onClick={changeTheme}
                        label="Enable dark mode"
                        checked={themeContext.theme === symityDarkTheme}
                      />
                      <Link
                        onClick={(e) => onLinkClick(e, "/settings")}
                        className={sharedStyles}
                      >
                        <div>View all settings</div>
                      </Link>
                    </div>
                  }
                </div>
                <div>
                  <Link href="https://docs.symityhub.com" target="blank" className={sharedStyles}>
                    <DocumentBulletListMultiple24Regular />
                    <div>Knowledgebase</div>
                  </Link>
                </div>
                <div>
                  <Link
                    className={sharedStyles}
                    onClick={() => {
                      setFeedbackModalOpen(true)
                    }}
                  >
                    <PersonFeedback24Regular />
                    <div>Feedback</div>
                  </Link>
                </div>
                {feedbackModalOpen && (
                  <FeedbackDialog
                    title={"Feedback"}
                    open={feedbackModalOpen}
                    setOpen={setFeedbackModalOpen}
                  />)
                }
              </div>
            )}
            <div>
              <Link
                onClick={handleLogout}
                className={sharedStyles}>
                <SignOut24Regular />
                <div>Sign out</div>
              </Link>
            </div>
            <div className={signedInUserPanelStyles().version}>
              {process.env.REACT_APP_CLIENT_VERSION && <Caption2 as="h3">Client version: {process.env.REACT_APP_CLIENT_VERSION}</Caption2>}
            </div>
          </div>
        </DrawerBody>
      </OverlayDrawer>
    </div>
  );
}
