import React, { useCallback } from "react";
import { IBaseQueryFilter, IBaseQueryResult } from "@symity-hub/types";
import ErrorBoundary from "./ErrorBoundary";
import debounce from "lodash.debounce";
import { IQueryProps } from "../types/IQueryProps";
import { IDropdownFilter } from "../types/IDropdownFilter";
import cloneDeep from "lodash.clonedeep";
import { OptionOnSelectData, SelectionEvents } from "@fluentui/react-combobox";
import { sharedDropdownStyles, sharedHorizontalMediumGapWrapFlexStyles } from "../styles/styles";
import { CustomDropdown } from "./CustomDropdown";

interface IDropdownFiltersProps {
    queryProps: IQueryProps;
    setQueryProps: React.Dispatch<React.SetStateAction<IQueryProps>>
    dropdownFilters: IDropdownFilter[];
    setDropdownFilters: React.Dispatch<React.SetStateAction<IDropdownFilter[]>>
}

export const populateDropdownFilter = (data: IBaseQueryResult, dropdownFilters: IDropdownFilter[], setDropdownFilters: React.Dispatch<React.SetStateAction<IDropdownFilter[]>>, filterName: string, key: string, text: string) => {
    const newDropdownFilters = cloneDeep(dropdownFilters);
    const dropdownFilter = newDropdownFilters.find((filter) => filter.name === filterName);
    if (dropdownFilter) {
        dropdownFilter.options = data.resources.map((item) => {
            return {
                key: item[key],
                text: item[text]
            }
        });
    }
    setDropdownFilters(newDropdownFilters);
}

export const DropdownFilters: React.FunctionComponent<IDropdownFiltersProps> = (props) => {

    // Destructure props (to avoid callback requiring props as a dependency)
    const {
        queryProps,
        setQueryProps,
        dropdownFilters,
        setDropdownFilters
    } = props;

    /**
     * When a filter dropdown changes, update the query params (on a debounced callback)
     */
    const onDropdownFilterChange = useCallback(debounce((filters: IBaseQueryFilter[]) => {
        // Add filters to query params
        setQueryProps({
            ...queryProps,
            params: {
                ...queryProps.params,
                filters,
                page: 1
            }
        });
    }, 1000), [queryProps, setQueryProps]);

    /**
     * When a filter option is selected in a dropdown, add/remove it to the dropdown filter selected keys
     */
    const onOptionSelect = useCallback((event: SelectionEvents, data: OptionOnSelectData, filterName: string) => {
        const newDropdownFilters = cloneDeep(dropdownFilters);
        const dropdownFilter = newDropdownFilters.find((filter) => filter.name === filterName);
        if (dropdownFilter) {
            dropdownFilter.selectedKeys = data.selectedOptions;
            setDropdownFilters(newDropdownFilters);
        }
        // Add query filters
        const queryFilters = queryProps.params.filters && queryProps.params.filters.filter((filter: IBaseQueryFilter) => filter.name !== filterName) || [];
        if (dropdownFilter && dropdownFilter.selectedKeys.length > 0) {
            queryFilters?.push({
                name: dropdownFilter.name,
                operator: "in",
                value: dropdownFilter.selectedKeys
            });
        }
        // Add filters to query params
        onDropdownFilterChange(queryFilters);
    }, [dropdownFilters, onDropdownFilterChange, queryProps.params.filters, setDropdownFilters]);

    return (
        <ErrorBoundary>
            <div className={sharedHorizontalMediumGapWrapFlexStyles().root}>
                {dropdownFilters?.map((filter) => (
                    <div
                        key={filter.name}
                        className={sharedDropdownStyles().root}
                    >
                        <CustomDropdown
                            id={filter.name}
                            key={filter.name}
                            label={filter.label}
                            multiselect={filter.multiSelect}
                            options={filter.options}
                            onOptionSelect={(e, d) => onOptionSelect(e, d, filter.name)}
                            selectedOptions={filter.selectedKeys}
                        />
                    </div>
                ))}
            </div>
        </ErrorBoundary>
    );
};
