import React, { useContext, useState } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
import { BreadcrumbMenu } from "../components/BreadcrumbMenu";
import { ContentRoutes } from "../components/ContentRoutes";
import { IUserWithEffectivePermissions, TaskStatus } from "@symity-hub/types";
import { useMsal } from "@azure/msal-react";
import { fetcher } from "../modules/api";
import useSWR from "swr";
import { Link, Text, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { NavMenu } from "../components/NavMenu";
import { AcceptDialog } from "../components/AcceptDialog";
import { CustomAlert } from "../components/CustomAlert";
import { AppAlerts } from "../components/AppAlerts";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import { AppContext } from "../contexts/AppContext";

const authenticatedContentStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        backgroundColor: tokens.colorNeutralBackground5
    },
    content: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flexGrow: 1,
        ...shorthands.margin(0, tokens.spacingHorizontalM, tokens.spacingVerticalM, tokens.spacingHorizontalM)
    },
    signInError: {
        ...shorthands.padding(0, tokens.spacingHorizontalM)
    }
});

export const AuthenticatedContent: React.FunctionComponent = () => {

    const msalContext = useMsal();
    const signedInUserContext = useContext(SignedInUserContext);
    const appContext = useContext(AppContext);

    const [signInError, setSignInError] = useState<string>();

    const { mutate } = useSWR<IUserWithEffectivePermissions>(msalContext.accounts.length > 0 && msalContext.accounts[0].localAccountId ? [`/api/me`, msalContext, "GET"] : null, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        onError: (error) => {
            setSignInError(error);
        },
        onSuccess: (data) => {
            setSignInError(undefined);
            signedInUserContext.setSignedInUser(data);
        }
    });

    const onAcceptTerms = async () => {
        await fetcher(["/api/me/acceptTerms", msalContext, "POST", undefined, undefined])
            .then(() => {
                mutate();
                appContext.notify("Terms and conditions accepted", "success");
            })
    }

    return (
        <>
            {signInError && (
                <div className={authenticatedContentStyles().signInError}>
                    <CustomAlert id="signInError" type={TaskStatus.Warning} text={signInError} />
                </div>
            )}
            {signedInUserContext.signedInUser &&
                <div className={authenticatedContentStyles().root}>
                    <NavMenu />
                    <div className={authenticatedContentStyles().content}>
                        <AppAlerts
                            alerts={appContext.alerts}
                            setAlerts={appContext.setAlerts}
                        />
                        <BreadcrumbMenu />
                        <ErrorBoundary>
                            {signedInUserContext.signedInUser.acceptedTermsAndConditionsDate
                                ? <ContentRoutes />
                                : (<AcceptDialog
                                    onConfirm={() => onAcceptTerms()}
                                    title="Accept Terms and Conditions"
                                >
                                    <Text>Please confirm you accept the terms and conditions outlined at <Link href="https://cvdgroup.com/legal/" target="_blank">https://cvdgroup.com/legal/</Link></Text>
                                </AcceptDialog>)
                            }
                        </ErrorBoundary>
                    </div>
                </div>
            }
        </>
    );
}
