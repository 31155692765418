import React, { } from "react";
import { Field, Textarea, TextareaOnChangeData } from "@fluentui/react-components";
import { sharedInputStyles } from "../styles/styles";
import { ZodError } from "zod";

interface ICustomTextareaProps {
    id: string;
    label: string;
    required?: boolean;
    disabled?: boolean;
    value?: string;
    onChange?: (ev: React.ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => void;
    hint?: string;
    validationError?: ZodError;
    errorMessage?: string;
    placeholder?: string;
}

export const CustomTextarea: React.FunctionComponent<ICustomTextareaProps> = (props) => {

    return (
        <Field
            label={props.label}
            required={props.required}
            validationMessage={props.validationError?.issues.find(e => e.path.includes(props.id))?.message || props.errorMessage || undefined}
            hint={props.hint}
            className={sharedInputStyles().root}
        >
            <Textarea
                disabled={props.disabled}
                resize="vertical"
                onChange={props.onChange}
                value={props.value}
                placeholder={props.placeholder}
            />
        </Field>
    );
};
