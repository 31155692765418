import React from 'react'
import { sharedVerticalMediumGapFlexStyles } from '../styles/styles'
import { useMsal } from '@azure/msal-react'
import { ReportsViewer } from "./ReportsViewer"
import { Subtitle1, } from '@fluentui/react-components'

export const Dashboard: React.FunctionComponent = () => {

  const msalContext = useMsal();

  return (
    <div className={sharedVerticalMediumGapFlexStyles().root}>
      <div>
        <Subtitle1>{`Welcome to Symity Hub, ${msalContext.accounts[0]?.name}`}</Subtitle1>
      </div>
      <ReportsViewer
        url="/api/reports/dashboard"
      />
    </div>
  )
}
