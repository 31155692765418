import React, { Component, ErrorInfo, ReactNode } from "react";
import { CustomAlert } from "./CustomAlert";
import { TaskStatus } from "@symity-hub/types";

interface IProps {
    children: ReactNode;
}

interface IState {
    hasError: boolean;
}

class ErrorBoundary extends Component<IProps, IState> {
    public state: IState = {
        hasError: false
    };

    public static getDerivedStateFromError(): IState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return <CustomAlert
                text="Sorry, there was an error. Please try again later."
                type={TaskStatus.Error}
                id="errorBoundary"
            />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
