import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomAlert } from "./CustomAlert";
import ErrorBoundary from "./ErrorBoundary";
import { useMsal } from "@azure/msal-react";
import useSWR from "swr";
import { fetcher } from "../modules/api";
import { IPhoneNumber, IUserExtended, TaskStatus } from "@symity-hub/types";
import { UserPolicies } from "./UserPolicies";
import { UserVoice } from "./UserVoice";
import { TeamsUserContext } from "../contexts/TeamsUserContext";
import { UserOverview } from "./UserOverview";
import { Tab, TabList } from "@fluentui/react-components";
import { Call20Regular, Settings20Regular, TextBulletListLtr20Regular } from "@fluentui/react-icons";

export const UserPivot: React.FunctionComponent = () => {

    const msalContext = useMsal();
    const teamsUserContext = useContext(TeamsUserContext);
    const { id } = useParams();

    const [selectedTab, setSelectedTab] = useState<string>("overview");

    // Get user
    const { error: teamsDetailsError, mutate: teamsDetailsMutate, isLoading: teamsDetailsIsLoading } = useSWR<IUserExtended>([`/api/users/${id}/teamsDetails`, msalContext, "GET"], fetcher,
        {
            onSuccess(data) {
                if (data) {
                    teamsUserContext.setTeamsDetails(data);
                } else {
                    teamsUserContext.setTeamsDetails(undefined);
                }
            }
        }
    );
    const { error: phoneNumberError, mutate: phoneNumberMutate, isLoading: phoneNumberIsLoading } = useSWR<IPhoneNumber>(teamsUserContext.user?.id ? [`/api/voice/phoneNumbers/users/${teamsUserContext.user.id}`, msalContext, "GET"] : null, fetcher,
        {
            onSuccess(data) {
                if (data) {
                    teamsUserContext.setPhoneNumber(data);
                } else {
                    teamsUserContext.setPhoneNumber(undefined);
                }
            }
        });

    const onDismissPanel = () => {
        teamsDetailsMutate();
        phoneNumberMutate();
    };

    if (teamsDetailsError || phoneNumberError) return (<CustomAlert text={"Error loading user details"} type={TaskStatus.Error} id="errorLoadingUserDetails"/>);

    return (
        <ErrorBoundary>
            <TabList
                aria-label="User"
                selectedValue={selectedTab}
                onTabSelect={(_, data) => setSelectedTab(data.value as string)}
            >
                <Tab value="overview" icon={<TextBulletListLtr20Regular />}>Overview</Tab>
                <Tab value="voice" icon={<Call20Regular />}>Voice</Tab>
                <Tab value="policies" icon={<Settings20Regular />}>Policies</Tab>
            </TabList>
            {selectedTab === "overview" && (<UserOverview isLoading={phoneNumberIsLoading || !teamsUserContext.user || teamsDetailsIsLoading} />)}
            {selectedTab === "voice" && (<UserVoice onDismissPanel={onDismissPanel} isLoading={phoneNumberIsLoading} />)}
            {selectedTab === "policies" && (<UserPolicies onDismissPanel={onDismissPanel} isLoading={teamsDetailsIsLoading} />)}
        </ErrorBoundary>
    );
};
