import React from "react";
import { sharedHorizontalMediumGapWrapFlexStyles } from "../styles/styles";
import { Section } from "./Section";
import { IReport, ReportType, TaskStatus } from "@symity-hub/types";
import { PieChart } from "./PieChart";
import { fetcher } from "../modules/api";
import useSWR from "swr";
import { useMsal } from "@azure/msal-react";
import { BarChart } from "./BarChart";
import { Spinner, makeStyles } from "@fluentui/react-components";
import { CustomAlert } from "./CustomAlert";

export interface IReportsViewerProps {
    url?: string;
}

const reportStyles = makeStyles({
    root: {
        height: "380px",
        width: "500px",
        "@media screen and (max-width: 1280px)": {
            height: "285px",
            width: "375px"
        }
    }
});

export const ReportsViewer: React.FunctionComponent<IReportsViewerProps> = (props) => {

    const msalContext = useMsal();

    const { error, data: reports, isLoading } = useSWR<IReport[]>(props.url ? [props.url, msalContext, "GET"] : null, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    return (
        <div className={sharedHorizontalMediumGapWrapFlexStyles().root}>
            {error && <CustomAlert
                type={TaskStatus.Error}
                text={error.message}
                id="reportsViewer"
            />}
            {isLoading && <Spinner label="Loading..." />}
            {reports && reports.map((report: IReport, index) => (
                <Section
                    key={index}
                    title={report.title}
                    description={report.description}
                >
                    <div className={reportStyles().root}>
                        {report.type === ReportType.Pie && (
                            <PieChart
                                report={report}
                            />
                        )}
                        {report.type === ReportType.Bar && (
                            <BarChart
                                report={report}
                            />
                        )}
                    </div>
                </Section>
            ))}
        </div>
    );
};
