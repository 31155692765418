import React from "react";
import ErrorBoundary from "./ErrorBoundary";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

interface IConfirmationDialogProps {
    title: string;
    open: boolean;
    onDismiss: () => void
    onConfirm: () => void
    hideCancel?: boolean
    type?: "modal" | "non-modal" | "alert";
}

export const ConfirmationDialog: React.FunctionComponent<IConfirmationDialogProps> = (props) => {

    return (
        <Dialog
            open={props.open}
            onOpenChange={() => props.onDismiss()}
            modalType={props.type || "modal"}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >{props.title}</DialogTitle>
                    <DialogContent>
                        <ErrorBoundary>
                            {props.children}
                        </ErrorBoundary>
                    </DialogContent>
                    <DialogActions>
                        {!props.hideCancel &&
                            <DialogTrigger action="close">
                                <Button>Cancel</Button>
                            </DialogTrigger>
                        }
                        <Button
                            appearance="primary"
                            onClick={props.onConfirm}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}