import React, { useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { IBaseQueryFilter, IBaseQueryParams, IBaseQueryResult, IPhoneNumberExtended, PhoneNumberClassification, PhoneNumberType, PstnAssignmentStatus } from "@symity-hub/types";
import { useNavigate } from "react-router-dom";
import { getOptionsFromEnum } from "../modules/enum";
import { IQueryProps } from "../types/IQueryProps";
import { IDropdownFilter } from "../types/IDropdownFilter";
import { renderPhoneNumberWithExtension } from "../modules/string";
import { Link, TableColumnDefinition, createTableColumn } from "@fluentui/react-components";
import CustomDataGrid from "./CustomDataGrid";
import { useMsal } from "@azure/msal-react";
import useSWR from "swr";
import { fetcher } from "../modules/api";
import { populateDropdownFilter } from "./DropdownFilters";
import { CustomTableCellLayout } from "./CustomTableCellLayout";

interface IPhoneNumberListProps {
    defaultFilters?: IBaseQueryFilter[];
    hideRange?: boolean;
}

export const PhoneNumberList: React.FunctionComponent<IPhoneNumberListProps> = (props) => {

    const navigate = useNavigate();
    const msalContext = useMsal();

    useSWR<IBaseQueryResult>([`/api/voice/phoneNumberRanges`, msalContext, undefined, {
        sortBy: "name",
        limit: 100,
        select: ["id", "name"],
    }], fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        onSuccess: (data) => {
            populateDropdownFilter(data, dropdownFilters, setDropdownFilters, "rangeId", "id", "name");
        }
    });

    const defaultQueryParams: IBaseQueryParams = {
        sortBy: "phoneNumber",
        filters: props.defaultFilters || []
    };

    const defaultDropdownFilters: IDropdownFilter[] = [
        {
            name: "classification",
            label: "Classification",
            options: getOptionsFromEnum(PhoneNumberClassification),
            selectedKeys: [],
            multiSelect: true
        },
        {
            name: "numberType",
            label: "Type",
            options: getOptionsFromEnum(PhoneNumberType),
            selectedKeys: [],
            multiSelect: true
        },
        {
            name: "pstnAssignmentStatus",
            label: "Assignment",
            options: getOptionsFromEnum(PstnAssignmentStatus),
            selectedKeys: [],
            multiSelect: true
        }
    ]

    // Add range filter to start of array
    if (!props.hideRange) {
        defaultDropdownFilters.unshift({
            name: "rangeId",
            label: "Range",
            options: [],
            selectedKeys: [],
            multiSelect: true
        });
    }

    const [queryProps, setQueryProps] = useState<IQueryProps>({
        params: defaultQueryParams,
        searchField: "phoneNumber",
        searchPlaceholderText: "Search by phone number"
    });

    const [dropdownFilters, setDropdownFilters] = useState<IDropdownFilter[]>(defaultDropdownFilters);

    const columns: TableColumnDefinition<IPhoneNumberExtended>[] = [
        createTableColumn<IPhoneNumberExtended>({
            columnId: "phoneNumber",
            renderHeaderCell: () => {
                return "Phone number";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={renderPhoneNumberWithExtension(item.phoneNumber, item.extensionNumber)}>
                        <Link onClick={() => { navigate(`/voice/phoneNumbers/${item.id}`) }}>{renderPhoneNumberWithExtension(item.phoneNumber, item.extensionNumber)}</Link>
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.phoneNumber.localeCompare(b.phoneNumber);
            }
        }),
        createTableColumn<IPhoneNumberExtended>({
            columnId: "displayName",
            renderHeaderCell: () => {
                return "Assigned to";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.userDisplayName}>
                        {item.userId && (
                            <Link onClick={() => { navigate(`/accounts/${item.userId}`) }}>{item.userDisplayName}</Link>
                        )}
                    </CustomTableCellLayout>
                );
            },
            // compare: (a, b) => {
            //     const aName = a.userDisplayName || "";
            //     const bName = b.userDisplayName || "";
            //     return aName.localeCompare(bName);
            // }
        }),
        createTableColumn<IPhoneNumberExtended>({
            columnId: "classification",
            renderHeaderCell: () => {
                return "Classification";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.classification}>
                        {item.classification}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.classification.localeCompare(b.classification);
            },
        }),
        createTableColumn<IPhoneNumberExtended>({
            columnId: "numberType",
            renderHeaderCell: () => {
                return "Type";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.numberType}>
                        {item.numberType}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.numberType.localeCompare(b.numberType);
            },
        }),
        createTableColumn<IPhoneNumberExtended>({
            columnId: "pstnAssignmentStatus",
            renderHeaderCell: () => {
                return "Assignment status";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.pstnAssignmentStatus}>
                        {item.pstnAssignmentStatus}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.pstnAssignmentStatus.localeCompare(b.pstnAssignmentStatus);
            },
        })
    ];

    // If range is to be shown, insert it as the second column
    if (!props.hideRange) {
        columns.splice(2, 0, createTableColumn<IPhoneNumberExtended>({
            columnId: "rangeName",
            renderHeaderCell: () => {
                return "Range";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.rangeName}>
                        {item.rangeId && (
                            <Link onClick={() => { navigate(`/voice/phoneNumberRanges/${item.rangeId}`) }}>{item.rangeName}</Link>
                        )}
                    </CustomTableCellLayout>
                );
            }
        }));
    }

    return (
        <ErrorBoundary>
            <CustomDataGrid
                url="/api/voice/phoneNumbers"
                queryProps={queryProps}
                setQueryProps={setQueryProps}
                columns={columns}
                dropdownFilters={dropdownFilters}
                setDropdownFilters={setDropdownFilters}
            />
        </ErrorBoundary>
    );
}
