import React, { useContext, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { sharedHorizontalMediumGapWrapFlexStyles } from "../styles/styles";
import { AssignUserPoliciesPanel } from "./AssignUserPoliciesPanel";
import { Section } from "./Section";
import { PropertyAndValue } from "./PropertyAndValue";
import { TeamsUserContext } from "../contexts/TeamsUserContext";
import { Button } from "@fluentui/react-components";
import { Edit24Regular } from "@fluentui/react-icons";
import { assignTeamsPolicyPermission } from "@symity-hub/types";
import { RbacWrapper } from "./RbacWrapper";

interface IUserPoliciesProps {
    onDismissPanel: () => void
    isLoading: boolean;
}

export const UserPolicies: React.FunctionComponent<IUserPoliciesProps> = (props) => {

    const teamsUserContext = useContext(TeamsUserContext);

    const [panelOpen, setPanelOpen] = useState<boolean>(false);

    const onDismissPanel = () => {
        props.onDismissPanel();
        setPanelOpen(false);
    }

    return (
        <ErrorBoundary>
            <div className={sharedHorizontalMediumGapWrapFlexStyles().root} >
                <Section
                    title="Assigned Teams policies"
                    description="The following Teams policies are assigned to this account."
                    isLoading={props.isLoading}
                    action={(
                        <RbacWrapper allowedPermissions={[assignTeamsPolicyPermission]}>
                            <Button
                                icon={<Edit24Regular />}
                                onClick={() => setPanelOpen(true)}
                                appearance="outline"
                            >
                                Edit
                            </Button>
                        </RbacWrapper>
                    )}
                >
                    <div>
                        {teamsUserContext.teamsDetails && teamsUserContext.teamsDetails.policies && teamsUserContext.teamsDetails.policies.map((policy) => {
                            return (
                                <PropertyAndValue
                                    key={policy.policyType}
                                    property={policy.displayName}
                                    value={policy.currentPolicyName}
                                />
                            )
                        })}
                    </div>
                </Section>
            </div>
            {panelOpen && teamsUserContext.user && teamsUserContext.teamsDetails && (
                <AssignUserPoliciesPanel
                    open={panelOpen}
                    setOpen={setPanelOpen}
                    onDismiss={onDismissPanel}
                    users={[teamsUserContext.user]}
                    assignedUserPolicies={teamsUserContext.teamsDetails.policies}
                />
            )}
        </ErrorBoundary>
    );
};
