/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, TableColumnDefinition } from "@fluentui/react-components";

interface IBasicDataGridProps {
    columns: TableColumnDefinition<any>[];
    items: any[];
    size?: 'extra-small' | 'small' | 'medium';
}

export const BasicDataGrid: React.FunctionComponent<IBasicDataGridProps> = (props) => {

    return (
        <ErrorBoundary>
            <DataGrid
                items={props.items}
                columns={props.columns}
                sortable
                size={props.size}
            >
                <DataGridHeader>
                    <DataGridRow
                        selectionCell={undefined}
                    >
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody<unknown>>
                    {({ item, rowId }) => (
                        <DataGridRow<unknown>
                            key={rowId}
                            selectionCell={undefined}
                        >
                            {({ renderCell }) => (
                                <DataGridCell>{renderCell(item)}</DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>
        </ErrorBoundary>
    );
};
