import React, { useCallback, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { IPhoneNumberRange, phoneNumberRangeSchema } from "@symity-hub/types";
import debounce from "lodash.debounce";
import { fetcher } from "../modules/api";
import { useMsal } from "@azure/msal-react";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { CustomEditDrawer } from "./CustomEditDrawer";
import { EntityPage } from "./EntityPage";
import useSWR from "swr";
import { Spinner, Text, Toolbar, ToolbarButton } from "@fluentui/react-components";
import { CustomInput } from "./CustomInput";
import { CustomTextarea } from "./CustomTextarea";
import { Delete16Regular } from "@fluentui/react-icons";
import { ZodError } from "zod";

interface IEditPhoneNumberRangePanelProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    data?: IPhoneNumberRange;
    setData?: React.Dispatch<React.SetStateAction<IPhoneNumberRange | undefined>>
}

export const EditPhoneNumberRangePanel: React.FunctionComponent<IEditPhoneNumberRangePanelProps> = (props) => {

    const msalContext = useMsal();
    const navigate = useNavigate();

    const [data, setData] = useState<IPhoneNumberRange | undefined>(props.data);
    const [saveData, setSaveData] = useState<IPhoneNumberRange>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<ZodError>();
    const [phoneNumberLookup, setPhoneNumberLookup] = useState<string>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { isValidating, error: lookupError } = useSWR<any>(phoneNumberLookup ? [`/api/voice/phoneNumbers/lookup/${phoneNumberLookup}`, msalContext, "GET"] : null, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        errorRetryCount: 0,
        onSuccess: (response) => {
            // Set carrier information
            if (data) {
                setData({
                    ...data,
                    carrier: data.carrier || response.carrier?.name || undefined,
                });
            }
        }
    });

    const onSave = async () => {
        try {
            await phoneNumberRangeSchema.parseAsync(data)
                .then(() => {
                    setValidationError(undefined);
                    setSaveData(data);
                    props.setData?.(data)
                    props.setOpen(false);
                })
                .catch((error: ZodError) => {
                    setValidationError(error);
                    setSaveData(undefined);
                });
        } catch (error) {
            console.error(error);
        }
    }

    const onDelete = async () => {
        // Send request
        if (props.data?.id)
            await fetcher([`/api/voice/phoneNumberRanges/${props.data.id}`, msalContext, "DELETE"])
                .then(() => {
                    setDialogOpen(false);
                    props.setOpen(false);
                    setTimeout(() => {
                        navigate(`/voice/phoneNumberRanges`);
                    }, 1000);
                });
    }

    const onDismiss = () => {
        props.setOpen(false);
        setSaveData(undefined);
    }

    // Debounce the phone number lookup
    const onRangeStartChange = useCallback(debounce((value?: string) => {
        if (value) {
            setPhoneNumberLookup(value);
        }
    }, 1000), []);

    return (
        <CustomEditDrawer
            open={props.open}
            onDismiss={onDismiss}
            onSave={onSave}
            headerText="Phone number range"
            validationError={validationError}
        >
            <ErrorBoundary>
                <EntityPage
                    url={data && data.id ? `/api/voice/phoneNumberRanges/${data.id}` : `/api/voice/phoneNumberRanges/new`}
                    setData={setData}
                    saveData={saveData}
                >
                    {data && (
                        <div>
                            {props.data?.id && (
                                <Toolbar
                                    size="small"
                                >
                                    <ToolbarButton
                                        appearance="subtle"
                                        icon={<Delete16Regular />}
                                        onClick={() => { setDialogOpen(true); }}
                                    >
                                        Delete
                                    </ToolbarButton>
                                </Toolbar>
                            )}
                            <CustomInput
                                key="name"
                                id="name"
                                label="Name"
                                required={!phoneNumberRangeSchema.shape.name.isOptional()}
                                value={data.name}
                                onChange={(e, v) => setData({ ...data, name: v.value })}
                                validationError={validationError}
                            />
                            <CustomInput
                                id="description"
                                label="Description"
                                required={!phoneNumberRangeSchema.shape.description.isOptional()}
                                value={data.description}
                                onChange={(e, v) => setData({ ...data, description: v.value })}
                                validationError={validationError}
                            />
                            <CustomInput
                                id="rangeStart"
                                label="Range start"
                                required={!phoneNumberRangeSchema.shape.rangeStart.isOptional()}
                                value={data.rangeStart ? `${data.rangeStart.toString()}` : ""}
                                onChange={(e, v) => {
                                    // Set data
                                    setData({
                                        ...data,
                                        // Remove all non-numeric characters
                                        rangeStart: `+${v.value.replace(/[^0-9]/g, "").toString()}`,
                                        rangeEnd: `+${((parseInt(v.value)) + (data.totalPhoneNumbersInRange || 1) - 1).toString()}`
                                    })
                                    // Check if number is valid
                                    onRangeStartChange(v.value);
                                }}
                                validationError={validationError}
                                errorMessage={!isValidating && lookupError ? "Unable to check the validity of this number" : undefined}
                                contentAfter={isValidating ? <Spinner size="tiny" /> : undefined}
                            />
                            <CustomInput
                                id="totalPhoneNumbersInRange"
                                label="Numbers in range"
                                required={!phoneNumberRangeSchema.shape.totalPhoneNumbersInRange.isOptional()}
                                value={data.totalPhoneNumbersInRange ? data.totalPhoneNumbersInRange.toString() : ""}
                                onChange={(e, v) => setData({
                                    ...data,
                                    totalPhoneNumbersInRange: parseInt(v.value.replace(/[^0-9]/g, "").toString()),
                                    rangeEnd: `+${(parseInt(data.rangeStart) + parseInt(v.value) - 1).toString()}`
                                })}
                                validationError={validationError}
                            />
                            <CustomInput
                                id="rangeEnd"
                                label="Range end"
                                required={!phoneNumberRangeSchema.shape.rangeEnd.isOptional()}
                                value={data.rangeEnd ? data.rangeEnd : ""}
                                disabled
                                validationError={validationError}
                                hint="This is automatically calculated based on the range start and the total numbers in the range."
                            />
                            <CustomInput
                                id="carrier"
                                label="Carrier"
                                required={!phoneNumberRangeSchema.shape.carrier.isOptional()}
                                value={data.carrier || ""}
                                onChange={(e, v) => setData({ ...data, carrier: v.value })}
                                validationError={validationError}
                            />
                            <CustomTextarea
                                id="notes"
                                label="Notes"
                                required={!phoneNumberRangeSchema.shape.notes.isOptional()}
                                value={data.notes || ""}
                                onChange={(e, v) => setData({ ...data, notes: v.value })}
                                validationError={validationError}
                            />
                        </div>
                    )}
                </EntityPage>
            </ErrorBoundary>
            <ConfirmationDialog
                open={dialogOpen}
                onDismiss={() => setDialogOpen(false)}
                onConfirm={() => onDelete()}
                title="Delete phone number range?"
            >
                <Text>Are you sure you wish to delete this phone number range?</Text>
            </ConfirmationDialog>
        </CustomEditDrawer>
    );
};
