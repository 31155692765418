export enum TeamsPowerShellTaskType {
    GetUser = 'GetUser',

    // GetUsers = 'GetUsers',
    GetPhoneNumberAssignments = 'GetPhoneNumberAssignments',
    GetPolicies = 'GetPolicies',
    GetResourceAccounts = 'GetResourceAccounts',

    BatchPolicyAssignment = 'BatchPolicyAssignment',
    PhoneNumberAssignment = 'PhoneNumberAssignment',
}