import React from "react";
import { Field, Input, InputOnChangeData } from "@fluentui/react-components";
import { sharedInputStyles } from "../styles/styles";
import { ZodError } from "zod";

interface ICustomInputProps {
    id: string;
    label: string;
    required?: boolean;
    disabled?: boolean;
    value?: string;
    onChange?: (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
    hint?: string;
    validationError?: ZodError;
    errorMessage?: string;
    contentBefore?: JSX.Element;
    contentAfter?: JSX.Element;
}

export const CustomInput: React.FunctionComponent<ICustomInputProps> = (props) => {

    return (
        <Field
            label={props.label}
            required={props.required}
            validationMessage={props.validationError?.issues.find(e => e.path.includes(props.id))?.message || props.errorMessage || undefined}
            hint={props.hint}
            className={sharedInputStyles().root}
        >
            <Input
                disabled={props.disabled}
                onChange={props.onChange}
                value={props.value || ""}
                contentBefore={props.contentBefore}
                contentAfter={props.contentAfter}
            />
        </Field>
    );
};
