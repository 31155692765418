import React from "react";
import { CustomAlert, ICustomAlertProps } from "./CustomAlert";
import { DismissCircleRegular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

export interface IAppAlertsProps {
    alerts: ICustomAlertProps[];
    setAlerts: React.Dispatch<React.SetStateAction<ICustomAlertProps[]>>;
}

export const AppAlerts: React.FunctionComponent<IAppAlertsProps> = (props) => {

    return (
        <div>
            {props.alerts.map((alert, index) => {
                return (
                    <CustomAlert
                        id= {alert.id}
                        key={index}
                        text={alert.text}
                        type={alert.type}
                        action={(
                            <Button
                                appearance="subtle"
                                size="small"
                                aria-label="Dismiss alert"
                                icon={<DismissCircleRegular />}
                                onClick={() => {
                                    props.setAlerts((alerts) => {
                                        return alerts.filter((a) => a !== alert);
                                    });
                                }}
                            />
                        )}
                    />
                )
            })}
        </div>
    );
};
