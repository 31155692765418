import { z } from "zod";
import { baseCosmosItemSchema } from "./IBaseCosmosItem";
import { ITeamsAssignedPlan } from "./ITeamsAssignedPlan";
import { ITeamsPolicy } from "./ITeamsPolicy";
import { AccountType } from "./enums/AccountType";

export const userSchema = baseCosmosItemSchema.extend({
    displayName: z.string(),
    userPrincipalName: z.string(),
    usageLocation: z.string()
        .min(2)
        .max(2)
        .optional(),
    scopes: z.array(
        z.string()
            .uuid()
    )
        .optional(),
    roles: z.array(
        z.string()
            .uuid()
    )
        .optional(),
    acceptedTermsAndConditionsDate: z.string()
        .datetime()
        .optional(),
    accountType: z.nativeEnum(AccountType)
})
    .strict();

export type IUser = z.infer<typeof userSchema>;

// Extended user with Teams and Graph properties
export type IUserExtended = Omit<IUser, "scopes" | "roles" | "acceptedTermsAndConditionsDate"> & {
    accountType?: string;
    userType?: string;
    accountEnabled?: boolean;
    jobTitle?: string;
    assignedPlans?: ITeamsAssignedPlan[];
    dialPlan?: string;
    enterpriseVoiceEnabled?: boolean;
    interpretedUserType?: string;
    phoneNumber?: string;
    extensionNumber?: string;
    policies?: ITeamsPolicy[];
};