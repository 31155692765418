import { z } from "zod";
import { operationTaskSchema } from "./IOperationTask";
import { SyncType } from "./enums/SyncType";

export const syncTaskSchema = operationTaskSchema.extend({
    // Graph delta link, may be used to continue sync/delta operations for a task against the Graph API
    deltaLink: z.string()
        .optional(),
    syncType: z.nativeEnum(SyncType)
})
    .strict();

export type ISyncTask = z.infer<typeof syncTaskSchema>;