import React, { useEffect, useState } from "react";
import { sharedHorizontalMediumGapFlexStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { User } from "@microsoft/microsoft-graph-types";
import { IPhoneNumberExtended, ITeamsPowerShellTask, PhoneNumberAssignmentAction, PhoneNumberType, TeamsPowerShellTaskType } from "@symity-hub/types";
import { IQueryProps } from "../types/IQueryProps";
import { IDropdownFilter } from "../types/IDropdownFilter";
import { renderPhoneNumberAssignmentText } from "../modules/string";
import CustomDataGrid from "./CustomDataGrid";
import { Button, Caption1, Subtitle2, TableCellLayout, TableColumnDefinition, createTableColumn, makeStyles, tokens } from "@fluentui/react-components";
import { Add24Regular, ArrowLeft24Regular, Delete24Regular } from "@fluentui/react-icons";
import { CustomInput } from "./CustomInput";

interface IChoosePhoneNumberProps {
    queryProps: IQueryProps;
    setQueryProps: React.Dispatch<React.SetStateAction<IQueryProps>>;
    dropdownFilters: IDropdownFilter[];
    setDropdownFilters: React.Dispatch<React.SetStateAction<IDropdownFilter[]>>;
    tasks: ITeamsPowerShellTask[];
    setTasks: React.Dispatch<React.SetStateAction<ITeamsPowerShellTask[]>>;
    userToAssign: User;
    setUserToAssign: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const choosePhoneNumberStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "300px"
    },
    phoneNumber: {
        display: "flex",
        flexDirection: "row",
        alignItems: "end",
        gap: (tokens.spacingVerticalS, tokens.spacingHorizontalS)
    }
});

export const ChoosePhoneNumber: React.FunctionComponent<IChoosePhoneNumberProps> = (props) => {

    // Destructure props
    const {
        queryProps,
        setQueryProps,
        dropdownFilters,
        setDropdownFilters,
        tasks,
        setTasks,
        userToAssign,
        setUserToAssign
    } = props;

    const [task, setTask] = useState<ITeamsPowerShellTask | undefined>(tasks.find(task => task.phoneNumberAssignment?.userId === userToAssign.id));

    const onAssignNumber = (phoneNumber: IPhoneNumberExtended) => {
        // Check if number is not already assigned and user is selected
        const numberAssigned = tasks.some((task) => task.phoneNumberAssignment?.phoneNumber === phoneNumber.phoneNumber && task.phoneNumberAssignment?.action !== PhoneNumberAssignmentAction.Unassign);
        if (userToAssign && userToAssign.id && !numberAssigned) {
            setTask({
                name: renderPhoneNumberAssignmentText(PhoneNumberAssignmentAction.Assign, phoneNumber.phoneNumber, phoneNumber.extensionNumber, userToAssign.displayName || ""),
                type: TeamsPowerShellTaskType.PhoneNumberAssignment,
                phoneNumberAssignment: {
                    phoneNumber: phoneNumber.phoneNumber,
                    userId: userToAssign.id,
                    phoneNumberType: phoneNumber.numberType,
                    action: PhoneNumberAssignmentAction.Assign,
                    extensionNumber: phoneNumber.extensionNumber
                }
            });
        }
    }

    // When the user clicks the remove button, remove the phone number assignment
    const onUnassignNumber = () => {
        setTask(undefined);
    }

    // When the extension number changes, update the phone number assignment
    const onChangeExtensionNumber = (extensionNumber?: string) => {
        if (task && task.phoneNumberAssignment) {
            setTask({
                ...task,
                name: renderPhoneNumberAssignmentText(task.phoneNumberAssignment.action, task.phoneNumberAssignment.phoneNumber, extensionNumber, userToAssign?.displayName || ""),
                phoneNumberAssignment: {
                    ...task.phoneNumberAssignment,
                    extensionNumber: extensionNumber && extensionNumber.length > 0 ? extensionNumber : undefined,
                }
            });
        }
    }

    // When the phone number assignment changes, update the phone number assignments
    useEffect(() => {
        if (task) {
            setTasks([
                ...tasks.filter(task => task.phoneNumberAssignment?.userId !== userToAssign.id),
                task
            ]);
        } else {
            setTasks(tasks.filter(task => task.phoneNumberAssignment?.userId !== userToAssign.id));
        }
    }, [task, setTasks]);

    const columns: TableColumnDefinition<IPhoneNumberExtended>[] = [
        createTableColumn<IPhoneNumberExtended>({
            columnId: "phoneNumber",
            renderHeaderCell: () => {
                return "Phone number";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.phoneNumber}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<IPhoneNumberExtended>({
            columnId: "rangeName",
            renderHeaderCell: () => {
                return "Range";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.rangeName}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<IPhoneNumberExtended>({
            columnId: "classification",
            renderHeaderCell: () => {
                return "Classification";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.classification}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<IPhoneNumberExtended>({
            columnId: "numberType",
            renderHeaderCell: () => {
                return "Type";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.numberType}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<IPhoneNumberExtended>({
            columnId: "actions",
            renderHeaderCell: () => {
                return "";
            },
            renderCell: (item) => {
                // Check if the number is already assigned
                const isAssigned = tasks.some(task => task.phoneNumberAssignment?.phoneNumber === item.phoneNumber && task.phoneNumberAssignment?.action !== PhoneNumberAssignmentAction.Unassign);
                return (
                    <TableCellLayout>
                        <Button
                            icon={<Add24Regular />}
                            aria-label="Assign number"
                            disabled={isAssigned}
                            onClick={() => onAssignNumber(item)}
                        >
                            Assign
                        </Button>
                    </TableCellLayout>
                );
            }
        })
    ];

    return (
        <div className={sharedVerticalMediumGapFlexStyles().root}>
            <div className={choosePhoneNumberStyles().root}>
                <div className={sharedHorizontalMediumGapFlexStyles().root}>
                    <Button
                        aria-label="Back"
                        appearance="subtle"
                        icon={<ArrowLeft24Regular />}
                        onClick={() => setUserToAssign(undefined)}
                    />
                    <Subtitle2>{userToAssign.displayName}</Subtitle2>
                </div>
                {task && (
                    <div className={choosePhoneNumberStyles().phoneNumber}>
                        <CustomInput
                            id="phoneNumber"
                            label="Phone number"
                            value={(task.phoneNumberAssignment?.phoneNumber && task.phoneNumberAssignment?.action !== PhoneNumberAssignmentAction.Unassign) ? task.phoneNumberAssignment?.phoneNumber : ""}
                            disabled
                        />
                        <CustomInput
                            id="extensionNumber"
                            label="Extension number"
                            required={false}
                            value={task.phoneNumberAssignment?.extensionNumber || ""}
                            disabled={task.phoneNumberAssignment?.phoneNumberType !== PhoneNumberType.DirectRouting}
                            onChange={(event, data) => onChangeExtensionNumber(data.value)}
                        />
                        <CustomInput
                            id="phoneNumberType"
                            label="Type"
                            value={task?.phoneNumberAssignment?.phoneNumberType || ""}
                            disabled
                        />
                        <Button
                            aria-label="Remove"
                            icon={<Delete24Regular />}
                            disabled={!task || task.phoneNumberAssignment?.action === PhoneNumberAssignmentAction.Unassign}
                            onClick={() => onUnassignNumber()}
                        >
                            Remove
                        </Button>
                    </div>
                )}
            </div>
            {
                (!task || task.phoneNumberAssignment?.action === PhoneNumberAssignmentAction.Unassign) && (
                    <div className={sharedVerticalMediumGapFlexStyles().root}>
                        <Subtitle2>Phone numbers</Subtitle2>
                        <Caption1>The following numbers are available for assignment.</Caption1>
                        <CustomDataGrid
                            url="/api/voice/phoneNumbers"
                            queryProps={queryProps}
                            setQueryProps={setQueryProps}
                            columns={columns}
                            dropdownFilters={dropdownFilters}
                            setDropdownFilters={setDropdownFilters}
                        />
                    </div>
                )
            }
        </div >
    )
}
