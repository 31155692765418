import React, { useContext, useEffect, useState } from "react";
import { Alert24Regular } from "@fluentui/react-icons";
import { NotificationsPanel } from "./NotificationsPanel";
import { appHeaderStyles } from "./AppHeader";
import { IAudit, IBaseQueryResult, SubscriptionEventType } from "@symity-hub/types";
import { AppContext } from "../contexts/AppContext";
import { v4 as uuidv4 } from "uuid";
import { CounterBadge, makeStyles, ProgressBar, tokens } from "@fluentui/react-components";

interface INotificationsProps {
}

const notificationsStyles = makeStyles({
    root: {
        display: "inline-block",
        position: "relative"
    },
    progressBar: {
        position: "absolute",
        bottom: 0,
        left: 0,
        backgroundColor: tokens.colorNeutralForegroundStaticInverted
    },
    counterBadge: {
        position: "absolute",
        top: "12px",
        right: "5px"
    }
});

export const Notifications: React.FunctionComponent<INotificationsProps> = () => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<IBaseQueryResult>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runningOperations, setRunningOperations] = useState<number>(0);
    const [error, setError] = useState<string>();

    const appContext = useContext(AppContext);

    const callWebSocket = async () => {
        if (!appContext.sendMessage) {
            console.log("sendMessage not available in AppContext");
            return;
        }

        try {
            setIsLoading(true);
            await appContext.sendMessage({
                id: uuidv4(),
                type: SubscriptionEventType.AuditLog,
                updateFrequency: 10000
            }, (response) => {
                if (response) {
                    setData(response.data);
                }
                setIsLoading(false);
            });
        } catch (error) {
            console.error('WebSocket Error:', error);
            setError(error as string);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        callWebSocket();
    }, [appContext.sendMessage]);


    useEffect(() => {
        if (data && data.resources && data.resources.some((resource: IAudit) => resource.operation?.runtimeStatus === "Running" || resource.operation?.runtimeStatus === "Pending")) {
            setRunningOperations(data.resources.filter((resource: IAudit) => resource.operation?.runtimeStatus === "Running" || resource.operation?.runtimeStatus === "Pending").length);
        } else {
            setRunningOperations(0);
        }
    }, [data]);

    return (
        <div>
            <div className={notificationsStyles().root}>
                {runningOperations > 0 && (
                    <div>
                        <ProgressBar
                            shape="square"
                            thickness="large"
                            className={notificationsStyles().progressBar}
                        />
                        <CounterBadge
                            count={runningOperations}
                            size="small"
                            className={notificationsStyles().counterBadge}
                        />
                    </div>
                )}
                <div
                    className={appHeaderStyles().menuItem}
                    onClick={() => setOpen(!open)}
                >
                    <Alert24Regular />
                </div>
            </div>
            {open && (
                <NotificationsPanel
                    open={open}
                    setOpen={setOpen}
                    data={data}
                    isLoading={isLoading}
                    error={error}
                />
            )}
        </div>
    )
}
