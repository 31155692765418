import React from 'react'
import { ChatHelp24Regular } from '@fluentui/react-icons'
import { useNavigate } from "react-router-dom";
import { appHeaderStyles } from "./AppHeader";

export const CustomerHelp: React.FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/help")}
      className={appHeaderStyles().menuItem}
    >
      <ChatHelp24Regular />
    </div>
  )
}
