import React, { useContext } from "react";
import { tokens } from "@fluentui/react-components";
import { IReport } from "@symity-hub/types";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { ThemeContext } from "../contexts/ThemeContext";
import { symityDarkTheme } from "../styles/theme";

interface IPieChartProps {
    report: IReport;
}

export const PieChart: React.FunctionComponent<IPieChartProps> = (props) => {

    const themeContext = useContext(ThemeContext);

    const options: ApexOptions = {
        chart: {
            type: "donut",
            background: "none",
            fontFamily: tokens.fontFamilyBase
        },
        stroke: {
            width: 0,
        },
        dataLabels: {
            dropShadow: {
                enabled: false
            }
        },
        legend: {
            position: "bottom"
        },
        responsive: [{
            options: {
                legend: {
                    position: "bottom"
                }
            }
        }],
        theme: {
            mode: themeContext.theme === symityDarkTheme ? "dark" : "light",
        },
        labels: props.report.labels,
        colors: props.report.colors
    }

    return (
        <ReactApexChart
            options={options}
            series={props.report.data}
            type="donut"
        />
    );
};
