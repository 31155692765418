import React, { useState } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
import { sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { AccountType, IBaseQueryParams, IUserExtended, assignPhoneNumberPermission, assignTeamsPolicyPermission } from "@symity-hub/types";
import { useNavigate } from "react-router-dom";
import { IQueryProps } from "../types/IQueryProps";
import { AssignPhoneNumberPanel } from "./AssignPhoneNumberPanel";
import { AssignUserPoliciesPanel } from "./AssignUserPoliciesPanel";
import CustomDataGrid from "./CustomDataGrid";
import { TableColumnDefinition, createTableColumn, Link, ToolbarButton } from "@fluentui/react-components";
import { Call24Regular, Settings24Regular } from "@fluentui/react-icons";
import { RbacWrapper } from "./RbacWrapper";
import { renderPhoneNumberWithExtension } from "../modules/string";
import { CustomTableCellLayout } from "./CustomTableCellLayout";

interface IUserListProps {
    accountType: AccountType;
}
export const UserList: React.FunctionComponent<IUserListProps> = (props) => {

    const navigate = useNavigate();

    const [phoneNumberPanelOpen, setPhoneNumberPanelOpen] = useState<boolean>(false);
    const [policiesPanelOpen, setPoliciesPanelOpen] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<IUserExtended[]>([]);

    const defaultQueryParams: IBaseQueryParams = {
        sortBy: "displayName",
        filters: []
    };

    const [queryProps, setQueryProps] = useState<IQueryProps>({
        params: defaultQueryParams,
        searchField: "displayName",
        searchPlaceholderText: "Search by name"
    });

    // When the account type changes, reset the query params
    React.useEffect(() => {
        setQueryProps({
            params: {
                ...defaultQueryParams,
                filters: [
                    {
                        name: "accountType",
                        operator: "eq",
                        value: props.accountType
                    }
                ]
            },
            searchField: "displayName",
            searchPlaceholderText: "Search by name"
        });
    }, [props.accountType]);

    const columns: TableColumnDefinition<IUserExtended>[] = [
        createTableColumn<IUserExtended>({
            columnId: "displayName",
            renderHeaderCell: () => {
                return "Name";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.displayName}>
                        <Link onClick={() => { navigate(`/accounts/${item.id}`) }}>{item.displayName}</Link>
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.displayName.localeCompare(b.displayName);
            },
        }),
        createTableColumn<IUserExtended>({
            columnId: "phoneNumber",
            renderHeaderCell: () => {
                return "Phone number";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={renderPhoneNumberWithExtension(item.phoneNumber, item.extensionNumber)}>
                        {renderPhoneNumberWithExtension(item.phoneNumber, item.extensionNumber)}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                const aPhoneNumber = a.phoneNumber || "";
                const bPhoneNumber = b.phoneNumber || "";
                return aPhoneNumber.localeCompare(bPhoneNumber);
            },
        }),
        createTableColumn<IUserExtended>({
            columnId: "userPrincipalName",
            renderHeaderCell: () => {
                return "Username";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.userPrincipalName}>
                        {item.userPrincipalName}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.userPrincipalName.localeCompare(b.userPrincipalName);
            }
        }),
        createTableColumn<IUserExtended>({
            columnId: "usageLocation",
            renderHeaderCell: () => {
                return "Usage location";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.usageLocation}>
                        {item.usageLocation}
                    </CustomTableCellLayout>
                );
            }
        })
    ];

    return (
        <div className={sharedVerticalMediumGapFlexStyles().root} >
            <ErrorBoundary>
                <CustomDataGrid
                    url="/api/users"
                    queryProps={queryProps}
                    setQueryProps={setQueryProps}
                    columns={columns}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    selectionMode="multiselect"
                    toolbarButtons={[
                        <RbacWrapper allowedPermissions={[assignPhoneNumberPermission]}
                            key={0}
                        >
                            <ToolbarButton
                                appearance="subtle"
                                icon={<Call24Regular />}
                                onClick={() => setPhoneNumberPanelOpen(true)}
                                disabled={selectedItems.length === 0}
                            >
                                Assign phone numbers
                            </ToolbarButton>
                        </RbacWrapper>,
                        <RbacWrapper allowedPermissions={[assignTeamsPolicyPermission]}
                            key={1}
                        >
                            <ToolbarButton
                                appearance="subtle"
                                icon={<Settings24Regular />}
                                onClick={() => setPoliciesPanelOpen(true)}
                                disabled={selectedItems.length === 0}
                            >
                                Assign policies
                            </ToolbarButton>
                        </RbacWrapper>
                    ]}
                />
                {phoneNumberPanelOpen && <AssignPhoneNumberPanel users={selectedItems} open={phoneNumberPanelOpen} setOpen={setPhoneNumberPanelOpen} onDismiss={() => setPhoneNumberPanelOpen(false)} />}
                {policiesPanelOpen && <AssignUserPoliciesPanel users={selectedItems} open={policiesPanelOpen} setOpen={setPoliciesPanelOpen} onDismiss={() => setPoliciesPanelOpen(false)} />}
            </ErrorBoundary>
        </div>
    );
}
