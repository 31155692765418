import React, { useCallback } from "react";
import { IBaseQueryFilter } from "@symity-hub/types";
import ErrorBoundary from "./ErrorBoundary";
import debounce from "lodash.debounce";
import { IQueryProps } from "../types/IQueryProps";
import cloneDeep from "lodash.clonedeep";
import { sharedHorizontalMediumGapWrapFlexStyles } from "../styles/styles";
import { ICheckboxFilter } from "../types/ICheckboxFilter";
import { Checkbox } from "@fluentui/react-components";

interface ICheckboxFiltersProps {
    queryProps: IQueryProps;
    setQueryProps: React.Dispatch<React.SetStateAction<IQueryProps>>
    checkboxFilters: ICheckboxFilter[];
    setCheckboxFilters: React.Dispatch<React.SetStateAction<ICheckboxFilter[]>>
}


export const CheckboxFilters: React.FunctionComponent<ICheckboxFiltersProps> = (props) => {

    // Destructure props (to avoid callback requiring props as a dependency)
    const {
        queryProps,
        setQueryProps,
        checkboxFilters,
        setCheckboxFilters
    } = props;

    /**
     * When a filter changes, update the query params (on a debounced callback)
     */
    const onQueryChange = useCallback(debounce((filters: IBaseQueryFilter[]) => {
        // Add filters to query params
        setQueryProps({
            ...queryProps,
            params: {
                ...queryProps.params,
                filters,
                page: 1
            }
        });
    }, 1000), [queryProps, setQueryProps]);

    /**
     * When a filter option is checked or unchecked, add/remove it to the checkbox filter checked keys
     * @param event
     * @param checked
     * @param filterName
        */
    const onCheckboxFilterChange = useCallback((checked: boolean, filterName: string) => {
        const newCheckboxFilters = cloneDeep(checkboxFilters);
        const checkboxFilter = newCheckboxFilters.find((filter) => filter.name === filterName);
        if (checkboxFilter) {
            checkboxFilter.checked = checked;
            setCheckboxFilters(newCheckboxFilters);
        }
        // Add query filters
        const queryFilters = queryProps.params.filters && queryProps.params.filters.filter((filter: IBaseQueryFilter) => filter.name !== filterName) || [];
        if (checkboxFilter && checkboxFilter.checked) {
            queryFilters?.push({
                name: checkboxFilter.name,
                operator: "eq",
                value: checked
            });
        }
        // Add filters to query params
        onQueryChange(queryFilters);
    }, [checkboxFilters, onQueryChange, queryProps.params.filters, setCheckboxFilters]);

    return (
        <ErrorBoundary>
            <div className={sharedHorizontalMediumGapWrapFlexStyles().root}>
                {checkboxFilters?.map((filter) => (
                    <Checkbox
                        id={filter.name}
                        key={filter.name}
                        label={filter.label}
                        checked={filter.checked}
                        onChange={(e, d) => onCheckboxFilterChange(d.checked as boolean, filter.name)}
                    />
                ))}
            </div>
        </ErrorBoundary>
    );
};
