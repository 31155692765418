import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CustomAlert } from "./CustomAlert";
import { PhoneNumberPage } from "./PhoneNumberPage";
import { PhoneNumberList } from "./PhoneNumberList";
import { PhoneNumberRangePage } from "./PhoneNumberRangePage";
import { PhoneNumberRangeList } from "./PhoneNumberRangeList";
import { UserList } from "./UserList";
import { UserPage } from "./UserPage";
import { AdminUserList } from "./AdminUserList";
import { Help } from "./Help";
import { AuditList } from "./AuditList";
import { Dashboard } from "./Dashboard";
import { SettingsPage } from "./SettingsPage";
import { AccountType, TaskStatus } from "@symity-hub/types";
import { RoleList } from "./RoleList";


export const ContentRoutes: React.FunctionComponent = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/help" element={<Help />} />
                <Route path="/settings" element={<SettingsPage />} />
                {/* Voice */}
                <Route path="/voice" element={<Navigate replace to="/voice/phoneNumbers" />} />
                <Route path="/voice/phoneNumbers" element={<PhoneNumberList />} />
                <Route path="/voice/phoneNumbers/:id" element={<PhoneNumberPage />} />
                <Route path="/voice/phoneNumberRanges" element={<PhoneNumberRangeList />} />
                <Route path="/voice/phoneNumberRanges/:id" element={<PhoneNumberRangePage />} />
                {/* Users */}
                <Route path="/accounts" element={<Navigate replace to="/accounts/users" />} />
                <Route path="/accounts/users" element={<UserList accountType={AccountType.UserAccount} />} />
                <Route path="/accounts/resourceAccounts" element={<UserList accountType={AccountType.ResourceAccount} />} />
                <Route path="/accounts/:id" element={<UserPage />} />
                {/* Admin */}
                <Route path="/admin" element={<Navigate replace to="/admin/users" />} />
                <Route path="/admin/users" element={<AdminUserList />} />
                <Route path="/admin/roles" element={<RoleList />} />
                <Route path="/admin/logs" element={<AuditList />} />
                {/* Catch all */}
                <Route path="*" element={<CustomAlert text="Sorry, the page is not found." type={TaskStatus.Error} id="pageNotFound" />} />
            </Routes>
        </div>
    );
};
