import React from "react";
import { readAuditPermission, readPhoneNumberPermission, readUserPermission, readAdminUserPermission } from "@symity-hub/types";
import { makeStyles, tokens } from "@fluentui/react-components";
import { Call24Regular, ChartMultiple24Regular, PeopleTeam24Regular, Settings24Regular } from "@fluentui/react-icons";
import { INavMenuGroup, NavMenuGroup } from "./NavMenuGroup";
import { Sync } from "./Sync";

const navMenuStyles = makeStyles({
    root: {
        width: "250px",
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        boxShadow: tokens.shadow4,
        paddingLeft: tokens.spacingHorizontalXXS,
        backgroundColor: tokens.colorNeutralBackground3,
        clipPath: "inset(0px -15px 0px 0px);"
    },
    sync: {
        width: "252px",
        position: 'fixed',
        bottom: '0px',
        left: '0px'
    }
});

const navMenuGroups: INavMenuGroup[] = [
    {
        key: "dashboard",
        name: "Dashboard",
        icon: <ChartMultiple24Regular />,
        url: "/"
    },
    {
        key: "voice",
        name: "Voice",
        icon: <Call24Regular />,
        requiredPermissionId: readPhoneNumberPermission.id,
        links: [
            {
                key: '/voice/phoneNumbers',
                name: 'Phone numbers',
                url: '/voice/phoneNumbers',
                requiredPermissionId: readPhoneNumberPermission.id
            },
            {
                key: '/voice/phoneNumberRanges',
                name: 'Phone number ranges',
                url: '/voice/phoneNumberRanges',
                requiredPermissionId: readPhoneNumberPermission.id
            }
        ]
    },
    {
        key: '/accounts',
        name: 'Accounts',
        url: '/accounts',
        requiredPermissionId: readUserPermission.id,
        icon: <PeopleTeam24Regular />,
        links: [
            {
                key: '/accounts/users',
                name: 'Users',
                url: '/accounts/users',
                requiredPermissionId: readUserPermission.id
            },
            {
                key: '/accounts/resourceAccounts',
                name: 'Resource accounts',
                url: '/accounts/resourceAccounts',
                requiredPermissionId: readUserPermission.id
            }
        ]
    },
    {
        key: "admin",
        name: "Admin",
        icon: <Settings24Regular />,
        requiredPermissionId: readAdminUserPermission.id,
        links: [
            {
                key: '/admin/users',
                name: 'Users',
                url: '/admin/users',
                requiredPermissionId: readAdminUserPermission.id
            },
            {
                key: '/admin/logs',
                name: 'Logs',
                url: '/admin/logs',
                requiredPermissionId: readAuditPermission.id
            },
            {
                key: '/admin/roles',
                name: 'Roles',
                url: '/admin/roles',
                requiredPermissionId: readAdminUserPermission.id
            }
        ]
    }
];

export const NavMenu: React.FunctionComponent = () => {

    return (
        <div>
            <div className={navMenuStyles().root}>
                {navMenuGroups.map((navMenuGroup) => {
                    return (
                        <NavMenuGroup
                            key={navMenuGroup.key}
                            navMenuGroup={navMenuGroup}
                        />
                    );
                })}
            </div>
            <div className={navMenuStyles().sync}>
                <Sync />
            </div>
        </div>
    );
}
