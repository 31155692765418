import { IUserScope } from "../types/IUserScope";

export const defaultUserScope: IUserScope = {
    id: "2b268752-c1fd-455d-9160-9ee9b0d9960e",
    displayName: "Default",
    description: "Default scope"
}

export const allUserScopes: IUserScope[] = [
    defaultUserScope
]
