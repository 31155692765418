import React, { useContext, useEffect, useState } from "react";
import { INavMenuLink, NavMenuLink } from "./NavMenuLink";
import { Link, makeStyles } from "@fluentui/react-components";
import { ChevronDown24Regular, ChevronUp24Regular } from "@fluentui/react-icons";
import { sharedNavMenuStyles } from "../styles/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import { IUserPermission } from "@symity-hub/types";

interface INavMenuGroupProps {
    navMenuGroup: INavMenuGroup;
}

export interface INavMenuGroup {
    key: string;
    name: string;
    icon: JSX.Element;
    url?: string;
    links?: INavMenuLink[];
    requiredPermissionId?: string;
}

const navMenuGroupStyles = makeStyles({
    chevron: {
        marginLeft: "auto"
    }
});

export const NavMenuGroup: React.FunctionComponent<INavMenuGroupProps> = (props) => {

    const signedInUserContext = useContext(SignedInUserContext);

    const location = useLocation();
    const navigate = useNavigate();

    const [expanded, setExpanded] = useState<boolean>(false);
    const [groupAccessible, setGroupAccessible] = useState<boolean>(false);
    const [accessibleLinks, setAccessibleLinks] = useState<INavMenuLink[]>([]);

    const onClick = () => {
        if (props.navMenuGroup.links) {
            setExpanded(!expanded);
        }
        if (props.navMenuGroup.url) {
            navigate(props.navMenuGroup.url);
        }
    }

    // Check if the current location is a child of this group
    useEffect(() => {
        if (props.navMenuGroup.links) {
            props.navMenuGroup.links.forEach((navMenuLink) => {
                if (location.pathname === navMenuLink.url) {
                    setExpanded(true);
                }
            });
        }
    }, [location.pathname, props.navMenuGroup.links]);

    // Check which links in the group are accessible to the user
    useEffect(() => {
        // Check if the user has the required permission for the group
        if ((signedInUserContext && signedInUserContext.signedInUser && signedInUserContext.signedInUser.permissions.find((permission: IUserPermission) => permission.id === props.navMenuGroup.requiredPermissionId)) || (props.navMenuGroup.requiredPermissionId === undefined)) {
            setGroupAccessible(true);
            // Check if the user has the required permission for each link
            if (props.navMenuGroup.links) {
                const links: INavMenuLink[] = [];
                props.navMenuGroup.links.forEach((navMenuLink) => {
                    if (navMenuLink.requiredPermissionId) {
                        // Check if the user has the required permission
                        if ((signedInUserContext && signedInUserContext.signedInUser && signedInUserContext.signedInUser.permissions.find((permission: IUserPermission) => permission.id === navMenuLink.requiredPermissionId)) || (navMenuLink.requiredPermissionId === undefined)) {
                            links.push(navMenuLink);
                        }
                    }
                });
                setAccessibleLinks(links);
            }
        }
    }, [signedInUserContext, props.navMenuGroup, props.navMenuGroup.links, props.navMenuGroup.requiredPermissionId]);

    return (
        <div
            key={props.navMenuGroup.key}
        >
            {groupAccessible ?
                <Link
                    key={props.navMenuGroup.key}
                    onClick={onClick}
                    className={sharedNavMenuStyles().root}
                >
                    <div>{props.navMenuGroup.icon}</div>
                    <div>{props.navMenuGroup.name}</div>
                    <div className={navMenuGroupStyles().chevron}>
                        {props.navMenuGroup.links ?
                            expanded ? <ChevronUp24Regular /> : <ChevronDown24Regular />
                            : null
                        }
                    </div>
                </Link>
                : null
            }
            {expanded && accessibleLinks.map((navMenuLink) => {
                return (
                    <div
                        key={navMenuLink.key}
                    >
                        <NavMenuLink
                            navMenuLink={navMenuLink}
                        />
                    </div>
                );
            })}
        </div>
    );
}
