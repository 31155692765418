import { IBaseQueryFilter } from "./IBaseQueryFilter";

export interface IBaseQueryParams {
    page?: number;
    limit?: number;
    sortDirection?: "ASC" | "DESC";
    sortBy?: string;
    filters?: IBaseQueryFilter[];
    select?: string[];
}

export const defaultPage = 1;
export const defaultLimit = 10;
export const defaultSortDirection = "ASC";
export const defaultSortBy = "id";