import React from "react";
import { CustomDrawer } from "./CustomDrawer";
import ErrorBoundary from "./ErrorBoundary";
import { IAudit, IAuditExtended, IBaseQueryResult, TaskStatus } from "@symity-hub/types";
import { Link, Spinner, Text, makeStyles } from "@fluentui/react-components";
import { sharedHorizontalExtraSmallGapFlexStyles, sharedHorizontalNoGapFlexStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { StatusIcon } from "./StatusIcon";
import { DateTime } from "luxon";
import { CustomAlert } from "./CustomAlert";
import { Section } from "./Section";
import { AuditPage } from "./AuditPage";

interface INotificationsPanelProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data?: IBaseQueryResult;
  isLoading: boolean;
  error?: string;
}

const notificationsPanelStyles = makeStyles({
  date: {
    marginLeft: "auto",
  }
});

export const NotificationsPanel: React.FunctionComponent<INotificationsPanelProps> = (props) => {

  const [selectedAudit, setSelectedAudit] = React.useState<IAudit>();

  const onDismiss = () => {
    props.setOpen(false);
  }

  return (
    <CustomDrawer
      open={props.open}
      onDismiss={onDismiss}
      headerText="Notifications"
      size="medium"
      onBack={selectedAudit ? () => setSelectedAudit(undefined) : undefined}
    >
      <ErrorBoundary>
        {props.error && (<CustomAlert
          text={props.error}
          type={TaskStatus.Error}
          id="NotificationsPanel"
        />)}
        {props.isLoading && <Spinner size="large" />}
        {selectedAudit ? (
          <AuditPage
            data={selectedAudit as IAuditExtended}
          />
        ) : <div>
          {props.data && props.data.resources.length &&
            <div
              className={sharedVerticalMediumGapFlexStyles().root}
            >
              {props.data.resources.map((audit: IAudit) => (
                <Section
                  key={audit.id}
                  title={(
                    <div className={sharedHorizontalExtraSmallGapFlexStyles().root}>
                      <StatusIcon status={audit.status} />
                      <Link
                        onClick={() => setSelectedAudit(audit)}
                      >{audit.description}
                      </Link>
                    </div>
                  )}
                  description={audit.status}
                >
                  <div
                    className={sharedHorizontalNoGapFlexStyles().root}
                  >
                    <Text
                      className={notificationsPanelStyles().date}
                      size={200}
                      italic
                    >
                      Started {DateTime.fromISO(audit.date).toRelative()}
                    </Text>
                  </div>
                </Section>
              ))}
            </div>
          }
        </div>}
      </ErrorBoundary>
    </CustomDrawer>
  );
}
