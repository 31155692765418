import React, { } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { CustomAlert } from "./CustomAlert";
import { ZodError } from "zod";
import { CustomDrawer, ICustomDrawerProps } from "./CustomDrawer";
import { TaskStatus } from "@symity-hub/types";

interface ICustomEditDrawerProps extends ICustomDrawerProps {
    onSave: () => void;
    validationError: ZodError | undefined;
}

export const CustomEditDrawer: React.FunctionComponent<ICustomEditDrawerProps> = (props) => {

    return (
        <CustomDrawer
            open={props.open}
            onDismiss={props.onDismiss}
            headerText={props.headerText}
            size={props.size}
            onSave={props.onSave}
        >
            <ErrorBoundary>
                {props.validationError && (
                    <CustomAlert
                        type={TaskStatus.Error}
                        text={`${props.validationError.errors.length} issues(s) found:`}
                        id= "validationError"
                    >
                        {props.validationError.errors.map((error, index) => {
                            return (
                                <li key={index}>{error.message}</li>
                            )
                        })}
                    </CustomAlert>
                )}
                {props.children}
            </ErrorBoundary>
        </CustomDrawer>
    );
};
