import { useIsAuthenticated } from "@azure/msal-react";
import React from "react";
import { AppTitle } from "./AppTitle";
import { SignInButton } from "./SignInButton";
import { Image, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { SignedInUserProfile } from "./SignedInUserProfile";
import { Notifications } from "./Notifications";
import { CustomerHelp } from "./CustomerHelp";


interface IAppHeaderProps {
}

export const appHeaderStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#001E43",
        boxShadow: tokens.shadow4,
    },
    title: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexGrow: 1,
        ...shorthands.margin(tokens.spacingVerticalXS, tokens.spacingHorizontalXS),
    },
    menu: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    menuItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "48px",
        textDecorationLine: "none",
        color: tokens.colorNeutralForegroundStaticInverted,
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
        ":hover": {
            cursor: "pointer",
            textDecorationLine: "none",
            color: tokens.colorNeutralForeground1Hover,
            backgroundColor: tokens.colorNeutralBackground3Hover
        }
    }
});

export const AppHeader: React.FunctionComponent<IAppHeaderProps> = () => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <div className={appHeaderStyles().root}>
            <div className={appHeaderStyles().title}>
                <Image src="/images/symity.png" width={50} height={50} />
                <AppTitle />
            </div>
            <div>
                {isAuthenticated
                    ? (<div className={appHeaderStyles().menu}>
                        <CustomerHelp />
                        <Notifications />
                        <SignedInUserProfile />
                    </div>)
                    : (<div>
                        <SignInButton />
                    </div>)
                }
            </div>
        </div >
    )
}
