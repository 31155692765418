import React, { useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { Button, Checkbox, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { sharedVerticalMediumGapFlexStyles } from "../styles/styles";

interface IAcceptDialogProps {
    title: string;
    onConfirm: () => void
}

export const AcceptDialog: React.FunctionComponent<IAcceptDialogProps> = (props) => {

    const [accepted, setAccepted] = useState<boolean>(false);

    return (
        <Dialog
            open={true}
            modalType={"alert"}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>{props.title}</DialogTitle>
                    <DialogContent>
                        <ErrorBoundary>
                            <div className={sharedVerticalMediumGapFlexStyles().root} >
                                {props.children}
                                <Checkbox
                                    label="I accept the above terms and conditions"
                                    checked={accepted}
                                    onChange={() => setAccepted(!accepted)}
                                />
                            </div>
                        </ErrorBoundary>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            appearance="primary"
                            disabled={!accepted}
                            onClick={props.onConfirm}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}