import React, { useContext, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { Section } from "./Section";
import { sharedHorizontalMediumGapWrapFlexStyles } from "../styles/styles";
import { CustomAlert } from "./CustomAlert";
import { TeamsUserContext } from "../contexts/TeamsUserContext";
import { useEffect } from "react";
import { TaskStatus, TeamsPolicyType } from "@symity-hub/types";
import { PropertyAndValue } from "./PropertyAndValue";

interface IUserOverviewProps {
    isLoading: boolean;
}

export const UserOverview: React.FunctionComponent<IUserOverviewProps> = (props) => {

    const teamsUserContext = useContext(TeamsUserContext);

    const [healthCheckIssues, setHealthCheckIssues] = useState<string[]>([]);

    // Health check
    useEffect(() => {
        const issues: string[] = [];
        if (teamsUserContext.teamsDetails && teamsUserContext.phoneNumber?.phoneNumber) {
            // User has a phone number, but enterprise voice is disabled
            if (!teamsUserContext.teamsDetails.enterpriseVoiceEnabled) {
                issues.push("Enterprise voice is not enabled");
            }
            // Global Online Voice Routing Policy set (Direct Routing number only)
            const onlineVoiceRoutingPolicy = teamsUserContext.teamsDetails.policies && teamsUserContext.teamsDetails.policies.find(p => p.policyType === TeamsPolicyType.OnlineVoiceRoutingPolicy);
            if (teamsUserContext.phoneNumber?.numberType === "DirectRouting" && onlineVoiceRoutingPolicy?.currentPolicyName === "Global") {
                issues.push("A DirectRouting number is assigned and the Global Online Voice Routing Policy is set");
            }
            // Not licensed with Phone System license
            const phoneSystemLicense = teamsUserContext.teamsDetails.assignedPlans && teamsUserContext.teamsDetails.assignedPlans.find(p => p.capability === "Microsoft 365 Phone System" || p.capability === "Microsoft 365 Phone Standard Resource Account");
            if (!phoneSystemLicense) {
                issues.push("No Phone System license assigned");
            }
            // LineURI is not the same as the phone number
            const phoneNumberLineUriFormatted = `${teamsUserContext.phoneNumber.phoneNumber.replace("+", "tel:+")}${teamsUserContext.phoneNumber.extensionNumber ? ";ext=" + teamsUserContext.phoneNumber.extensionNumber : ""}`;
            if (phoneNumberLineUriFormatted !== teamsUserContext.teamsDetails.phoneNumber) {
                issues.push("Line URI is not the same as the phone number assigned");
            }
        }
        setHealthCheckIssues(issues);
    }, [teamsUserContext.phoneNumber?.extensionNumber, teamsUserContext.phoneNumber?.numberType, teamsUserContext.phoneNumber?.phoneNumber, teamsUserContext.teamsDetails]);

    return (
        <ErrorBoundary>
            <div className={sharedHorizontalMediumGapWrapFlexStyles().root} >
                <Section
                    title="Health check"
                    description="The following issues were found with the account configuration."
                    isLoading={props.isLoading}
                >
                    <div>
                        {healthCheckIssues.length > 0 ? (
                            <CustomAlert
                                type={TaskStatus.Warning}
                                text="There are potential issues with the voice configuration:"
                                id="voiceConfigurationIssues"
                            >
                                {healthCheckIssues.map((issue) => {
                                    return (
                                        <li key={issue}>{issue}</li>
                                    )
                                })}
                            </CustomAlert>
                        ) : (
                            <CustomAlert
                                type={TaskStatus.Completed}
                                text="Voice configuration is healthy."
                                id="voiceConfigurationHealthy"
                            />
                        )}
                    </div>
                </Section>
                <Section
                    title="Teams licenses"
                    description="The following licenses are assigned to the account."
                    isLoading={props.isLoading}
                >
                    <div>
                        {teamsUserContext && teamsUserContext.teamsDetails && teamsUserContext.teamsDetails.assignedPlans && teamsUserContext.teamsDetails.assignedPlans.length > 0 ? teamsUserContext.teamsDetails.assignedPlans.map((plan) => {
                            return (
                                <PropertyAndValue
                                    key={plan.servicePlanId}
                                    property={plan.capability}
                                    value={plan.capabilityStatus}
                                />
                            )
                        }) : (
                            <CustomAlert
                                text="No licenses assigned."
                                id="noLicenses"
                            />
                        )}
                    </div>
                </Section>
            </div>
        </ErrorBoundary>
    );
};
