import { IUserPermission } from "@symity-hub/types";
import React, { useContext } from "react";
import { SignedInUserContext } from "../contexts/SignedInUserContext";

interface IRbacWrapperProps {
    allowedPermissions: IUserPermission[];
}

export const RbacWrapper: React.FunctionComponent<IRbacWrapperProps> = (props) => {

    const signedInUserContext = useContext(SignedInUserContext);

    return (
        <div>
            {props.allowedPermissions.some((allowedPermission) => signedInUserContext.signedInUser?.permissions?.some((userPermission) => userPermission.id === allowedPermission.id)) ? props.children : null}
        </div>
    );
};
