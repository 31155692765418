import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";
import { Home24Regular } from "@fluentui/react-icons";
import { Breadcrumb, BreadcrumbButton, BreadcrumbDivider, BreadcrumbItem } from "@fluentui/react-components";
import { sharedHorizontalNoGapFlexStyles } from "../styles/styles";

interface IBreadcrumbMenuProps {
}

interface IBreadcrumbItem {
    text?: string;
    key: string;
    url: string;
}
export const BreadcrumbMenu: React.FunctionComponent<IBreadcrumbMenuProps> = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const [items, setItems] = useState<IBreadcrumbItem[]>([]);

    useEffect(() => {
        // Break up location path into breadcrumb items
        const paths = location.pathname.split("/");
        const newItems: IBreadcrumbItem[] = [
            {
                key: "home",
                url: "/",
            }
        ];
        paths.forEach((path, index) => {
            if (path) {
                // Change path name from camel case to human readable with first letter of first word capitalized
                let text = "";
                if (appContext.pageName && appContext.pageName.originalValue.toLowerCase() === path.toLowerCase()) {
                    // Replace last item with current page name if the original value is the same as the last item
                    text = appContext.pageName.newValue;
                } else {
                    // Separate the path to separate words, replace first letter of first word with upper case, make sure the rest of the words are lower case
                    text = path.split(/(?=[A-Z])/).map((word, index) => {
                        if (index === 0) {
                            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                        }
                        return word.toLowerCase();
                    }).join(" ");
                }
                newItems.push(
                    {
                        text,
                        key: path,
                        url: `${paths.slice(0, index + 1).join("/")}`,
                    }
                );
            }
            setItems(newItems);
        });
    }, [appContext.pageName, location.pathname]);

    return (

        <Breadcrumb
            aria-label="Breadcrumb"
            size="large"
        >
            {items.map((item) => {
                return (
                    <div
                        key={item.key}
                        className={sharedHorizontalNoGapFlexStyles().root}
                    >
                        {item.url === "/"
                            ? <Home24Regular />
                            : <BreadcrumbDivider />}
                        <BreadcrumbItem>
                            <BreadcrumbButton
                                key={item.key}
                                onClick={() => navigate(item.url)}
                            >
                                {item.text}
                            </BreadcrumbButton>
                        </BreadcrumbItem>
                    </div>
                )
            })}
        </Breadcrumb>

    );
}

