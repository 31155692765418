// Related to different task statuses
// This was originally a list of statuses returned by Azure Functions, but now it's a more of a list of general statuses
export enum TaskStatus {
    Running = "Running",
    Completed = "Completed",
    ContinuedAsNew = "ContinuedAsNew",
    Failed = "Failed",
    Canceled = "Canceled",
    Terminated = "Terminated",
    Pending = "Pending",
    Warning = "Warning",
    Error = "Error"
}