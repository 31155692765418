import React, { } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { sharedHorizontalMediumGapFlexStyles } from "../styles/styles";
import { Button, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, OverlayDrawer, Subtitle1 } from "@fluentui/react-components";
import { ArrowLeft24Regular, Dismiss24Regular } from "@fluentui/react-icons";

export interface ICustomDrawerProps {
    open: boolean;
    onDismiss: () => void;
    headerText: string;
    size?: "small" | "medium" | "large" | "full" | undefined;
    onSave?: () => void;
    onBack?: () => void;
}


export const CustomDrawer: React.FunctionComponent<ICustomDrawerProps> = (props) => {

    const onOpenChange = (open: boolean) => {
        if (open) {
            props.onDismiss();
        }
    };

    return (
        <OverlayDrawer
            open={props.open}
            position="end"
            size={props.size}
            onOpenChange={(_, data) => onOpenChange(data.open)}
        >
            <DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.onDismiss()}
                        />
                    }
                >
                    <div className={sharedHorizontalMediumGapFlexStyles().root}>
                        {props.onBack ? (
                            <Button
                                aria-label="Back"
                                appearance="subtle"
                                icon={<ArrowLeft24Regular />}
                                onClick={props.onBack}
                            />
                        ) :
                            <div />
                        }
                        <Subtitle1>{props.headerText}</Subtitle1>
                    </div>
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <ErrorBoundary>
                    {props.children}
                </ErrorBoundary>
            </DrawerBody>
            <DrawerFooter>
                <div className={sharedHorizontalMediumGapFlexStyles().root}>
                    {props.onSave &&
                        (<Button
                            onClick={props.onSave}
                            appearance="primary"
                        >
                            Save
                        </Button>)
                    }
                    <Button
                        onClick={props.onDismiss}
                    >
                        Close
                    </Button>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    );
};
