import { z } from "zod";
import { TaskStatus } from "./enums/TaskStatus";

export const operationTaskSchema = z.object({
    name: z.string(),
    status: z.nativeEnum(TaskStatus)
        .optional(),
    errors: z.array(
        z.string()
    )
        .optional(),
    logs: z.array(
        z.string()
    )
        .optional(),
    input: z.any()
        .optional(),
    output: z.any()
        .optional(),
    description: z.string()
        .optional()
})

export type IOperationTask = z.infer<typeof operationTaskSchema>;