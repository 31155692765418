import { IDropdownOption } from "../types/IDropdownOption";

export function getOptionsFromEnum<T extends { [key: number]: string }>(allEnumObject: T, allowedEnumObject?: unknown[]): IDropdownOption[] {
    const result = Object.values(allEnumObject).filter((item, i, ar) => ar.indexOf(item) === i).map((prop) => {
        let disabled = false;
        if (allowedEnumObject && !Object.values(allowedEnumObject).some((value) => value === prop)) {
            disabled = true;
        }
        return { key: prop, text: prop, disabled }
    });

    return result.sort((a, b) => {
        if (a.text.toLowerCase() < b.text.toLowerCase()) { return -1; }
        if (a.text.toLowerCase() > b.text.toLowerCase()) { return 1; }
        return 0;
    });
}