import React, { useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { IAuditChange, IAuditExtended, IOperationStep } from "@symity-hub/types";
import { Section } from "./Section";
import { PropertyAndValue } from "./PropertyAndValue";
import { generateDateFromIso, toTitleCase } from "../modules/string";
import { TabList, Tab, Text, TableCellLayout, TableColumnDefinition, createTableColumn, Divider, makeStyles, shorthands, tokens, Caption1 } from "@fluentui/react-components";
import { ClipboardTaskListLtr20Regular, Database20Regular, ErrorCircle20Regular } from "@fluentui/react-icons";
import { sharedHorizontalExtraSmallGapFlexStyles, sharedVerticalExtraSmallGapFlexStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { BasicDataGrid } from "./BasicDataGrid";
import { StatusIcon } from "./StatusIcon";
import { DateTime } from "luxon";

interface IAuditPageProps {
    data: IAuditExtended;
}

const auditPageStyles = makeStyles({
    row: {
        display: "flex",
        flexDirection: "column",
        gap: (tokens.spacingHorizontalXS),
        ...shorthands.padding(tokens.spacingVerticalXS, 0)
    }
})

export const AuditPage: React.FunctionComponent<IAuditPageProps> = (props) => {

    const [selectedTab, setSelectedTab] = useState<string>(props.data.database ? "database" : "operation");

    const changesColumns: TableColumnDefinition<IAuditChange>[] = [
        createTableColumn<IAuditChange>({
            columnId: "property",
            renderHeaderCell: () => {
                return "Property";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.property}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<IAuditChange>({
            columnId: "oldValue",
            renderHeaderCell: () => {
                return "Previous value";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {Array.isArray(item.oldValue) ? item.oldValue.join(', ') : item.oldValue}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<IAuditChange>({
            columnId: "newValue",
            renderHeaderCell: () => {
                return "New value";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {Array.isArray(item.newValue) ? item.newValue.join(', ') : item.newValue}
                    </TableCellLayout>
                );
            }
        })
    ];

    const stepsColumns: TableColumnDefinition<IOperationStep>[] = [
        createTableColumn<IOperationStep>({
            columnId: "date",
            renderHeaderCell: () => {
                return "Started";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {generateDateFromIso(item.dateStart)}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<IOperationStep>({
            columnId: "name",
            renderHeaderCell: () => {
                return "Step";
            },
            renderCell: (item) => {
                return (
                    <div className={sharedHorizontalExtraSmallGapFlexStyles().root}>
                        <StatusIcon status={item.name} />
                        {item.name}
                    </div>
                );
            }
        })
    ];

    const outputColumns: TableColumnDefinition<{ property: string, value: unknown }>[] = [
        createTableColumn<{ property: string, value: unknown }>({
            columnId: "property",
            renderHeaderCell: () => {
                return "Output";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {toTitleCase(item.property)}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<{ property: string, value: unknown }>({
            columnId: "value",
            renderHeaderCell: () => {
                return "Value";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {`${item.value}`}
                    </TableCellLayout>
                );
            }
        })
    ];

    return (
        <ErrorBoundary>
            {props.data && (
                <div className={sharedVerticalMediumGapFlexStyles().root}>
                    <Section
                        title="Details"
                    >
                        <PropertyAndValue
                            property="Date"
                            value={generateDateFromIso(props.data.date)}
                        />
                        <PropertyAndValue
                            property="Status"
                            value={(
                                <div className={sharedHorizontalExtraSmallGapFlexStyles().root}>
                                    <StatusIcon status={props.data.status} />
                                    {props.data.status}
                                </div>
                            )}
                        />
                        <PropertyAndValue
                            property="Action"
                            value={props.data.type.action}
                        />
                        <PropertyAndValue
                            property="Description"
                            value={props.data.description}
                        />
                        <PropertyAndValue
                            property="Initiated by"
                            value={props.data.initiatedByUserDisplayName}
                        />
                    </Section>
                    <TabList
                        aria-label="Log"
                        selectedValue={selectedTab}
                        onTabSelect={(_, data) => setSelectedTab(data.value as string)}
                    >
                        <Tab value="database" icon={<Database20Regular />}>Database</Tab>
                        <Tab value="operation" icon={<ClipboardTaskListLtr20Regular />}>Operation</Tab>
                        <Tab value="errors" icon={<ErrorCircle20Regular />}>Errors</Tab>
                    </TabList>
                    {selectedTab === "database" && (
                        <div>
                            {props.data.database ? (
                                <div className={sharedVerticalMediumGapFlexStyles().root}>
                                    <Section
                                        title="Item"
                                        description="The following item has been changed."
                                    >
                                        <div>
                                            <PropertyAndValue
                                                property="Item type"
                                                value={props.data.database.container}
                                            />
                                            <PropertyAndValue
                                                property="Item ID"
                                                value={props.data.database.entityId}
                                            />
                                            <PropertyAndValue
                                                property="Item name"
                                                value={props.data.database.entityName}
                                            />
                                        </div>
                                    </Section>
                                    {props.data.database.changes.length > 0 && (
                                        <Section
                                            title="Changes"
                                            description="Changes have been made to the following properties in the database."
                                        >
                                            <BasicDataGrid
                                                columns={changesColumns}
                                                items={props.data.database.changes}
                                                size="small"
                                            />
                                        </Section>
                                    )}
                                </div>
                            )
                                : (
                                    <Section>
                                        <Text size={200}>No changes made.</Text>
                                    </Section>
                                )
                            }
                        </div>
                    )}
                    {selectedTab === "operation" && (
                        <div>
                            {props.data.operation ? (
                                <div className={sharedVerticalMediumGapFlexStyles().root}>
                                    <Section
                                        title="Operation"
                                        description="The following operation has been performed."
                                    >
                                        <div>
                                            <PropertyAndValue
                                                property="Operation"
                                                value={props.data.operation.name}
                                            />
                                            <PropertyAndValue
                                                property="Instance ID"
                                                value={props.data.operation.instanceId}
                                            />
                                            <PropertyAndValue
                                                property="Status"
                                                value={(
                                                    <div className={sharedHorizontalExtraSmallGapFlexStyles().root}>
                                                        <StatusIcon status={props.data.operation.customStatus} />
                                                        {props.data.operation.customStatus}
                                                    </div>
                                                )}
                                            />
                                            <PropertyAndValue
                                                property="Elapsed time"
                                                value={
                                                    // Calculate the elapsed time using the createdTime and lastUpdatedTime, format should be in the format "x hours, x minutes, x seconds"
                                                    DateTime.fromISO(props.data.operation.lastUpdatedTime as unknown as string).diff(DateTime.fromISO(props.data.operation.createdTime as unknown as string), ["hours", "minutes", "seconds"]).toHuman()
                                                }
                                            />
                                        </div>
                                    </Section>
                                    {props.data.operation.output.steps.length > 0 && (
                                        <Section
                                            title="Steps"
                                            description="The following steps have been triggered."
                                        >
                                            <BasicDataGrid
                                                columns={stepsColumns}
                                                items={props.data.operation.output.steps}
                                                size="small"
                                            />
                                        </Section>
                                    )}
                                    {props.data.operation.output.tasks && props.data.operation.output.tasks.length > 0 && (
                                        <Section
                                            title="Tasks"
                                            description="The following tasks have been performed."
                                        >
                                            {
                                                props.data.operation.output.tasks.map((task, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={auditPageStyles().row}
                                                        >
                                                            <Divider />
                                                            <div className={sharedHorizontalExtraSmallGapFlexStyles().root}>
                                                                <StatusIcon status={task.status} />
                                                                <Text>{toTitleCase(task.name)}</Text>
                                                            </div>
                                                            {task.description && (<Text size={200}>{task.description}</Text>)}
                                                            {task.output && (
                                                                <Section>
                                                                    <div className={sharedVerticalExtraSmallGapFlexStyles().root}>
                                                                        <Caption1>Result:</Caption1>
                                                                        {
                                                                            typeof task.output === "object" ?
                                                                                <BasicDataGrid
                                                                                    key={index}
                                                                                    columns={outputColumns}
                                                                                    items={Object.entries(task.output).map(([key, value]) => {
                                                                                        return { property: key, value: value };
                                                                                    })}
                                                                                    size="small"
                                                                                />
                                                                                // If the output is a string, display it as text
                                                                                : <Text>{JSON.stringify(task.output)}</Text>
                                                                        }
                                                                    </div>
                                                                </Section>
                                                            )}
                                                            {task.errors && task.errors.length > 0 && (
                                                                <Section>
                                                                    <div className={sharedVerticalExtraSmallGapFlexStyles().root}>
                                                                        <Caption1>Errors ({task.errors.length}):</Caption1>
                                                                        {task.errors.map((error, index) => {
                                                                            return (
                                                                                <Text key={index}>{error}</Text>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </div>
                                                                </Section>
                                                            )}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Section>
                                    )}
                                    {props.data.operation.output.output && props.data.operation.output.output.length > 0 && (
                                        <Section
                                            title="Result"
                                            description="The following result(s) has been generated."
                                        >
                                            <div
                                                className={sharedVerticalMediumGapFlexStyles().root}
                                            >
                                                {
                                                    props.data.operation.output.output.map((output, index) => {
                                                        // If the output is an object, display it as a table
                                                        return typeof output === "object" ?
                                                            <BasicDataGrid
                                                                key={index}
                                                                columns={outputColumns}
                                                                items={Object.entries(output).map(([key, value]) => {
                                                                    return { property: key, value: value };
                                                                })}
                                                                size="small"
                                                            />
                                                            // If the output is a string, display it as text
                                                            : <Text>{JSON.stringify(output)}</Text>
                                                    })
                                                }
                                            </div>
                                        </Section>
                                    )}
                                </div>
                            )
                                : (
                                    <Section>
                                        <Text size={200}>No operation performed.</Text>
                                    </Section>
                                )
                            }
                        </div>
                    )}
                    {selectedTab === "errors" && (
                        <div>
                            {props.data.error ? (
                                <Section
                                    title="Errors"
                                    description="The following error(s) have occurred."
                                >
                                    <Text>{props.data.error}</Text>
                                </Section>
                            )
                                : (
                                    <Section>
                                        <Text size={200}>No errors occurred.</Text>
                                    </Section>
                                )
                            }
                        </div>
                    )}
                </div>
            )}
        </ErrorBoundary>
    );
};
