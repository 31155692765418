import React from "react";
import { Link, Subtitle2, Text } from '@fluentui/react-components';
import { sharedHorizontalMediumGapFlexStyles, sharedHorizontalMediumGapWrapFlexStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { Call24Regular, DesktopCursor24Regular, Mail24Regular } from "@fluentui/react-icons";
import { Section } from "./Section";

export const Help: React.FunctionComponent = () => {

    return (
        <div className={sharedHorizontalMediumGapWrapFlexStyles().root}>
            <Section
                title="Support contact details"
            >
                <div className={sharedVerticalMediumGapFlexStyles().root}>
                    <Text>Incidents and requests can be raised to Symity Support by:</Text>
                    <div
                        className={sharedHorizontalMediumGapFlexStyles().root}
                    >
                        <Mail24Regular
                            aria-label="Email"
                        />
                        <Text>Sending an email to <Link href="mailto:service.desk@cvdgroup.com">service.desk@cvdgroup.com</Link></Text>
                    </div>
                    <div
                        className={sharedHorizontalMediumGapFlexStyles().root}
                    >
                        <Call24Regular
                            aria-label="Phone"
                        />
                        <Text>Calling us on <Link href="tel://+442038801450">+44 (0)20 3880 1450</Link></Text>
                    </div>
                    <div
                        className={sharedHorizontalMediumGapFlexStyles().root}
                    >
                        <DesktopCursor24Regular
                            aria-label="Portal"
                        />
                        <Text>Accessing our portal at <Link href="https://cvdprod.service-now.com/csm" target="_blank">https://cvdprod.service-now.com/csm</Link></Text>
                    </div>
                    <Text>In the event of a P1 incident please call Symity in addition to logging via email or portal to state the impact to the business.</Text>
                    <Subtitle2>Support hours</Subtitle2>
                    <Text>Symity application support is available between <strong>08:00-18:00 (GMT/BST - UK time) Monday to Friday</strong>, excluding public holidays in England.</Text>
                    <Text>Investigation and resolution of active P1 &lsquo;Critical&lsquo; incidents logged during support hours may continue outside of support hours on a reasonable endeavour basis, subject to resource availability and ability for Symity to restore services within the scope of the service description.</Text>
                </div>
            </Section>
            {/* <Section
                title="Service status"
            >
                <div className={sharedVerticalMediumGapFlexStyles().root}>
                    <Text>Service status for all Symity services is available at <Link href="https://status.symity.com" target="_blank">https://status.symity.com</Link></Text>
                </div>
            </Section> */}
        </div>
    )
}
