import React, { } from "react";
import { Dropdown, Field, Option } from "@fluentui/react-components";
import { IDropdownOption } from "../types/IDropdownOption";
import { OptionOnSelectData, SelectionEvents } from "@fluentui/react-combobox";
import { sharedInputStyles } from "../styles/styles";
import { getSelectedOptionsTotal } from "../modules/dropdown";
import { ZodError } from "zod";

interface ICustomDropdownProps {
    id: string;
    label: string;
    required?: boolean;
    disabled?: boolean;
    multiselect?: boolean;
    options: IDropdownOption[];
    selectedOptions?: string[];
    value?: string | (readonly string[] & string)
    onOptionSelect?: ((event: SelectionEvents, data: OptionOnSelectData) => void) | undefined;
    hint?: string;
    validationError?: ZodError;
    errorMessage?: string;
}

export const CustomDropdown: React.FunctionComponent<ICustomDropdownProps> = (props) => {

    const calculateSelectedOptionsTotal = () => {
        if (props.multiselect && props.selectedOptions) {
            if (props.selectedOptions.length > 1) {
                return getSelectedOptionsTotal(props.selectedOptions)
            } else if (props.selectedOptions && props.selectedOptions.length === 1) {
                // Return option text from only selected option
                const selectedOption = props.selectedOptions[0];
                return props.options.find(option => option.key === selectedOption)?.text;
            } else {
                return "";
            }
        } else {
            return props.value
        }
    }

    return (
        <Field
            label={props.label}
            required={props.required}
            validationMessage={props.validationError?.issues.find(e => e.path.includes(props.id))?.message || props.errorMessage || undefined}
            hint={props.hint}
            className={sharedInputStyles().root}
        >
            <Dropdown
                disabled={props.disabled}
                placeholder="Select an option"
                multiselect={props.multiselect}
                onOptionSelect={props.onOptionSelect}
                selectedOptions={props.selectedOptions}
                value={calculateSelectedOptionsTotal()}
            >
                {props.options.map((option) => (
                    <Option key={option.key} disabled={option.disabled} value={option.key}>{option.text}</Option>
                ))}
            </Dropdown>
        </Field>
    );
};
