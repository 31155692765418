/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { IBaseQueryFilter, IBaseQueryResult, TaskStatus, defaultLimit, defaultPage } from "@symity-hub/types";
import ErrorBoundary from "./ErrorBoundary";
import { useMsal } from "@azure/msal-react";
import useSWR from "swr";
import debounce from "lodash.debounce";
import { fetcher } from "../modules/api";
import { CustomAlert } from "./CustomAlert";
import { IQueryProps } from "../types/IQueryProps";
import { IDropdownFilter } from "../types/IDropdownFilter";
import { DropdownFilters } from "./DropdownFilters";
import { sharedHorizontalMediumGapFlexStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridProps, DataGridRow, Input, Spinner, TableColumnDefinition, TableRowId, Toolbar, ToolbarButton, Tooltip, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { ArrowClockwise24Regular, Dismiss24Regular, Search20Regular } from "@fluentui/react-icons";
import { ICheckboxFilter } from "../types/ICheckboxFilter";
import { CheckboxFilters } from "./CheckboxFilters";

interface ICustomDataGridProps {
    url: string;
    queryProps: IQueryProps;
    setQueryProps: React.Dispatch<React.SetStateAction<IQueryProps>>;
    columns: TableColumnDefinition<any>[];
    dropdownFilters?: IDropdownFilter[];
    setDropdownFilters?: React.Dispatch<React.SetStateAction<IDropdownFilter[]>>;
    checkboxFilters?: ICheckboxFilter[];
    setCheckboxFilters?: React.Dispatch<React.SetStateAction<ICheckboxFilter[]>>;
    selectionMode?: "single" | "multiselect";
    selectedItems?: any[];
    setSelectedItems?: React.Dispatch<React.SetStateAction<any[]>>;
    toolbarButtons?: JSX.Element[];
    additionalFilters?: JSX.Element[];
}

export interface ICustomDataGridRef {
    reloadData: () => void;
}

interface IPageButtonProps {
    name: string;
    value: number;
    disabled?: boolean;
}

const customDataGridStyles = makeStyles({
    spinner: {
        ...shorthands.padding(0, tokens.spacingHorizontalXXS),
    },
    pageButtons: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: (tokens.spacingHorizontalXS),
    },
    pageButton: {
        ...shorthands.margin(0, tokens.spacingHorizontalXS),
        minWidth: "24px",
    },
    searchFilters: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "end",
        gap: (tokens.spacingHorizontalM),
    },
    searchInput: {
        minWidth: "300px",
        display: "flex",
        flexDirection: "row",
        flexGrow: 1
    },
    tableHeader: {
        backgroundColor: tokens.colorNeutralBackground1,
        borderTopLeftRadius: tokens.borderRadiusMedium,
        borderTopRightRadius: tokens.borderRadiusMedium,
    }
});

const CustomDataGrid: React.ForwardRefRenderFunction<ICustomDataGridRef, ICustomDataGridProps> = (props, ref) => {
    const msalContext = useMsal();
    // Destructure props (to avoid callback requiring props as a dependency)
    const {
        url,
        queryProps,
        setQueryProps,
        columns,
        dropdownFilters,
        setDropdownFilters,
        checkboxFilters,
        setCheckboxFilters
    } = props;

    const { data, error, mutate, isValidating } = useSWR<IBaseQueryResult>([url, msalContext, "GET", queryProps.params], fetcher, undefined);
    const [searchValue, setSearchValue] = useState<string>();
    const [sortState, setSortState] = useState<Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]>({
        sortColumn: queryProps.params.sortBy,
        sortDirection: queryProps.params.sortDirection === "DESC" ? "descending" : "ascending",
    });
    // State for selected items on the current page/table
    const [currentPageSelectedItems, setCurrentPageSelectedItems] = useState(
        new Set<TableRowId>()
    );

    const numberOfPages = Math.ceil((data?.total || 0) / (queryProps.params.limit || defaultLimit));
    const currentPage = queryProps.params.page || defaultPage;
    const buttonItems: IPageButtonProps[] = [];

    /**
     * When a search value is entered, update the query params (on a debounced callback)
     */
    const onSearchFilterChange = useCallback(debounce((value?: string) => {
        if (queryProps.searchField) {
            // Get other filters
            const filters = queryProps.params.filters?.filter((filter: IBaseQueryFilter) => (filter.name !== queryProps.searchField || filter.operator !== "contains"));
            if (value) {
                // Add search filter
                filters?.push({ name: queryProps.searchField, operator: "contains", value: value })
            }
            setQueryProps({
                ...queryProps,
                params: {
                    ...queryProps.params,
                    filters,
                    page: 1
                }
            });
        }
    }, 1000), [queryProps, setQueryProps]);

    /**
     * Handle sort order (change sort order and sort field)
     */
    const onSortChange: DataGridProps["onSortChange"] = (e, nextSortState) => {
        if (nextSortState.sortColumn) {
            setSortState(nextSortState);
            // Update query params
            if (nextSortState.sortColumn) {
                setQueryProps({
                    ...queryProps,
                    params: {
                        ...queryProps.params,
                        sortBy: nextSortState.sortColumn.toString(),
                        sortDirection: nextSortState.sortDirection === "descending" ? "DESC" : "ASC",
                        page: 1
                    }
                });
            }
        }
    };

    /**
     * Handle selection of rows
     */
    const onSelectionChange: DataGridProps["onSelectionChange"] = (_, selection) => {
        if (props.setSelectedItems) {
            const newSelectedItems = props.selectedItems ? [...props.selectedItems] : [];
            const pageSelectedItems: any[] = [];
            // Go through each selected item and add to selectedItems
            selection.selectedItems.forEach((item) => {
                // Get the item from the tableRowId
                const selectedItem = data?.resources[item];
                const pageSelectedItem = data?.resources[item];
                pageSelectedItems.push(pageSelectedItem);
                // Add selected item if not already in array otherwise remove it from array
                if (selectedItem && !newSelectedItems.includes(selectedItem)) {
                    newSelectedItems.push(selectedItem);
                }
            });
            // Find items that are in currentPageSelectedItems but not in selection.selectedItems and remove them from newSelectedItems (as they have been deselected)
            currentPageSelectedItems.forEach((item) => {
                if (!selection.selectedItems.has(item)) {
                    const selectedItem = data?.resources[item];
                    const index = newSelectedItems.indexOf(selectedItem);
                    if (index > -1) {
                        newSelectedItems.splice(index, 1);
                    }
                }
            });
            // Set state
            props.setSelectedItems(newSelectedItems);
            setCurrentPageSelectedItems(selection.selectedItems);
        }
    };

    // Loop through each page number and render items
    if (numberOfPages > 1) {
        for (let i = 1; i <= numberOfPages; i++) {
            const pageNumber = i;
            const pageNumberString = (pageNumber).toString()
            // If more than 10 pages, only show certain links (first, current, near current and last)
            if (numberOfPages > 10) {
                const allowedPageNumbers: number[] = [
                    1,
                    currentPage - 2,
                    currentPage - 1,
                    currentPage,
                    currentPage + 1,
                    currentPage + 2,
                    numberOfPages
                ]
                if (allowedPageNumbers.includes(pageNumber)) {
                    buttonItems.push({
                        name: pageNumberString,
                        value: i
                    })
                } else if (pageNumber === 2 || pageNumber === numberOfPages - 2) {
                    // Add a ... link between first/last and current pages
                    buttonItems.push({
                        name: "...",
                        value: 0,
                        disabled: true
                    })
                }
            } else {
                // Else show all links
                buttonItems.push({
                    name: pageNumberString,
                    value: i
                })
            }
        }
        // Add previous and next links
        if (currentPage !== 1) {
            buttonItems.unshift({
                name: "< Previous",
                value: currentPage - 1
            });
        }
        if (currentPage !== numberOfPages) {
            buttonItems.push({
                name: "Next >",
                value: currentPage + 1
            })
        }
    }

    // If a reload is requested, refetch data
    const reloadData = () => {
        setTimeout(() => {
            mutate();
        }, 1000);
    };

    useImperativeHandle(ref, () => ({
        reloadData
    }));

    // If page changes, recalculate the selected rows
    useEffect(() => {
        if (data) {
            const newCurrentPageSelectedItems = new Set<TableRowId>();
            props.selectedItems?.forEach((item) => {
                const index = data.resources.findIndex((resource) => resource.id === item.id);
                if (index >= 0) {
                    newCurrentPageSelectedItems.add(index);
                }
            });
            setCurrentPageSelectedItems(newCurrentPageSelectedItems);
        }
    }, [data, props.selectedItems]);

    if (error) return (<CustomAlert text={error} type={TaskStatus.Error} id="customDataGrid" />);

    return (
        <div className={sharedVerticalMediumGapFlexStyles().root}>
            <ErrorBoundary>
                <div>
                    <Toolbar
                        size="small"
                    >
                        {props.selectedItems && props.selectedItems.length > 0 &&
                            <ToolbarButton
                                appearance="subtle"
                                onClick={() => { if (props.setSelectedItems) props.setSelectedItems([]) }}
                                icon={<Dismiss24Regular />}
                            >
                                Clear {props.selectedItems.length} selected
                            </ToolbarButton>
                        }
                        {props.toolbarButtons}
                    </Toolbar>
                </div>
                <div className={customDataGridStyles().searchFilters}>
                    <div className={sharedHorizontalMediumGapFlexStyles().root}>
                        {isValidating ?
                            <Spinner
                                size="tiny"
                                className={customDataGridStyles().spinner}
                            /> :
                            <Tooltip
                                content={"Refresh"}
                                relationship={"description"}
                                positioning={"above"}
                                withArrow
                            >
                                <Button
                                    aria-label="Refresh"
                                    size="small"
                                    icon={<ArrowClockwise24Regular />}
                                    appearance="subtle"
                                    onClick={() => mutate()}
                                />
                            </Tooltip>
                        }
                        <div className={customDataGridStyles().searchInput}>
                            <Input
                                className={customDataGridStyles().searchInput}
                                type="search"
                                placeholder={queryProps.searchPlaceholderText || "Search"}
                                value={searchValue || ""}
                                onChange={(e, v) => {
                                    setSearchValue(v.value);
                                    onSearchFilterChange(v.value);
                                }}
                                contentBefore={<Search20Regular />}
                            />
                        </div>
                    </div>
                    {dropdownFilters && setDropdownFilters && (
                        <DropdownFilters
                            queryProps={queryProps}
                            setQueryProps={setQueryProps}
                            dropdownFilters={dropdownFilters}
                            setDropdownFilters={setDropdownFilters}
                        />
                    )}
                    {checkboxFilters && setCheckboxFilters && (
                        <CheckboxFilters
                            queryProps={queryProps}
                            setQueryProps={setQueryProps}
                            checkboxFilters={checkboxFilters}
                            setCheckboxFilters={setCheckboxFilters}
                        />
                    )}
                    {props.additionalFilters}
                </div>
                <div className={sharedVerticalMediumGapFlexStyles().root}>
                    <DataGrid
                        items={data?.resources || []}
                        columns={columns}
                        sortable
                        sortState={sortState}
                        onSortChange={onSortChange}
                        selectedItems={currentPageSelectedItems}
                        onSelectionChange={onSelectionChange}
                        selectionMode={props.selectionMode}
                    >
                        <DataGridHeader
                            className={customDataGridStyles().tableHeader}
                        >
                            <DataGridRow
                                selectionCell={props.selectionMode === "multiselect" ? { "aria-label": "Select all items on page" } : undefined}
                            >
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                )}
                            </DataGridRow>
                        </DataGridHeader>
                        <DataGridBody<any>>
                            {({ item, rowId }) => (
                                <DataGridRow<any>
                                    id={item.id}
                                    key={rowId}
                                >
                                    {({ renderCell }) => (
                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid>
                    <div className={customDataGridStyles().pageButtons}>
                        {buttonItems.map((item, index) => {
                            return (
                                <Button
                                    key={index}
                                    className={customDataGridStyles().pageButton}
                                    aria-label={`Page ${item.name}`}
                                    onClick={() => setQueryProps({
                                        ...queryProps,
                                        params: {
                                            ...queryProps.params,
                                            page: item.value as number
                                        }
                                    })}
                                    value={item.value}
                                    appearance={item.name === currentPage.toString() ? "primary" : "secondary"}
                                    disabled={item.disabled}
                                >
                                    {item.name}
                                </Button>
                            );
                        })
                        }
                    </div>
                </div>
                {!data && isValidating && (
                    <Spinner
                        size="large"
                        className={customDataGridStyles().spinner}
                    />
                )}
                {data && data.resources.length === 0 && <CustomAlert text="No results found." id="noResultsFound" />}
            </ErrorBoundary>
        </div>
    );
};

export default forwardRef(CustomDataGrid);