import React, { useContext, useState } from "react";
import { Dialog, DialogTrigger, DialogSurface, DialogTitle, DialogBody, DialogContent, DialogActions, Button, Field, makeStyles, Text } from '@fluentui/react-components';
import { CustomDropdown } from "./CustomDropdown";
import { getOptionsFromEnum } from "../modules/enum";
import { FeedbackType, IFeedback, feedbackSchema } from "@symity-hub/types";
import { Star28Filled, Star28Regular } from "@fluentui/react-icons";
import { sharedHorizontalMediumGapFlexStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { AppContext } from "../contexts/AppContext";
import { useMsal } from "@azure/msal-react";
import { fetcher } from "../modules/api";
import { CustomTextarea } from "./CustomTextarea";

interface IFeedbackDialogProps {
    title: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const feedbackDialogStyles = makeStyles({
    star: {
        ":hover": {
            cursor: "pointer"
        }
    }
})

export const FeedbackDialog: React.FunctionComponent<IFeedbackDialogProps> = (props) => {

    const appContext = useContext(AppContext);
    const msalContext = useMsal();

    const [feedback, setFeedback] = useState<IFeedback>({
        feedbackType: FeedbackType.General,
        rating: 3,
        comment: "",
        version: process.env.REACT_APP_CLIENT_VERSION || "unknown"
    });
    const [hover, setHover] = useState<number>(0);

    const onFeedbackSubmit = async () => {
        await feedbackSchema.parseAsync(feedback)
            .then(async () => {
                await fetcher(["/api/me/feedback", msalContext, "POST", undefined, feedback])
                    .then(() => {
                        appContext.notify("Feedback submitted successfully. Thank you!", "success");
                    })
            })
            .catch((error) => {
                console.error(error);
                appContext.notify("Unable to submit feedback, please try again later.", "error");
            });
    }

    return (
        <Dialog
            open={props.open}
            onOpenChange={(_, data) => props.setOpen(data.open)}
            modalType={"modal"}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Submit feedback</DialogTitle>
                    <DialogContent>
                        <div className={sharedVerticalMediumGapFlexStyles().root}>
                            <Text>We are always keen to hear from our users on how we can improve Symity Hub. Please fill in the sections below to let us know your thoughts and suggestions.</Text>
                            <CustomDropdown
                                id="feedbackType"
                                label="What is your feedback about?"
                                options={getOptionsFromEnum(FeedbackType)}
                                selectedOptions={[feedback.feedbackType]}
                                onOptionSelect={(event, data) => setFeedback({ ...feedback, feedbackType: data.optionValue as FeedbackType })}
                                value={feedback.feedbackType}
                            />
                            <Field>
                                <CustomTextarea
                                    id="comment"
                                    label="Please provide more details"
                                    value={feedback.comment}
                                    onChange={(event, value) => setFeedback({ ...feedback, comment: value.value })}
                                />
                            </Field>
                            <div>
                                <Text>How satisfied are you with Symity Hub?</Text>
                            </div>
                            <div className={sharedHorizontalMediumGapFlexStyles().root}>
                                {[...Array(5)].map((_, index) => {
                                    const currentRating = index + 1;
                                    return (
                                        <div key={currentRating}>
                                            {
                                                currentRating <= feedback.rating
                                                    ?
                                                    (
                                                        <Star28Filled
                                                            className={feedbackDialogStyles().star}
                                                            color={currentRating <= (hover || feedback.rating) ? "#ffc107" : "#e4e5e9"}
                                                            onMouseEnter={() => setHover(currentRating)}
                                                            onMouseLeave={() => setHover(0)}
                                                            onClick={() => setFeedback({ ...feedback, rating: currentRating })}
                                                        />
                                                    )
                                                    :
                                                    (
                                                        <Star28Regular
                                                            className={feedbackDialogStyles().star}
                                                            color={currentRating <= (hover || feedback.rating) ? "#ffc107" : "#e4e5e9"}
                                                            onMouseEnter={() => setHover(currentRating)}
                                                            onMouseLeave={() => setHover(0)}
                                                            onClick={() => setFeedback({ ...feedback, rating: currentRating })}
                                                        />
                                                    )
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger>
                            <Button
                                appearance="primary"
                                onClick={
                                    () => {
                                        onFeedbackSubmit();
                                        props.setOpen(false);
                                    }
                                }
                            >
                                Submit
                            </Button>
                        </DialogTrigger>
                        <Button
                            appearance="secondary"
                            onClick={
                                () => {
                                    props.setOpen(false);
                                }
                            }
                        >
                            Close
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};