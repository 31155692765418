import React from "react";
import { IAuditExtended } from "@symity-hub/types";
import { CustomDrawer } from "./CustomDrawer";
import { AuditPage } from "./AuditPage";

interface IAuditPanelProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onDismiss: () => void;
    data: IAuditExtended;
}

export const AuditPanel: React.FunctionComponent<IAuditPanelProps> = (props) => {

    const onDismiss = () => {
        props.setOpen(false);
    }

    return (
        <CustomDrawer
            open={props.open}
            onDismiss={onDismiss}
            headerText="View log"
            size="medium"
        >
            <AuditPage
                data={props.data}
            />
        </CustomDrawer>
    );
};
