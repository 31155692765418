import { IUserPermission } from "../types/IUserPermission";

// PhoneNumber/PhoneNumberRange
export const readPhoneNumberPermission: IUserPermission = {
    id: "20430602-906e-4087-aba2-1ffdf41e3911",
    name: "PhoneNumber.Read",
    displayName: "Read phone numbers",
    description: "Read phone numbers and phone number ranges"
};

export const writePhoneNumberPermission: IUserPermission = {
    id: "03a7b43e-df28-42de-8bcf-ad6622c1ecb7",
    name: "PhoneNumber.Write",
    displayName: "Create/update phone numbers",
    description: "Create or edit phone numbers or phone number ranges"
};

export const deletePhoneNumberPermission: IUserPermission = {
    id: "57642327-da19-4be1-9efe-33ecd24dd4fa",
    name: "PhoneNumber.Delete",
    displayName: "Delete phone numbers",
    description: "Delete phone numbers or phone number ranges"
};

export const assignPhoneNumberPermission: IUserPermission = {
    id: "1e466d01-5822-4f6e-a22a-011de035692c",
    name: "PhoneNumber.Assign",
    displayName: "Assign phone numbers",
    description: "Assign phone numbers to user and resource accounts"
};

// TeamsUser
export const readUserPermission: IUserPermission = {
    id: "2be0a3f0-1d61-40e3-b724-840358c43755",
    name: "TeamsUser.Read",
    displayName: "Read users",
    description: "Read user and resource accounts"
};

export const readTeamsPolicyPermission: IUserPermission = {
    id: "c8482dac-7688-4737-84bd-b34a838d6f14",
    name: "TeamsPolicy.Read",
    displayName: "Read Teams policies",
    description: "Read Teams policies"
};

export const assignTeamsPolicyPermission: IUserPermission = {
    id: "2a28f66a-161d-455c-a766-b89f75d1b458",
    name: "TeamsPolicy.Assign",
    displayName: "Assign Teams policies",
    description: "Assign Teams policies to user and resource accounts"
};

// User
export const readAdminUserPermission: IUserPermission = {
    id: "303b134d-4c09-42c0-bf3b-6c3ba8a2a503",
    name: "User.Read",
    displayName: "Read users",
    description: "Read admin users"
};

export const writeAdminUserPermission: IUserPermission = {
    id: "fecc0b74-b891-417c-abad-48bd36b31ad4",
    name: "User.Write",
    displayName: "Create/update admin users",
    description: "Create or edit admin users"
};

export const readAuditPermission: IUserPermission = {
    id: "5319fb22-4789-48e6-86ed-3a39a57fecc4",
    name: "Audit.Read",
    displayName: "Read audit logs",
    description: "Read audit logs"
};

export const syncPermission: IUserPermission = {
    id: "ea42824f-b262-4a73-8d45-7f200f9467e3",
    name: "Sync",
    displayName: "Sync",
    description: "Perform a sync of all resources"
};

export const allUserPermissions: IUserPermission[] = [
    readPhoneNumberPermission,
    writePhoneNumberPermission,
    deletePhoneNumberPermission,
    assignPhoneNumberPermission,
    readUserPermission,
    readTeamsPolicyPermission,
    assignTeamsPolicyPermission,
    readAdminUserPermission,
    writeAdminUserPermission,
    readAuditPermission,
    syncPermission
];