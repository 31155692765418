import { z } from "zod";
import { teamsPowerShellTaskSchema } from "./ITeamsPowerShellTask";

export const teamsPowerShellRequestSchema = z.object({
    instanceId: z.string()
        .uuid(),
    tasks: z.array(teamsPowerShellTaskSchema)
})
    .strict();

export type ITeamsPowerShellRequest = z.infer<typeof teamsPowerShellRequestSchema>;

export interface ITeamsPowerShellRequestWithTokens extends ITeamsPowerShellRequest {
    graphAccessToken: string;
    teamsAdminAccessToken: string;
}