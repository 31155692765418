import React, { useState, useEffect } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { IUser, IUserPermission, TaskStatus, allUserPermissions, allUserRoles, allUserScopes, userSchema } from "@symity-hub/types";
import { CustomEditDrawer } from "./CustomEditDrawer";
import { CustomInput } from "./CustomInput";
import { EntityPage } from "./EntityPage";
import { CustomDropdown } from "./CustomDropdown";
import { ZodError } from "zod";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from "@fluentui/react-components";
import { sharedHorizontalSmallGapFlexStyles } from "../styles/styles";
import { StatusIcon } from "./StatusIcon";

interface IEditAdminUserProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    data: IUser;
    setData: React.Dispatch<React.SetStateAction<IUser | undefined>>;
}

interface IUserPermissionAllowed extends IUserPermission {
    allowed: boolean;
}

export const EditAdminUserPanel: React.FunctionComponent<IEditAdminUserProps> = (props) => {

    const [data, setData] = useState<IUser | undefined>(props.data);
    const [saveData, setSaveData] = useState<IUser>();
    const [validationError, setValidationError] = useState<ZodError>();
    const [effectivePermissions, setEffectivePermissions] = useState<IUserPermissionAllowed[]>([]);

    const onSave = async () => {
        if (data) {
            await userSchema.parseAsync(data)
                .then(() => {
                    setValidationError(undefined);
                    setSaveData(data);
                    props.setData(data);
                    props.setOpen(false);
                })
                .catch((error: ZodError) => {
                    setValidationError(error);
                    setSaveData(undefined);
                });
        }
    }

    const onDismiss = () => {
        props.setOpen(false);
        setSaveData(undefined);
    }

    // Any time selected roles change, update effective permissions
    useEffect(() => {
        if (data) {
            const permissions: IUserPermissionAllowed[] = [];
            data.roles?.forEach((roleId) => {
                const role = allUserRoles.find((r) => r.id === roleId);
                allUserPermissions.forEach((permission) => {
                    if (role) {
                        const allowed = role.permissions.includes(permission.id);
                        const existingPermission = permissions.find((p) => p.id === permission.id);
                        if (existingPermission) {
                            // If the permission is already marked as allowed, keep it as allowed
                            existingPermission.allowed = existingPermission.allowed || allowed;
                        } else {
                            permissions.push({
                                ...permission,
                                allowed
                            });
                        }
                    }
                });
            });
            // Sort permissions by allowed status, then by display name
            permissions.sort((a, b) => {
                return a.displayName.localeCompare(b.displayName);
            });
            setEffectivePermissions(permissions);
        }
    }, [data?.roles]);

    return (
        <CustomEditDrawer
            open={props.open}
            onDismiss={onDismiss}
            onSave={onSave}
            headerText={props.data ? "Edit user" : "Add user"}
            validationError={validationError}
        >
            <ErrorBoundary>
                <div>
                    {data && (
                        <EntityPage
                            url={`/api/admin/users/${data.id}`}
                            setData={setData}
                            saveData={saveData}
                        >
                            <div>
                                <CustomInput
                                    id="name"
                                    label="Name"
                                    required={!userSchema.shape.id.isOptional()}
                                    value={data.displayName}
                                    validationError={validationError}
                                    disabled
                                />
                                <CustomDropdown
                                    id="roles"
                                    label="Roles"
                                    required={!userSchema.shape.roles.isOptional()}
                                    options={allUserRoles.map((role) => ({ key: role.id, text: role.displayName }))}
                                    selectedOptions={data.roles || []}
                                    multiselect
                                    onOptionSelect={(_, option) => { if (option) setData({ ...data, roles: option.selectedOptions }) }}
                                    validationError={validationError}
                                />
                                <div>
                                    {
                                        effectivePermissions.length > 0 &&
                                        (
                                            <Accordion collapsible>
                                                <AccordionItem value="effectivePermissions">
                                                    <AccordionHeader size="small">Effective permissions</AccordionHeader>
                                                    <AccordionPanel>
                                                        {
                                                            effectivePermissions.map((permission) => {
                                                                return (
                                                                    <div key={permission.id} className={sharedHorizontalSmallGapFlexStyles().root}>
                                                                        <StatusIcon status={permission.allowed ? TaskStatus.Completed : TaskStatus.Failed} />
                                                                        {permission.displayName}
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </Accordion>
                                        )
                                    }
                                </div>
                                <CustomDropdown
                                    id="scopes"
                                    label="Scopes"
                                    required={!userSchema.shape.scopes.isOptional()}
                                    options={allUserScopes.map((scope) => ({ key: scope.id, text: scope.displayName }))}
                                    selectedOptions={data.scopes || []}
                                    multiselect
                                    onOptionSelect={(_, option) => { if (option) setData({ ...data, scopes: option.selectedOptions }) }}
                                    validationError={validationError}
                                />
                            </div>
                        </EntityPage>
                    )}
                </div>
            </ErrorBoundary>
        </CustomEditDrawer>
    );
};
