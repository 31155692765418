import { User } from "@microsoft/microsoft-graph-types";
import { IPhoneNumber, IUserExtended } from "@symity-hub/types";
import { createContext } from "react";

interface TeamsUserContextProps {
    user?: User;
    teamsDetails?: IUserExtended;
    setTeamsDetails: React.Dispatch<React.SetStateAction<IUserExtended | undefined>>;
    phoneNumber?: IPhoneNumber;
    setPhoneNumber: React.Dispatch<React.SetStateAction<IPhoneNumber | undefined>>;
}

export const TeamsUserContext = createContext({} as TeamsUserContextProps);
