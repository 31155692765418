import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { apiScopes } from "../modules/auth";
import { buttonStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { InteractionStatus } from "@azure/msal-browser";
import { Button } from "@fluentui/react-components";

export const SignInButton: React.FunctionComponent = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const handleLogin = async () => {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            await instance.loginPopup({
                scopes: apiScopes,
                prompt: "select_account"
            })
                .then((response) => {
                    console.log("msalResponse: ", response);
                })
                .catch(e => {
                    console.error(e);
                })
                .finally(() => {
                    console.info("MSAL loginPopup complete");
                });
        }
    }

    return (
        <div className={sharedVerticalMediumGapFlexStyles().root}>
            <Button
                appearance="primary"
                onClick={handleLogin}
                className={buttonStyles().root}
            >
                Sign in
            </Button>
        </div>
    )

}
