import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AppInsightsContext, ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppHeader } from "./components/AppHeader";
import { MainView } from "./views/MainView";
import { FluentProvider, Theme, Toast, ToastIntent, ToastTitle, Toaster, makeStyles, useId, useToastController } from "@fluentui/react-components";
import { BrowserRouter } from "react-router-dom";
import { symityDarkTheme, symityLightTheme } from "./styles/theme";
import { ThemeContext } from "./contexts/ThemeContext";
import { SignedInUserContext } from "./contexts/SignedInUserContext";
import { IUserWithEffectivePermissions, ISubscriptionEvent } from "@symity-hub/types";
import { fetcher, getTokenForScopes } from "./modules/api";
import { ICustomAlertProps } from "./components/CustomAlert";
import { AppContext } from "./contexts/AppContext";
import { useMsal } from "@azure/msal-react";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import { apiScopes } from "./modules/auth";

const reactPlugin = new ReactPlugin();

const appStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    flexGrow: 1
  }
});

if (process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin]
    }
  });
  appInsights.loadAppInsights();
}


export const App: React.FunctionComponent = () => {

  const msalContext = useMsal();

  const localStorageTheme = localStorage.getItem("theme");
  const [theme, setTheme] = useState<Theme>(localStorageTheme === "dark" ? symityDarkTheme : symityLightTheme);
  const [signedInUser, setSignedInUser] = useState<IUserWithEffectivePermissions>();
  const [pageName, setPageName] = useState<{ originalValue: string, newValue: string }>();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [alerts, setAlerts] = useState<ICustomAlertProps[]>([]);
  const [webPubSubClient, setWebPubSubClient] = useState<WebPubSubClient>();
  const eventCallbacks = useRef<Map<string, (response: ISubscriptionEvent) => void>>(new Map());

  useEffect(() => {
    const initializeWebPubSub = async () => {
      if (msalContext.accounts.length > 0) {
        await fetcher([`/api/subscriptions/negotiate`, msalContext, "GET"])
          .then(async (response) => {
            if (response.url) {
              const client = new WebPubSubClient(response.url);
              client.on("group-message", (e) => {
                console.log(`WebPubSubClient: Received message from group:`, e.message);
              })

              client.on("connected", (e) => {
                console.log(`WebPubSubClient: Connection ${e.connectionId} is connected`);
              });

              client.on("disconnected", (e) => {
                console.log(`WebPubSubClient: Connection ${e.connectionId} is disconnected`);
                setWebPubSubClient(undefined);
                eventCallbacks.current.clear();
              });

              client.on("server-message", (e) => {
                console.log(`WebPubSubClient: Received message from server:`, e.message);
                const data = e.message.data as ISubscriptionEvent;
                // If message type is "Error", send a toast notification

                if (data.type === "Error") {
                  console.warn("WebPubSubClient: Received error message", data.data);
                  notify(data.data, "error");
                  return;
                }

                // Find callback and execute
                if (!data.id) {
                  console.warn("WebPubSubClient: No ID in message");
                  return;
                }
                const callback = eventCallbacks.current.get(data.id);
                if (callback) {
                  callback(e.message.data as ISubscriptionEvent);
                }
              });

              await client.start()
                .then(() => {
                  console.log("WebPubSubClient: Connected to WebPubSub");
                })
                .catch((error) => {
                  console.error("WebPubSubClient: Failed to connect to Web PubSub", error);
                });
              setWebPubSubClient(client);
            } else {
              throw new Error("No URL returned from Web PubSub API");
            }
          })
          .catch((error) => {
            console.error("WebPubSubClient: Failed to negotiate with Web PubSub", error);
          });
      }
    }
    initializeWebPubSub();
  }, [msalContext]);

  const sendEventWithCallback = useCallback(async (event: ISubscriptionEvent, callback: (response: ISubscriptionEvent) => void) => {
    const { id } = event;
    eventCallbacks.current.set(id, callback);
    // Send via WebPubSub
    if (!webPubSubClient) {
      console.error("WebPubSubClient: Not initialized, cannot send message");
      return;
    }
    // Get access token and send event
    const accessToken = await getTokenForScopes(msalContext, { scopes: apiScopes });
    if (!accessToken) {
      console.error("WebPubSubClient: No access token");
      return;
    }
    event.token = accessToken;
    console.info("Sending message with ID and callback", event, eventCallbacks.current);
    await webPubSubClient.sendEvent(event.type, event, "json")
      .then(() => {
        console.log("WebPubSubClient: Sent event", event);
      })
      .catch((error) => {
        console.error("WebPubSubClient: Failed to send event", error);
      });

    console.log("Sent message with ID and callback", event, eventCallbacks.current);
  }, [webPubSubClient]);

  const notify = (message: string, intent: ToastIntent) =>
    dispatchToast(
      <Toast>
        <ToastTitle>{message}</ToastTitle>
      </Toast>,
      { position: "top-end", intent, timeout: 5000 }
    );

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <FluentProvider theme={theme}>
        <Toaster id={toasterId} />
        <AppInsightsContext.Provider value={reactPlugin}>
          <SignedInUserContext.Provider value={{
            signedInUser,
            setSignedInUser
          }}>
            <AppContext.Provider value={{
              pageName,
              setPageName,
              notify,
              alerts,
              setAlerts,
              sendMessage: sendEventWithCallback
            }}>
              <BrowserRouter>
                <div className={appStyles().root}>
                  <AppHeader />
                  <MainView />
                </div>
              </BrowserRouter>
            </AppContext.Provider>
          </SignedInUserContext.Provider>
        </AppInsightsContext.Provider>
      </FluentProvider>
    </ThemeContext.Provider>
  );
}

export default withAITracking(reactPlugin, App);