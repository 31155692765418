import React from "react";
import { tokens } from '@fluentui/react-components';
import ReactApexChart from 'react-apexcharts';
import { IReport } from "@symity-hub/types";
import { ApexOptions } from "apexcharts";
import { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import { symityDarkTheme } from "../styles/theme";

interface IBarChartProps {
    report: IReport;
}

export const BarChart: React.FunctionComponent<IBarChartProps> = (props) => {

    const themeContext = useContext(ThemeContext);

    const options: ApexOptions = {
        chart: {
            type: "bar",
            stacked: true,
            stackType: "100%",
            toolbar: {
                show: false
            },
            background: "none",
            fontFamily: tokens.fontFamilyBase
        },
        plotOptions: {
            bar: {
                horizontal: props.report.layout === "horizontal" ? true : false
            }
        },
        theme: {
            mode: themeContext.theme === symityDarkTheme ? "dark" : "light"
        },
        xaxis: {
            categories: props.report.labels
        },
        colors: props.report.colors
    }

    return (
        <ReactApexChart
            options={options}
            series={props.report.data}
            type="bar"
        />
    )
}