import React, { useContext, useEffect, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { IOperationResult, IOperationTask, TaskStatus, SubscriptionEventType } from "@symity-hub/types";
import { CustomAlert } from "./CustomAlert";
import { sharedHorizontalExtraSmallGapFlexStyles, sharedHorizontalMediumGapFlexStyles } from "../styles/styles";
import { Button, Caption1, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Spinner, Text, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { StatusIcon } from "./StatusIcon";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../contexts/AppContext";

interface IOperationDialogProps {
    open: boolean;
    onDismiss: () => void
    operationId?: string;
}

const operationDialogStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        gap: (tokens.spacingHorizontalS, tokens.spacingVerticalS),
        ...shorthands.padding(0, 0, tokens.spacingVerticalM, 0)
    },
    item: {
        display: "flex",
        flexDirection: "column",
        gap: (tokens.spacingHorizontalXS),
        ...shorthands.padding(tokens.spacingVerticalXS, 0)
    },
});


export const OperationDialog: React.FunctionComponent<IOperationDialogProps> = (props) => {

    const appContext = useContext(AppContext);

    const [operationComplete, setOperationComplete] = useState<boolean>(false);
    const [data, setData] = useState<IOperationResult>();
    const [error, setError] = useState<string>();

    const callWebSocket = async () => {
        try {
            if (!appContext.sendMessage) {
                console.log("sendMessage not available in AppContext");
                return;
            }

            if (props.operationId) {
                await appContext.sendMessage({
                    id: uuidv4(),
                    type: SubscriptionEventType.OperationStatus,
                    entityId: props.operationId,
                    updateFrequency: 10000
                }, (response) => {
                    if (response && response.data && response.data.operation) {
                        setData(response.data.operation);
                        if (response.data.operation.runtimeStatus !== "Running" && response.data.operation.runtimeStatus !== "Pending") {
                            setOperationComplete(true);
                        } else {
                            setOperationComplete(false);
                        }
                    }
                });
            }
        } catch (error) {
            console.error('WebSocket Error:', error);
            setError(error as string);
        }
    };

    useEffect(() => {
        callWebSocket();
    }, [props.operationId, appContext.sendMessage]);

    return (
        <Dialog
            open={props.open}
            onOpenChange={() => props.onDismiss()}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        <div className={sharedHorizontalMediumGapFlexStyles().root}>
                            {// With errors?
                                data?.output?.tasks && data.output.tasks.some((task: IOperationTask) => task.status === TaskStatus.Failed) && (
                                    <StatusIcon status={TaskStatus.Failed} />
                                )}
                            {// With success?
                                data?.output?.tasks && data.output.tasks.every((task: IOperationTask) => task.status === TaskStatus.Completed) && (
                                    <StatusIcon status={TaskStatus.Completed} />
                                )}
                            {data?.runtimeStatus || "Working on it"}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <ErrorBoundary>
                            <div className={operationDialogStyles().root}>
                                <Caption1>Please note: changes may take some time to take effect.</Caption1>
                                {error && <CustomAlert
                                    text={error}
                                    type={TaskStatus.Error}
                                    id="operationDialog"
                                />}
                                {!operationComplete && <Spinner size="large" />}
                                <div>
                                    {data?.output?.tasks && data.output.tasks.length > 0 && data.output.tasks.map((task: IOperationTask, index: number) => {
                                        return (
                                            <div key={index}>
                                                <Divider />
                                                <div className={operationDialogStyles().item}>
                                                    {task.status === TaskStatus.Failed ? (
                                                        <div className={sharedHorizontalExtraSmallGapFlexStyles().root}>
                                                            <StatusIcon status={TaskStatus.Failed} />
                                                            <Text size={200}>{task.name} failed: {task.errors?.join(", ")}</Text>
                                                        </div>
                                                    )
                                                        : task.status && (
                                                            <div className={sharedHorizontalExtraSmallGapFlexStyles().root}>
                                                                <StatusIcon status={task.status} />
                                                                <Text size={200}>{task.name}</Text>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <DialogActions>
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="secondary"
                                        aria-label="close"
                                    >
                                        Close
                                    </Button>
                                </DialogTrigger>
                            </DialogActions>
                        </ErrorBoundary>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog >
    );
}