import React, { useContext } from 'react'
import { Caption1, Switch } from "@fluentui/react-components";
import { ThemeContext } from '../contexts/ThemeContext';
import { symityDarkTheme, symityLightTheme } from '../styles/theme';
import { Section } from "./Section";
import { sharedVerticalMediumGapFlexStyles } from "../styles/styles";

export function SettingsPage() {

  const themeContext = useContext(ThemeContext);

  const changeTheme = () => {
    themeContext.setTheme(themeContext.theme === symityLightTheme ? symityDarkTheme : symityLightTheme)
    localStorage.setItem("theme", themeContext.theme === symityLightTheme ? "dark" : "light");
  }

  return (
    <div>
      <Section
        title="General"
      >
        <div
          className={sharedVerticalMediumGapFlexStyles().root}
        >
          <Switch
            onClick={changeTheme}
            label="Enable dark mode"
            checked={themeContext.theme === symityDarkTheme}
          />
          <Caption1>Enable the dark mode theme for Symity Hub.</Caption1>
        </div>
      </Section>
    </div>
  )
}
