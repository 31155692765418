import React, { useState } from "react";
import { Persona } from "@fluentui/react-components";
import { useParams } from "react-router-dom";
import { sharedHorizontalMediumGapFlexStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import ErrorBoundary from "./ErrorBoundary";
import { UserPivot } from "./UserPivot";
import { EntityPage } from "./EntityPage";
import { fetcher } from "../modules/api";
import useSWR from "swr";
import { useMsal } from "@azure/msal-react";
import { Section } from "./Section";
import { PropertyAndValue } from "./PropertyAndValue";
import { IPhoneNumber, IUserExtended, IUser } from "@symity-hub/types";
import { TeamsUserContext } from "../contexts/TeamsUserContext";
import { BreadcrumbOverride } from "./BreadcrumbOverride";
import { renderPhoneNumberWithExtension } from "../modules/string";

interface IUserPageProps {
}

export const UserPage: React.FunctionComponent<IUserPageProps> = () => {

    const { id } = useParams();
    const msalContext = useMsal();

    const [data, setData] = useState<IUser>();
    const [teamsDetails, setTeamsDetails] = useState<IUserExtended>();
    const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>();
    const { data: photo } = useSWR<string>([`/api/users/${id}/photo`, msalContext, "GET"], fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    return (
        <ErrorBoundary>
            <TeamsUserContext.Provider
                value={{
                    teamsDetails,
                    setTeamsDetails,
                    user: data,
                    phoneNumber,
                    setPhoneNumber,
                }}
            >
                <EntityPage
                    url={`/api/users/${id}`}
                    setData={setData}
                >
                    <BreadcrumbOverride id={id} name={data?.displayName} />
                    {data && (
                        <div className={sharedVerticalMediumGapFlexStyles().root}>
                            <div className={sharedHorizontalMediumGapFlexStyles().root} >
                                <Section
                                    title="Account"
                                >
                                    <div className={sharedVerticalMediumGapFlexStyles().root}>
                                        <div>
                                            <Persona
                                                name={data.displayName || ""}
                                                avatar={{
                                                    image: {
                                                        src: photo || undefined
                                                    }
                                                }}
                                                secondaryText={data.userPrincipalName}
                                                size="huge"
                                            />
                                        </div>
                                        <div>
                                            <PropertyAndValue
                                                property="Location"
                                                value={data.usageLocation}
                                            />
                                            <PropertyAndValue
                                                property="Phone number"
                                                value={phoneNumber?.phoneNumber ? renderPhoneNumberWithExtension(phoneNumber.phoneNumber, phoneNumber.extensionNumber) : ""}
                                            />
                                            <PropertyAndValue
                                                property="Account type"
                                                value={teamsDetails?.accountType}
                                            />
                                            <PropertyAndValue
                                                property="Directory status"
                                                value={teamsDetails?.interpretedUserType}
                                            />
                                        </div>
                                    </div>
                                </Section>
                            </div>
                            <UserPivot />
                        </div>
                    )}
                </EntityPage>
            </TeamsUserContext.Provider>
        </ErrorBoundary>
    );
};
