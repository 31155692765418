import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { IPhoneNumberRange, writePhoneNumberPermission } from "@symity-hub/types";
import { sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { EntityPage } from "./EntityPage";
import ErrorBoundary from "./ErrorBoundary";
import { Section } from "./Section";
import { PropertyAndValue } from "./PropertyAndValue";
import { EditPhoneNumberRangePanel } from "./EditPhoneNumberRangePanel";
import { PhoneNumberList } from "./PhoneNumberList";
import { BreadcrumbOverride } from "./BreadcrumbOverride";
import { Button, Tab, TabList } from "@fluentui/react-components";
import { Call20Regular, Edit24Regular, TextBulletListLtr20Regular } from "@fluentui/react-icons";
import { RbacWrapper } from "./RbacWrapper";
import { ReportsViewer } from "./ReportsViewer";

interface IPhoneNumberRangePageProps {
}

export const PhoneNumberRangePage: React.FunctionComponent<IPhoneNumberRangePageProps> = () => {

    const { id } = useParams();

    const [panelOpen, setPanelOpen] = useState<boolean>(false);
    const [data, setData] = useState<IPhoneNumberRange>();
    const [selectedTab, setSelectedTab] = useState<string>("overview");

    return (
        <ErrorBoundary>
            <EntityPage
                url={`/api/voice/phoneNumberRanges/${id}`}
                setData={setData}
            >
                <BreadcrumbOverride id={id} name={data?.name} />
                <div className={sharedVerticalMediumGapFlexStyles().root}>
                    <TabList
                        aria-label="Range"
                        selectedValue={selectedTab}
                        onTabSelect={(_, data) => setSelectedTab(data.value as string)}
                    >
                        <Tab value="overview" icon={<TextBulletListLtr20Regular />}>Overview</Tab>
                        <Tab value="phoneNumbers" icon={<Call20Regular />}>Phone numbers</Tab>
                    </TabList>
                    {selectedTab === "overview" && (
                        <div className={sharedVerticalMediumGapFlexStyles().root}>
                            <Section
                                title="Range"
                                isLoading={!data}
                                action={(
                                    <RbacWrapper allowedPermissions={[writePhoneNumberPermission]}>
                                        <Button
                                            icon={<Edit24Regular />}
                                            onClick={() => setPanelOpen(true)}
                                            appearance="outline"
                                        >
                                            Edit
                                        </Button>
                                    </RbacWrapper>
                                )}
                            >
                                <PropertyAndValue
                                    property="Name"
                                    value={data?.name}
                                />
                                <PropertyAndValue
                                    property="Description"
                                    value={data?.description}
                                />
                                <PropertyAndValue
                                    property="Range start"
                                    value={data?.rangeStart}
                                />
                                <PropertyAndValue
                                    property="Range end"
                                    value={data?.rangeEnd}
                                />
                                <PropertyAndValue
                                    property="Numbers in range"
                                    value={data?.totalPhoneNumbersInRange}
                                />
                                <PropertyAndValue
                                    property="Carrier"
                                    value={data?.carrier}
                                />
                                <PropertyAndValue
                                    property="Notes"
                                    value={data?.notes}
                                />
                            </Section>
                            <ReportsViewer
                                url={`/api/reports/phoneNumberRanges/${id}`}
                            />
                            {panelOpen && data && (
                                <EditPhoneNumberRangePanel
                                    open={panelOpen}
                                    setOpen={setPanelOpen}
                                    data={data}
                                    setData={setData}
                                />
                            )}
                        </div>
                    )}
                    {selectedTab === "phoneNumbers" && (
                        <div className={sharedVerticalMediumGapFlexStyles().root}>
                            {data && (
                                <PhoneNumberList
                                    defaultFilters={[
                                        {
                                            name: "rangeId",
                                            operator: "eq",
                                            value: data.id
                                        }
                                    ]}
                                    hideRange
                                />
                            )}
                        </div>
                    )}
                </div>
            </EntityPage>
        </ErrorBoundary>
    );
};
