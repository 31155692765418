import React, { useContext, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { AssignPhoneNumberPanel } from "./AssignPhoneNumberPanel";
import { Section } from "./Section";
import { sharedHorizontalMediumGapWrapFlexStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { PropertyAndValue } from "./PropertyAndValue";
import { TeamsUserContext } from "../contexts/TeamsUserContext";
import { renderPhoneNumberWithExtension } from "../modules/string";
import { Button, Text } from "@fluentui/react-components";
import { Edit24Regular } from "@fluentui/react-icons";
import { assignPhoneNumberPermission } from "@symity-hub/types";
import { RbacWrapper } from "./RbacWrapper";

interface IUserVoiceProps {
    onDismissPanel: () => void;
    isLoading: boolean;
}

export const UserVoice: React.FunctionComponent<IUserVoiceProps> = (props) => {

    const teamsUserContext = useContext(TeamsUserContext);

    const [panelOpen, setPanelOpen] = useState<boolean>(false);

    const onDismissPanel = () => {
        props.onDismissPanel();
        setPanelOpen(false);
    }

    return (
        <ErrorBoundary>
            <div className={sharedHorizontalMediumGapWrapFlexStyles().root} >
                <Section
                    title="Number assignment"
                    isLoading={props.isLoading}
                    action={(
                        <RbacWrapper allowedPermissions={[assignPhoneNumberPermission]}>
                            <Button
                                icon={<Edit24Regular />}
                                onClick={() => setPanelOpen(true)}
                                appearance="outline"
                            >
                                Edit
                            </Button>
                        </RbacWrapper>
                    )}
                >
                    {teamsUserContext.phoneNumber ? (
                        <div>
                            <PropertyAndValue
                                property="Number"
                                value={renderPhoneNumberWithExtension(teamsUserContext.phoneNumber.phoneNumber, teamsUserContext.phoneNumber.extensionNumber)}
                            />
                            <PropertyAndValue
                                property="Type"
                                value={teamsUserContext.phoneNumber.numberType}
                            />
                            <PropertyAndValue
                                property="Classification"
                                value={teamsUserContext.phoneNumber.classification}
                            />
                        </div>
                    )
                        : (
                            <div className={sharedVerticalMediumGapFlexStyles().root}>
                                <Text>No phone number assigned.</Text>
                            </div>
                        )
                    }
                </Section>
            </div>
            {panelOpen && teamsUserContext.user && (
                <AssignPhoneNumberPanel
                    open={panelOpen}
                    setOpen={setPanelOpen}
                    onDismiss={onDismissPanel}
                    users={[teamsUserContext.user]}
                />
            )}
        </ErrorBoundary>
    );
};
