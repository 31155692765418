import React, { useEffect, useRef, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { IBaseQueryParams, IUser, allUserRoles, writeAdminUserPermission } from "@symity-hub/types";
import { IQueryProps } from "../types/IQueryProps";
import CustomDataGrid, { ICustomDataGridRef } from "./CustomDataGrid";
import { TableColumnDefinition, createTableColumn, TableCellLayout, Button } from "@fluentui/react-components";
import { Edit24Regular } from "@fluentui/react-icons";
import { EditAdminUserPanel } from "./EditAdminUserPanel";
import { RbacWrapper } from "./RbacWrapper";
import { CustomTableCellLayout } from "./CustomTableCellLayout";

export const AdminUserList: React.FunctionComponent = () => {

    const dataGridRef = useRef<ICustomDataGridRef>(null);

    const [panelOpen, setPanelOpen] = useState<boolean>(false);
    const prevPanelOpen = useRef<boolean>(panelOpen);
    const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined);

    const defaultQueryParams: IBaseQueryParams = {
        sortBy: "displayName",
        filters: []
    };

    const [queryProps, setQueryProps] = useState<IQueryProps>({
        params: defaultQueryParams,
        searchField: "displayName",
        searchPlaceholderText: "Search by name"
    });

    const columns: TableColumnDefinition<IUser>[] = [
        createTableColumn<IUser>({
            columnId: "displayName",
            renderHeaderCell: () => {
                return "Name";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.displayName}>
                        {item.displayName}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.displayName.localeCompare(b.displayName);
            },
        }),
        createTableColumn<IUser>({
            columnId: "userPrincipalName",
            renderHeaderCell: () => {
                return "Username";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.userPrincipalName}>
                        {item.userPrincipalName}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.userPrincipalName.localeCompare(b.userPrincipalName);
            },
        }),
        createTableColumn<IUser>({
            columnId: "roles",
            renderHeaderCell: () => {
                return "Roles";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.roles?.map((role) => allUserRoles.find((userRole) => userRole.id === role)?.displayName).join(", ")}>
                        {item.roles?.map((role) => allUserRoles.find((userRole) => userRole.id === role)?.displayName).join(", ")}
                    </CustomTableCellLayout>
                );
            }
        }),
        createTableColumn<IUser>({
            columnId: "actions",
            renderHeaderCell: () => {
                return "";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <RbacWrapper allowedPermissions={[writeAdminUserPermission]}>
                            <Button
                                icon={<Edit24Regular />}
                                aria-label="Edit user"
                                disabled={panelOpen}
                                onClick={() => {
                                    setPanelOpen(true)
                                    setSelectedUser(item);
                                }}
                            >
                                Edit
                            </Button>
                        </RbacWrapper>
                    </TableCellLayout>
                );
            }
        })
    ];

    // When panel is closed, refresh the data grid
    useEffect(() => {
        if (prevPanelOpen.current && !panelOpen && dataGridRef.current) {
            dataGridRef.current.reloadData();
        }
        prevPanelOpen.current = panelOpen;
    }, [panelOpen]);

    return (
        <div className={sharedVerticalMediumGapFlexStyles().root} >
            <ErrorBoundary>
                <CustomDataGrid
                    ref={dataGridRef}
                    url="/api/admin/users"
                    queryProps={queryProps}
                    setQueryProps={setQueryProps}
                    columns={columns}
                />
                {panelOpen && selectedUser && (
                    <EditAdminUserPanel
                        open={panelOpen}
                        setOpen={setPanelOpen}
                        data={selectedUser}
                        setData={setSelectedUser}
                    />
                )}
            </ErrorBoundary>
        </div>
    );
}
