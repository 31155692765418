import React from "react";
import { Divider, makeStyles, shorthands, Text, tokens } from "@fluentui/react-components";

export interface IPropertyAndValueProps {
    property: string;
    value?: string | number | null | React.ReactNode;
}

const propertyAndValueStyles = makeStyles({
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        gap: (tokens.spacingHorizontalXXL),
        ...shorthands.padding(tokens.spacingVerticalXS, 0),
        width: "100%"
    },
    property: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightSemibold
    },
    value: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        marginLeft: "auto"
    }
})

export const PropertyAndValue: React.FunctionComponent<IPropertyAndValueProps> = (props) => {

    return (
        <div>
            <Divider />
            <div className={propertyAndValueStyles().row}>
                <Text className={propertyAndValueStyles().property}>{props.property}:</Text>
                {
                    // If the value is a string, number, or null, render it as a Text component
                    typeof props.value === "string" || typeof props.value === "number" || props.value === null ? (
                        <Text className={propertyAndValueStyles().value}>{props.value}</Text>
                    ) : (
                        // Otherwise, render it as-is
                        <div className={propertyAndValueStyles().value}>
                            {props.value}
                        </div>
                    )
                }
            </div>
        </div>
    );
};
