import { Avatar, Text, Tooltip, makeStyles } from "@fluentui/react-components";
import React, { useState } from "react";
import { SignedInUserPanel } from "./SignedInUserPanel";
import { useMsal } from "@azure/msal-react";
import useSWR from "swr";
import { fetcher } from "../modules/api";
import { appHeaderStyles } from "./AppHeader";
import { sharedHorizontalMediumGapFlexStyles } from "../styles/styles";

const signedInUserProfileStyles = makeStyles({
    avatar: {
        maxWidth: '200px',
        overflowX: "hidden",
        overflowY: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
});

export const SignedInUserProfile: React.FunctionComponent = () => {
    const msalContext = useMsal();

    const [open, setOpen] = useState(false);

    const { data: photo } = useSWR<string>(msalContext.accounts.length > 0 && msalContext.accounts[0].localAccountId ? [`/api/me/photo`, msalContext, "GET"] : null, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });

    return (
        <div>
            <div
                className={appHeaderStyles().menuItem}
                onClick={() => setOpen(!open)}
            >
                <div
                    className={sharedHorizontalMediumGapFlexStyles().root}
                >
                    <Tooltip content={msalContext.accounts[0]?.name || ""} relationship="label">
                        <Text
                            className={signedInUserProfileStyles().avatar}
                            size={300}
                        >
                            {msalContext.accounts[0]?.name}
                        </Text>
                    </Tooltip>
                    <Avatar
                        name={msalContext.accounts[0]?.name}
                        size={48}
                        image={{
                            src: photo || undefined
                        }}
                    />
                </div>
            </div>
            {open && (
                <SignedInUserPanel
                    open={open}
                    setOpen={setOpen}
                    photo={photo}
                />
            )}
        </div>
    );
}
