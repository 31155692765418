import React, { } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { sharedHorizontalSmallGapFlexStyles } from "../styles/styles";
import { TableCellLayout, TableColumnDefinition, Tooltip, createTableColumn, Text } from "@fluentui/react-components";
import { IUserPermission, TaskStatus, allUserPermissions, allUserRoles } from "@symity-hub/types";
import { Section } from "./Section";
import { BasicDataGrid } from "./BasicDataGrid";
import { StatusIcon } from "./StatusIcon";
import { Info16Regular } from "@fluentui/react-icons";

interface IRoleListProps {
}

export const RoleList: React.FunctionComponent<IRoleListProps> = () => {

    const rolesColumns: TableColumnDefinition<IUserPermission>[] = [
        createTableColumn<IUserPermission>({
            columnId: "displayName",
            renderHeaderCell: () => {
                return "Permission";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <div className={sharedHorizontalSmallGapFlexStyles().root}>
                            <div>
                                <Tooltip
                                    content={item.description}
                                    positioning="above-start"
                                    withArrow
                                    relationship="label"
                                >
                                    <Info16Regular />
                                </Tooltip>
                            </div>
                            <Text size={200}>{item.displayName}</Text>
                        </div>
                    </TableCellLayout>
                );
            }
        }),
        ...allUserRoles.map((role) => {
            return createTableColumn<IUserPermission>({
                columnId: role.id,
                renderHeaderCell: () => {
                    return role.displayName;
                },
                renderCell: (item) => {
                    // Check if the role has the permission
                    const allowed = role.permissions.includes(item.id);
                    return (
                        <TableCellLayout>
                            <StatusIcon status={allowed ? TaskStatus.Completed : TaskStatus.Failed} />
                        </TableCellLayout>
                    );
                }
            });
        })
    ];

    return (
        <div>
            <ErrorBoundary>
                <Section
                    title="Roles and permissions"
                    description="Roles and permissions are used to control access to Symity Hub."
                >
                    <BasicDataGrid
                        columns={rolesColumns}
                        items={allUserPermissions.sort((a, b) => {
                            return a.displayName.localeCompare(b.displayName);
                        })}
                        size="small"
                    />
                </Section>
            </ErrorBoundary>
        </div>
    );
}
