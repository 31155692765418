import React, { useEffect, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { sharedHorizontalExtraSmallGapFlexStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { AuditTypeAction, AuditTypeTarget, IAuditExtended, IBaseQueryParams, TaskStatus } from "@symity-hub/types";
import { IQueryProps } from "../types/IQueryProps";
import CustomDataGrid from "./CustomDataGrid";
import { TableColumnDefinition, createTableColumn, Link, Checkbox } from "@fluentui/react-components";
import { AuditPanel } from "./AuditPanel";
import { StatusIcon } from "./StatusIcon";
import { generateDateFromIso } from "../modules/string";
import { IDropdownFilter } from "../types/IDropdownFilter";
import { getOptionsFromEnum } from "../modules/enum";
import cloneDeep from "lodash.clonedeep";
import { CustomTableCellLayout } from "./CustomTableCellLayout";

interface IAuditListProps {
}

export const AuditList: React.FunctionComponent<IAuditListProps> = () => {

    const [panelOpen, setPanelOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<IAuditExtended | undefined>(undefined);

    const defaultQueryParams: IBaseQueryParams = {
        sortBy: "date",
        sortDirection: "DESC",
        filters: [
            {
                name: "parentId",
                operator: "notDefined"
            }
        ]
    };

    const [queryProps, setQueryProps] = useState<IQueryProps>({
        params: defaultQueryParams,
        searchField: "description",
        searchPlaceholderText: "Search by description"
    });

    const defaultDropdownFilters: IDropdownFilter[] = [
        {
            name: "status",
            label: "Status",
            options: getOptionsFromEnum(TaskStatus),
            selectedKeys: [],
            multiSelect: true
        },
        {
            name: "type.target",
            label: "Type",
            options: getOptionsFromEnum(AuditTypeTarget),
            selectedKeys: [],
            multiSelect: true
        },
        {
            name: "type.action",
            label: "Action",
            options: getOptionsFromEnum(AuditTypeAction),
            selectedKeys: [],
            multiSelect: true
        }
    ]

    const [dropdownFilters, setDropdownFilters] = useState<IDropdownFilter[]>(defaultDropdownFilters);
    const [showChildLogs, setShowChildLogs] = useState<boolean>(false);

    useEffect(() => {
        if (queryProps.params.filters) {
            const newFilters = cloneDeep(queryProps.params.filters).filter((filter) => filter.name !== "parentId");
            if (!showChildLogs) {
                newFilters.push({
                    name: "parentId",
                    operator: "notDefined"
                });
            }
            setQueryProps({
                ...queryProps,
                params: {
                    ...queryProps.params,
                    filters: newFilters
                }
            });
        }
    }, [showChildLogs]);

    const columns: TableColumnDefinition<IAuditExtended>[] = [
        createTableColumn<IAuditExtended>({
            columnId: "date",
            renderHeaderCell: () => {
                return "Date";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={generateDateFromIso(item.date)}>
                        <Link onClick={() => {
                            setSelectedItem(item);
                            setPanelOpen(true)
                        }}>
                            {generateDateFromIso(item.date)}
                        </Link>
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.date.localeCompare(b.date);
            },
        }),
        createTableColumn<IAuditExtended>({
            columnId: "status",
            renderHeaderCell: () => {
                return "Status";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.status}>
                        <div className={sharedHorizontalExtraSmallGapFlexStyles().root}>
                            <StatusIcon status={item.status} />
                            {item.status}
                        </div>
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.status.localeCompare(b.status);
            },
        }),
        createTableColumn<IAuditExtended>({
            columnId: "target",
            renderHeaderCell: () => {
                return "Type";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.type.target}>
                        {item.type.target}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.type.target.localeCompare(b.type.target);
            },
        }),
        createTableColumn<IAuditExtended>({
            columnId: "action",
            renderHeaderCell: () => {
                return "Action";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.type.action}>
                        {item.type.action}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.type.action.localeCompare(b.type.action);
            },
        }),
        createTableColumn<IAuditExtended>({
            columnId: "description",
            renderHeaderCell: () => {
                return "Description";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.description}>
                        {item.description}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.description.localeCompare(b.description);
            },
        }),
        createTableColumn<IAuditExtended>({
            columnId: "initiatedByUserDisplayName",
            renderHeaderCell: () => {
                return "Initiated by";
            },
            renderCell: (item) => {
                return (
                    <CustomTableCellLayout tooltip={item.initiatedByUserDisplayName}>
                        {item.initiatedByUserDisplayName}
                    </CustomTableCellLayout>
                );
            },
            compare: (a, b) => {
                return a.initiatedByUserDisplayName.localeCompare(b.initiatedByUserDisplayName);
            },
        })
    ];

    const onDismiss = () => {
        setPanelOpen(false);
        setSelectedItem(undefined);
    }

    return (
        <div className={sharedVerticalMediumGapFlexStyles().root} >
            <ErrorBoundary>

                <CustomDataGrid
                    url="/api/admin/audit"
                    queryProps={queryProps}
                    setQueryProps={setQueryProps}
                    columns={columns}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    additionalFilters={[
                        <Checkbox
                            key="showChildLogs"
                            label="Include child log entries"
                            checked={showChildLogs}
                            onChange={(e, d) => setShowChildLogs(d.checked as boolean)}
                        />
                    ]}
                />
                {panelOpen && selectedItem && (
                    <AuditPanel
                        open={panelOpen}
                        setOpen={setPanelOpen}
                        data={selectedItem}
                        onDismiss={onDismiss}
                    />
                )}
            </ErrorBoundary>
        </div>
    );
}
