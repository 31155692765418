import { z } from 'zod';
import { FeedbackType } from "./enums/FeedbackType";

export const feedbackSchema = z.object({
    feedbackType: z.nativeEnum(FeedbackType),
    rating: z.number()
        .int()
        .min(1)
        .max(5),
    comment: z.string(),
    version: z.string()
});

export type IFeedback = z.infer<typeof feedbackSchema>;