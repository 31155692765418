import { IUserRole } from "../types/IUserRole";
import { readPhoneNumberPermission, writePhoneNumberPermission, deletePhoneNumberPermission, assignPhoneNumberPermission, readUserPermission, readTeamsPolicyPermission, assignTeamsPolicyPermission, readAdminUserPermission, writeAdminUserPermission, readAuditPermission, syncPermission } from "./permissions";

export const defaultUserRole: IUserRole = {
    id: "ea539298-62bc-4878-91d0-a67766cc0a40",
    displayName: "Default",
    description: "Default user role",
    permissions: []
};

export const ownerUserRole: IUserRole = {
    id: "ff0e3c47-2397-4b55-a2dc-74c7591a1a59",
    displayName: "Owner",
    description: "Full access to manage all resources, including administration of users",
    permissions: [
        readPhoneNumberPermission.id,
        writePhoneNumberPermission.id,
        deletePhoneNumberPermission.id,
        assignPhoneNumberPermission.id,
        readUserPermission.id,
        readTeamsPolicyPermission.id,
        assignTeamsPolicyPermission.id,
        readAdminUserPermission.id,
        writeAdminUserPermission.id,
        readAuditPermission.id,
        syncPermission.id
    ]
};

export const telephonyAdministratorUserRole: IUserRole = {
    id: "9a178923-f035-476f-8c28-f55d0c267f32",
    displayName: "Telephony Administrator",
    description: "Full access to telephony-related resources, such as phone numbers",
    permissions: [
        readPhoneNumberPermission.id,
        writePhoneNumberPermission.id,
        deletePhoneNumberPermission.id,
        assignPhoneNumberPermission.id,
        readUserPermission.id,
        readTeamsPolicyPermission.id,
        assignTeamsPolicyPermission.id,
        syncPermission.id
    ]
};

export const supportEngineerUserRole: IUserRole = {
    id: "05c656d2-7a6e-43a2-a2da-c320bfe31ff7",
    displayName: "Support Engineer",
    description: "Manage user resources, such as assigning phone numbers",
    permissions: [
        readPhoneNumberPermission.id,
        assignPhoneNumberPermission.id,
        readUserPermission.id,
        readTeamsPolicyPermission.id,
        assignTeamsPolicyPermission.id,
        syncPermission.id
    ]
};

export const teamsReaderUserRole: IUserRole = {
    id: "fecc0b74-b891-417c-abad-48bd36b31ad4",
    displayName: "Reader",
    description: "Read-only access to all resources",
    permissions: [
        readPhoneNumberPermission.id,
        readUserPermission.id,
        readTeamsPolicyPermission.id
    ]
};

export const allUserRoles: IUserRole[] = [
    ownerUserRole,
    telephonyAdministratorUserRole,
    supportEngineerUserRole,
    teamsReaderUserRole
];
