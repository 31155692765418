import React, { useRef, useEffect, useState } from "react";
import { TableCellLayout, Tooltip, makeStyles } from "@fluentui/react-components";

interface ICustomTableCellLayoutProps {
    tooltip?: string;
}

const truncateStyle = makeStyles({
    root: {
        overflowX: "hidden",
        overflowY: "hidden",
        whiteSpace: "nowrap"
    }
});

// Custom hook to check if text is truncated
function useIsTextTruncated(ref) {
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const element = ref.current;
        if (element) {
            setIsTruncated(element.offsetWidth < element.scrollWidth);
        }
    }, [ref]);

    return isTruncated;
}

export const CustomTableCellLayout: React.FunctionComponent<ICustomTableCellLayoutProps> = (props) => {
    const cellRef = useRef(null);
    const isTruncated = useIsTextTruncated(cellRef);

    return (
        <TableCellLayout
            className={truncateStyle().root}
            ref={cellRef}
        >
            {isTruncated ? (
                <Tooltip content={props.tooltip || ""} relationship="label">
                    <div>
                        {props.children}
                    </div>
                </Tooltip>
            ) : (
                <div>
                    {props.children}
                </div>
            )}
        </TableCellLayout >
    );
}