import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, makeStyles, mergeClasses, tokens } from "@fluentui/react-components";
import { sharedNavMenuStyles } from "../styles/styles";

export interface INavMenuLink {
    key: string;
    name: string;
    url: string;
    requiredPermissionId?: string;
}

interface INavMenuLinkProps {
    navMenuLink: INavMenuLink;
}

const navMenuLinkStyles = makeStyles({
    root: {
        paddingLeft: tokens.spacingHorizontalXXXL,
        fontSize: tokens.fontSizeBase300,
    },
    selected: {
        backgroundColor: tokens.colorNeutralBackground1Hover,
        borderLeftColor: tokens.colorBrandBackground,
        borderLeftWidth: tokens.strokeWidthThicker,
        borderLeftStyle: "solid"
    }
});

export const NavMenuLink: React.FunctionComponent<INavMenuLinkProps> = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    const navMenuLinkClass = mergeClasses(sharedNavMenuStyles().root, navMenuLinkStyles().root);
    const navMenuLinkSelectedClass = mergeClasses(navMenuLinkClass, navMenuLinkStyles().selected);

    return (
        <Link
            key={props.navMenuLink.key}
            onClick={() => navigate(props.navMenuLink.url)}
            className={location.pathname === props.navMenuLink.url ? navMenuLinkSelectedClass : navMenuLinkClass}
            appearance="subtle"
        >
            {props.navMenuLink.name}
        </Link>
    );
}
