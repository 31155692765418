import { BrandVariants, Theme, createDarkTheme, createLightTheme } from "@fluentui/react-components";

const symityTheme: BrandVariants = {
    10: "#010308",
    20: "#081730",
    30: "#0D254A",
    40: "#1F3154",
    50: "#2E3D5F",
    60: "#3C496A",
    70: "#4B5675",
    80: "#596380",
    90: "#68718C",
    100: "#777E97",
    110: "#868DA3",
    120: "#959BAF",
    130: "#A5AABB",
    140: "#B4B8C7",
    150: "#C4C7D3",
    160: "#D5D7DF"
};

export const symityLightTheme: Theme = {
    ...createLightTheme(symityTheme),
};

export const symityDarkTheme: Theme = {
    ...createDarkTheme(symityTheme),
};
