import { z } from "zod";
import { IBaseCosmosItem, baseCosmosItemSchema } from "./IBaseCosmosItem";

export const customerSchema = baseCosmosItemSchema.extend({
    azureTenantId: z.string()
        .uuid(),
    name: z.string()
})
    .strict();

export type ICustomer = z.infer<typeof customerSchema>;