import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const buttonStyles = makeStyles({
    root: {
        minWidth: "100px",
        ...shorthands.margin(tokens.spacingVerticalS, tokens.spacingHorizontalS)
    },
});

export const sharedNavMenuStyles = makeStyles({
    root: {
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textDecorationLine: "none",
        width: "100%",
        borderBottomColor: tokens.colorNeutralStroke3,
        borderBottomWidth: tokens.strokeWidthThin,
        borderBottomStyle: "solid",
        fontSize: tokens.fontSizeBase400,
        gap: (tokens.spacingVerticalS, tokens.spacingHorizontalS),
        ":hover": {
            textDecorationLine: "none",
            backgroundColor: tokens.colorNeutralBackground3Hover
        },
        ":focus": {
            textDecorationLine: "none",
            backgroundColor: tokens.colorNeutralBackground3Hover
        },
        ":pressed": {
            textDecorationLine: "none",
            backgroundColor: tokens.colorNeutralBackground3Pressed
        },
        ":active": {
            textDecorationLine: "none",
            backgroundColor: tokens.colorNeutralBackground3Selected
        }
    }
});

const flexWithGapStyles = (horizontalSpacing: string, verticalSpacing: string) => {
    return {
        ...shorthands.gap(horizontalSpacing, verticalSpacing)
    }
}

// Horizontal Flex Styles

const sharedFlexRowStyles = (): unknown => {
    return {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
}

export const sharedHorizontalNoGapFlexStyles = makeStyles({
    root: {
        ...(sharedFlexRowStyles() || {})
    }
});

export const sharedHorizontalExtraSmallGapFlexStyles = makeStyles({
    root: {
        ...(sharedFlexRowStyles() || {}),
        ...flexWithGapStyles(tokens.spacingHorizontalXS, tokens.spacingVerticalXS)
    }
});

export const sharedHorizontalSmallGapFlexStyles = makeStyles({
    root: {
        ...(sharedFlexRowStyles() || {}),
        ...flexWithGapStyles(tokens.spacingHorizontalS, tokens.spacingVerticalS)
    }
});

export const sharedHorizontalMediumGapFlexStyles = makeStyles({
    root: {
        ...(sharedFlexRowStyles() || {}),
        ...flexWithGapStyles(tokens.spacingHorizontalM, tokens.spacingVerticalM)
    },
});

export const sharedHorizontalLargeGapFlexStyles = makeStyles({
    root: {
        ...(sharedFlexRowStyles() || {}),
        ...flexWithGapStyles(tokens.spacingHorizontalL, tokens.spacingVerticalL)
    }
});

export const sharedHorizontalExtraLargeGapFlexStyles = makeStyles({
    root: {
        ...(sharedFlexRowStyles() || {}),
        ...flexWithGapStyles(tokens.spacingHorizontalXL, tokens.spacingVerticalXL)
    }
});

export const sharedHorizontalMediumGapWrapFlexStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        ...flexWithGapStyles(tokens.spacingHorizontalM, tokens.spacingVerticalM),
    },
});

// Vertical Flex Styles
const sharedFlexColumnStyles = (): unknown => {
    return {
        display: "flex",
        flexDirection: "column"
    }
}

export const sharedVerticalNoGapFlexStyles = makeStyles({
    root: {
        ...sharedFlexColumnStyles
    }
});

export const sharedVerticalExtraSmallGapFlexStyles = makeStyles({
    root: {
        ...(sharedFlexColumnStyles() || {}),
        ...flexWithGapStyles(tokens.spacingHorizontalXS, tokens.spacingVerticalXS)
    }
});

export const sharedVerticalSmallGapFlexStyles = makeStyles({
    root: {
        ...(sharedFlexColumnStyles() || {}),
        ...flexWithGapStyles(tokens.spacingHorizontalS, tokens.spacingVerticalS)
    }
});

export const sharedVerticalMediumGapFlexStyles = makeStyles({
    root: {
        ...(sharedFlexColumnStyles() || {}),
        ...flexWithGapStyles(tokens.spacingHorizontalM, tokens.spacingVerticalM)
    },
});

export const sharedVerticalLargeGapFlexStyles = makeStyles({
    root: {
        ...(sharedFlexColumnStyles() || {}),
        ...flexWithGapStyles(tokens.spacingHorizontalL, tokens.spacingVerticalL)
    }
});

export const sharedVerticalExtraLargeGapFlexStyles = makeStyles({
    root: {
        ...(sharedFlexColumnStyles() || {}),
        ...flexWithGapStyles(tokens.spacingHorizontalXL, tokens.spacingVerticalXL)
    }
});

export const sharedVerticalMediumGapWrapFlexStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        ...flexWithGapStyles(tokens.spacingHorizontalM, tokens.spacingVerticalM),
    },
});

export const sharedDropdownStyles = makeStyles({
    root: {
        display: "grid",
        gridTemplateRows: "repeat(1fr)",
        justifyItems: "start",
        minWidth: "200px",
        maxWidth: "300px",
        gap: (tokens.spacingHorizontalS, tokens.spacingVerticalS),
    }
});

export const sharedInputStyles = makeStyles({
    root: {
        maxWidth: "400px"
    }
});
