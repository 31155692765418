import React, { useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { IPhoneNumber, IPhoneNumberExtended, PhoneNumberClassification, phoneNumberSchema, PhoneNumberType } from "@symity-hub/types";
import { ZodError } from "zod";
import { getOptionsFromEnum } from "../modules/enum";
import { CustomEditDrawer } from "./CustomEditDrawer";
import { CustomDropdown } from "./CustomDropdown";
import { CustomInput } from "./CustomInput";
import { CustomTextarea } from "./CustomTextarea";
import { EntityPage } from "./EntityPage";

interface IEditPhoneNumberPanelProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    data: IPhoneNumberExtended;
    setData: React.Dispatch<React.SetStateAction<IPhoneNumberExtended | undefined>>
}

export const EditPhoneNumberPanel: React.FunctionComponent<IEditPhoneNumberPanelProps> = (props) => {

    const [data, setData] = useState<IPhoneNumberExtended>(props.data);
    const [saveData, setSaveData] = useState<IPhoneNumber>();
    const [validationError, setValidationError] = useState<ZodError>();

    const onSave = async () => {
        if (data) {
            const phoneNumber: IPhoneNumberExtended = { ...data };
            // Delete extended properties before validating (if set)
            if (phoneNumber.userDisplayName) delete phoneNumber.userDisplayName;
            if (phoneNumber.rangeName) delete phoneNumber.rangeName;
            await phoneNumberSchema.parseAsync(phoneNumber)
                .then(() => {
                    setValidationError(undefined);
                    setSaveData(phoneNumber);
                    props.setData(phoneNumber);
                    props.setOpen(false);
                })
                .catch((error: ZodError) => {
                    setValidationError(error);
                    setSaveData(undefined);
                });
        }
    }

    const onDismiss = () => {
        props.setOpen(false);
        setSaveData(undefined);
    }

    return (
        <CustomEditDrawer
            open={props.open}
            onDismiss={onDismiss}
            onSave={onSave}
            headerText="Phone number"
            validationError={validationError}
        >
            <ErrorBoundary>
                <EntityPage
                    url={`/api/voice/phoneNumbers/${data.id}`}
                    setData={setData}
                    saveData={saveData}
                >
                    {data && (
                        <div>
                            <CustomInput
                                id="number"
                                label="Number"
                                required={!phoneNumberSchema.shape.phoneNumber.isOptional()}
                                value={data.phoneNumber}
                                validationError={validationError}
                                disabled
                            />
                            <CustomInput
                                id="extension"
                                label="Extension"
                                required={!phoneNumberSchema.shape.extensionNumber.isOptional()}
                                value={data.extensionNumber}
                                validationError={validationError}
                                disabled
                            />
                            <CustomInput
                                id="userDisplayName"
                                label="Assigned to"
                                value={data.userDisplayName}
                                validationError={validationError}
                                disabled
                            />
                            <CustomInput
                                id="rangeName"
                                label="Range"
                                value={data.rangeName}
                                validationError={validationError}
                                disabled
                            />
                            <CustomDropdown
                                id="type"
                                label="Type"
                                options={getOptionsFromEnum(PhoneNumberType)}
                                selectedOptions={[data.numberType]}
                                value={data.numberType}
                                required={!phoneNumberSchema.shape.numberType.isOptional()}
                                validationError={validationError}
                                disabled
                            />
                            <CustomDropdown
                                id="classification"
                                label="Classification"
                                options={getOptionsFromEnum(PhoneNumberClassification)}
                                selectedOptions={[data.classification]}
                                value={data.classification}
                                onOptionSelect={(_, option) => { if (option) { setData({ ...data, classification: option.optionValue as PhoneNumberClassification }) } }}
                                required={!phoneNumberSchema.shape.classification.isOptional()}
                                validationError={validationError}
                            />
                            <CustomTextarea
                                id="notes"
                                label="Notes"
                                value={data.notes}
                                required={!phoneNumberSchema.shape.notes.isOptional()}
                                onChange={(e, v) => setData({ ...data, notes: v.value })}
                                validationError={validationError}
                            />
                        </div>
                    )}
                </EntityPage>
            </ErrorBoundary>
        </CustomEditDrawer>
    );
};
