import { Spinner, makeStyles, tokens } from "@fluentui/react-components";
import { CheckmarkCircle20Filled, ErrorCircle20Filled, Info20Filled, Warning20Filled } from "@fluentui/react-icons";
import { TaskStatus } from "@symity-hub/types";
import React from "react";

interface IStatusIconProps {
    status?: TaskStatus | unknown;
}

const statusIconStyles = makeStyles({
    success: {
        width: "20px",
        flexShrink: 0,
        color: tokens.colorPaletteGreenBackground3
    },
    error: {
        width: "20px",
        flexShrink: 0,
        color: tokens.colorPaletteRedBackground3
    },
    running: {
        width: "20px",
        flexShrink: 0,
        color: tokens.colorPaletteBlueBackground2
    },
    canceled: {
        width: "20px",
        flexShrink: 0,
        color: tokens.colorNeutralForeground3
    },
    info: {
        width: "20px",
        flexShrink: 0,
        color: tokens.colorBrandForeground1
    },
    warning: {
        width: "20px",
        flexShrink: 0,
        color: tokens.colorPaletteMarigoldBackground3
    }
});

export const StatusIcon: React.FunctionComponent<IStatusIconProps> = (props) => {
    if (props.status === TaskStatus.Completed) {
        return <CheckmarkCircle20Filled className={statusIconStyles().success} />
    }
    if (props.status === TaskStatus.Failed || props.status === TaskStatus.Error) {
        return <ErrorCircle20Filled className={statusIconStyles().error} />
    }
    if (props.status === TaskStatus.Canceled || props.status === TaskStatus.Terminated) {
        return <Info20Filled className={statusIconStyles().canceled} />
    }
    if (props.status === TaskStatus.Pending || props.status === TaskStatus.Running) {
        return <Spinner className={statusIconStyles().running} size="tiny" />;
    }
    if (props.status === TaskStatus.Warning) {
        return <Warning20Filled className={statusIconStyles().warning} />
    }
    return <Info20Filled className={statusIconStyles().info} />
}
