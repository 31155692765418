import React, { ReactNode } from "react";
import { makeStyles, shorthands, Text, tokens } from "@fluentui/react-components";
import { sharedHorizontalSmallGapFlexStyles } from "../styles/styles";
import { StatusIcon } from "./StatusIcon";
import { TaskStatus } from "@symity-hub/types";

export interface ICustomAlertProps {
    id: string;
    text: string;
    type?: TaskStatus | unknown;
    action?: ReactNode;
}

const customAlertStyles = makeStyles({
    root: {
        ...shorthands.margin(tokens.spacingVerticalS, 0),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
        gap: (tokens.spacingVerticalS),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        boxShadow: tokens.shadow4,
        backgroundColor: tokens.colorNeutralBackground1
    },
    icon: {
        marginTop: "5px",
        marginBottom: "auto",
        flexShrink: 0,
    },
    action: {
        marginBottom: "auto",
        marginLeft: "auto",
        flexShrink: 0,
    }
});

export const CustomAlert: React.FunctionComponent<ICustomAlertProps> = (props) => {

    return (
        <div
            className={customAlertStyles().root}
        >
            <div className={sharedHorizontalSmallGapFlexStyles().root}>
                <div className={customAlertStyles().icon}>
                    <StatusIcon status={props.type} />
                </div>
                <Text size={300}>{props.text}</Text>
                <div
                    className={customAlertStyles().action}
                >
                    {props.action}
                </div>
            </div>
            {props.children}
        </div>
    );
};
