/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect } from "react";
import { CustomAlert } from "./CustomAlert";
import ErrorBoundary from "./ErrorBoundary";
import { useMsal } from "@azure/msal-react";
import useSWRMutation from "swr/mutation";
import { fetcher } from "../modules/api";
import { AppContext } from "../contexts/AppContext";
import { Spinner } from "@fluentui/react-components";
import { TaskStatus } from "@symity-hub/types";

interface IEntityPageProps {
    url: string;
    setData: React.Dispatch<React.SetStateAction<any>>;
    saveData?: any;
    revalidate?: boolean;
}

export const EntityPage: React.FunctionComponent<IEntityPageProps> = (props) => {

    const msalContext = useMsal();
    const appContext = useContext(AppContext);
    const { error, trigger, isMutating } = useSWRMutation(props.url ? [props.url, msalContext] : null, fetcher,
        {
            revalidate: props.revalidate,
            onSuccess: (data) => {
                props.setData(data);
            }
        }
    );

    // Trigger on first render
    useEffect(() => {
        trigger({});
    }, [trigger]);

    // Trigger on save
    useEffect(() => {
        const onSave = async () => {
            await trigger({
                method: "PUT",
                data: props.saveData
            })
                .then(() => {
                    appContext.notify("Saved successfully", "success");
                })
                .catch((error) => {
                    appContext.notify(`Error saving: ${error}`, "error");
                });
        }
        if (props.saveData) onSave();
    }, [props.saveData, trigger, appContext]);

    if (error) return (<CustomAlert
        text={error}
        type={TaskStatus.Error}
        id="entityPage"
    />);
    if (isMutating) return (<Spinner size="large" />);

    return (
        <ErrorBoundary>
            <div>
                {props.children}
            </div>
        </ErrorBoundary>
    );
}